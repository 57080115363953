import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TReservation } from '../../types/Reservation';
import { listMessagesThunk, sendMessageThunk } from '../thunks/chatThunk';
import { IChatSliceState } from '../../types/Chat';

const initialState: IChatSliceState = {
  reservation: null,
  hasFirstLoadNotificationDisplayed: false,
  previousMessagesCount: 0,

  messages: null,
  listMessagesStatus: null,
  sendMessageStatus: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    clearChat: () => initialState,
    setChatModalVisible: (
      state,
      { payload }: PayloadAction<{ reservation: TReservation | null }>,
    ) => {
      state.reservation = payload.reservation;
    },
    setFirstLoadNotificationToBeDisplayed: (state) => {
      state.hasFirstLoadNotificationDisplayed = true;
    },
    setPreviousMessaggesCount: (state, action) => {
      state.previousMessagesCount = action.payload;
    },
    cleanMessages: (state) => {
      state.messages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // listMessages
      .addCase(listMessagesThunk.pending, (state) => {
        state.listMessagesStatus = 'loading';
      })
      .addCase(listMessagesThunk.fulfilled, (state, { payload }) => {
        state.messages = payload;

        state.listMessagesStatus = 'success';
      })
      .addCase(listMessagesThunk.rejected, (state) => {
        state.listMessagesStatus = 'failed';
      })

      // sendMessage
      .addCase(sendMessageThunk.pending, (state) => {
        state.sendMessageStatus = 'loading';
      })
      .addCase(sendMessageThunk.fulfilled, (state, { payload }) => {
        if (!state.messages) return;

        state.messages.push(payload);

        state.sendMessageStatus = 'success';
      })
      .addCase(sendMessageThunk.rejected, (state) => {
        state.sendMessageStatus = 'failed';
      });
  },
});

export const {
  clearChat,
  setChatModalVisible,
  setFirstLoadNotificationToBeDisplayed,
  setPreviousMessaggesCount,
  cleanMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
