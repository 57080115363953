import { FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { IImageUploadProps } from './ImageUpload.types';
import { imageExtensionsUpload } from '../../../../constants/constants';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import PenIcon from '../../../../assets/icons/PenIcon';
import UploadIcon from '../../../../assets/icons/UploadIcon';
import ImageCropModal from '../../organisms/ImageCropModal/ImageCropModal';
import InfoIcon from '../../../../assets/icons/InfoIcon';
import useTooltip from '../../../../hooks/useTooltip';
import useMobile from '../../../../hooks/useIsMobile';

const ImageUpload: FC<IImageUploadProps> = ({
  name,
  labelText,
  placeholder,
  value,
  onChange,
  maxFileSize,
  error,
  isReadOnly,
  isImagePreviewHorizontal,
  aspect,
  variant = 'default',
}) => {
  const { t } = useTranslation();

  const isMobile = useMobile();

  const [isImageCropVisible, setIsImageCropVisible] = useState<boolean>(false);

  const [imageWorkspace, setImageWorkspace] = useState<string | undefined>(
    undefined,
  );

  const [imageFilename, setImageFilename] = useState<string | undefined>(
    undefined,
  );

  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined,
  );

  const onCloseImageCropModal = () => {
    setIsImageCropVisible(false);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      if (file) {
        if (file.size / 1024 / 1024 > maxFileSize) {
          toast.error(t('errorMessages.fileSizeError'));
          return;
        }
        const url = URL.createObjectURL(file);
        setImageWorkspace(url);
        setImageFilename(file.name);
        setIsImageCropVisible(true);
      }
    },
    [t, maxFileSize],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: imageExtensionsUpload,
    multiple: false,
    noClick: true,
    minSize: 0,
  });

  const handleSetPhoto = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    open();
  };

  const handleRemovePhoto = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImagePreview(undefined);
    onChange(null);
  };

  useEffect(() => {
    if (typeof value === 'string' && value.length > 0) {
      setImagePreview(value);
    }
  }, [value]);

  const { targetRef, TooltipElement } = useTooltip({
    top: isMobile ? 8 : 15,
    left: isMobile ? 0 : 58,
    text: t('messages.photoShouldBeInAspect', {
      aspect: aspect.replace('/', ':'),
    }),
    disableWidth: true,
  });

  return (
    <div
      className={clsx(
        'flex w-full flex-col',
        variant === 'default' &&
          'h-[214px] min-h-[214px] md:h-[132px] md:min-h-[132px]',
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps()}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} type="file" accept={'image/*'} />
      {variant === 'default' && (
        <div className="flex md:gap-2">
          <InputLabel text={labelText} htmlFor={name} variant="secondary" />

          <span className="ml-auto cursor-pointer md:ml-0" ref={targetRef}>
            <InfoIcon isWhite />
          </span>
        </div>
      )}
      <div className="relative flex">
        <TooltipElement />
      </div>
      <div
        className={clsx(
          'flex flex-col gap-3 pt-2 md:pb-1',
          variant === 'default' && 'md:flex-row',
        )}
      >
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="preview"
            className={clsx(
              'mr-3 h-20  rounded-xs bg-brand-700 object-cover',
              variant === 'offer' && 'w-full',
              variant === 'default' && isImagePreviewHorizontal
                ? 'w-[138px] min-w-[138px]'
                : 'w-20 min-w-[80px]',
            )}
          />
        ) : (
          <div
            className={clsx(
              'mr-3 h-20 rounded-xs bg-brand-700',
              variant === 'offer' && 'w-full',
              variant === 'default' && !imagePreview ? 'flex' : 'hidden',
              variant === 'default' && isImagePreviewHorizontal
                ? 'w-[138px] min-w-[138px]'
                : 'w-20 min-w-[80px]',
            )}
          />
        )}
        {variant === 'offer' && imagePreview && (
          <span className="text-xs text-brand-1700">
            {t('messages.offerCategoryCover')}
          </span>
        )}

        <div className="flex w-full flex-col gap-3 pb-1">
          <ImageCropModal
            onClose={onCloseImageCropModal}
            visible={isImageCropVisible}
            onChange={onChange}
            imagesWorkspace={imageWorkspace ? [imageWorkspace] : []}
            imageFilename={imageFilename}
            setImagePreview={setImagePreview}
            labelText={labelText}
            isSingleImage
            aspect={aspect}
          />
          <div className="flex gap-4">
            <input
              type="text"
              className={clsx(
                'h-[42px] w-full rounded-xs border border-brand-700 bg-transparent px-4 placeholder-brand-1700 outline-none',
              )}
              placeholder={placeholder}
              value={value instanceof File ? value.name : value || ''}
              readOnly
            />
            {!imagePreview && !isReadOnly ? (
              <ButtonWithIcon
                type="button"
                text={t('messages.chooseFile')}
                variant="secondary"
                icon={UploadIcon}
                onClick={handleSetPhoto}
                isJustIconOnMobile
              />
            ) : null}
            {imagePreview && !isReadOnly ? (
              <div className="flex justify-end gap-4">
                <ButtonWithIcon
                  type="button"
                  text={t('messages.delete')}
                  variant="secondary"
                  icon={TrashIcon}
                  onClick={handleRemovePhoto}
                  isDeleteButton
                  isJustIconOnMobile
                />
                <ButtonWithIcon
                  type="button"
                  text={t('messages.change')}
                  variant="primary"
                  icon={PenIcon}
                  onClick={handleSetPhoto}
                  isJustIconOnMobile
                />
              </div>
            ) : null}
          </div>
          <p className="text-xs text-brand-1700">
            {t('messages.extensionsAndSize', { maxFileSize })}
          </p>
        </div>
      </div>
      {!!error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default ImageUpload;
