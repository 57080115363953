const EyeClosedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9617 22.9692L15.6157 18.6537C15.1028 18.8435 14.5428 18.9903 13.9357 19.0942C13.3287 19.198 12.6835 19.2499 12.0002 19.2499C9.41684 19.2499 7.09926 18.5329 5.04742 17.0989C2.99542 15.6649 1.52584 13.7986 0.638672 11.4999C0.991172 10.5999 1.43892 9.76342 1.98192 8.99042C2.52492 8.21725 3.14767 7.52492 3.85017 6.91342L1.03092 4.03842L2.53492 2.53467L21.4654 21.4652L19.9617 22.9692ZM12.0002 16.1769C12.1758 16.1769 12.3608 16.1631 12.5549 16.1354C12.7493 16.1079 12.9067 16.0678 13.0272 16.0152L7.48492 10.4729C7.43226 10.6229 7.39217 10.7893 7.36467 10.9719C7.337 11.1548 7.32317 11.3308 7.32317 11.4999C7.32317 12.8179 7.77317 13.9269 8.67317 14.8269C9.57317 15.7269 10.6822 16.1769 12.0002 16.1769ZM19.5289 16.6594L16.2924 13.4229C16.4206 13.1434 16.5168 12.8403 16.5809 12.5134C16.6451 12.1864 16.6772 11.8486 16.6772 11.4999C16.6772 10.1819 16.2272 9.07292 15.3272 8.17292C14.4272 7.27292 13.3182 6.82292 12.0002 6.82292C11.6515 6.82292 11.2919 6.86492 10.9214 6.94892C10.5509 7.03292 10.2727 7.12558 10.0867 7.22692L7.45992 4.60967C8.14192 4.325 8.86659 4.11058 9.63392 3.96642C10.4013 3.82208 11.19 3.74992 12.0002 3.74992C14.577 3.74992 16.8863 4.46692 18.9279 5.90092C20.9696 7.33492 22.4475 9.20125 23.3617 11.4999C22.9745 12.5101 22.4543 13.4524 21.8012 14.3269C21.1478 15.2013 20.3904 15.9788 19.5289 16.6594ZM14.7347 11.8749L11.6252 8.77492C12.0828 8.70825 12.5117 8.74508 12.9117 8.88542C13.3117 9.02592 13.6533 9.24292 13.9367 9.53642C14.2265 9.81342 14.447 10.1577 14.5982 10.5692C14.7495 10.9807 14.795 11.4159 14.7347 11.8749Z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default EyeClosedIcon;
