import { useAppSelector } from '../../../../hooks/useRedux';

const UserInfoBox = () => {
  const user = useAppSelector((state) => state.user.user);
  const userInitials = `${user?.firstName[0]}${user?.lastName[0]}`;

  return (
    <div className="flex items-center gap-2 px-2 pb-8">
      <span className="align-center flex h-8 max-h-8 w-8 min-w-8 max-w-8 justify-center whitespace-nowrap rounded-full bg-brand-900 px-[6px] py-1 font-medium">
        {userInitials}
      </span>
      <div className="flex flex-col overflow-hidden">
        <span className="truncate font-medium">
          {user?.firstName} {user?.lastName}
        </span>
        <span className="truncate text-xs text-brand-1700">{user?.email}</span>
      </div>
    </div>
  );
};

export default UserInfoBox;
