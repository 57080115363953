import { SVGProps } from 'react';

const CheckLargeIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M10.6002 16.353L17.8732 9.07997L16.3694 7.57614L10.6002 13.3454L7.70017 10.4454L6.19635 11.9492L10.6002 16.353ZM2.7002 21.2992V2.69922H21.3001V21.2992H2.7002Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckLargeIcon;
