import axios from 'axios';

import { IAxiosMiddlewareParams } from '../types/IAxiosMiddlewareParams';
import { getTokenFromFirebaseAuth } from '../functions/functions';

// url: without deploy base url, for example: /space-plan/list-space-plans
export const axiosMiddleware = async ({
  method = 'GET',
  url,
  headers = {},
  params = {},
  data,
}: IAxiosMiddlewareParams) => {
  const token = await getTokenFromFirebaseAuth();

  const instance = axios.create({
    baseURL: import.meta.env.VITE_BASE_REST_API_URL,
    timeout: 20000,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  const response = await instance.request({
    method,
    url,
    params,
    data,
  });

  return response.data;
};
