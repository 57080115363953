const SearchSmallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4729 17.6895L11.1972 12.4139C10.7805 12.744 10.2701 13.0028 9.66596 13.1903C9.0618 13.3778 8.46324 13.4715 7.8703 13.4715C6.3071 13.4715 4.98361 12.9298 3.89984 11.8463C2.81606 10.7627 2.27417 9.43955 2.27417 7.87673C2.27417 6.3139 2.81593 4.99028 3.89946 3.90588C4.98299 2.82149 6.30617 2.2793 7.869 2.2793C9.43182 2.2793 10.7554 2.82119 11.8398 3.90496C12.9242 4.98874 13.4664 6.31223 13.4664 7.87542C13.4664 8.48439 13.377 9.0736 13.198 9.64305C13.019 10.2125 12.7629 10.7114 12.4296 11.1398L17.726 16.4363L16.4729 17.6895ZM7.8703 11.6799C8.94402 11.6799 9.84653 11.3143 10.5778 10.583C11.3091 9.85166 11.6748 8.94914 11.6748 7.87542C11.6748 6.8017 11.3091 5.89919 10.5778 5.16788C9.84653 4.43658 8.94402 4.07092 7.8703 4.07092C6.79657 4.07092 5.89406 4.43658 5.16275 5.16788C4.43146 5.89919 4.06582 6.8017 4.06582 7.87542C4.06582 8.94914 4.43146 9.85166 5.16275 10.583C5.89406 11.3143 6.79657 11.6799 7.8703 11.6799Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchSmallIcon;
