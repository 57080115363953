import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { IGetStatisticsDataThunk } from '../../types/Statistics';
import i18n from '../../configuration/i18n';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import { statisticsListResponseValidateSchema } from '../../schema/StatisticsSchemas';

export const statisticsListThunk = createAsyncThunk(
  'statistics/statisticsList',
  async (
    { startDate, endDate }: IGetStatisticsDataThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        startDate,
        endDate,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.statisticsList,
        method: 'GET',
        params,
      });

      statisticsListResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      toast.error(
        i18n.t(
          'errorMessages.somethingWentWrongDuringFetchingPageViewsStatistics',
        ),
      );
      return rejectWithValue(error);
    }
  },
);
