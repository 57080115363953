import { createSlice } from '@reduxjs/toolkit';

import { createSupportTicketThunk } from '../thunks/supportThunk';
import { ISupportSliceState } from '../../types/Support';

const initialState: ISupportSliceState = {
  createSupportTicketStatus: null,
};

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createSupportTicketThunk.pending, (state) => {
      state.createSupportTicketStatus = 'loading';
    });
    builder.addCase(
      createSupportTicketThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;

        state.createSupportTicketStatus = 'success';
      },
    );
    builder.addCase(createSupportTicketThunk.rejected, (state) => {
      state.createSupportTicketStatus = 'failed';
    });
  },
});

export default supportSlice.reducer;
