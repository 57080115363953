import { createSlice } from '@reduxjs/toolkit';

import {
  listEmployeesThunk,
  createEmployeeThunk,
  deleteEmployeeThunk,
  getPlaceForSettingsThunk,
  updateEmployeeThunk,
  updatePlaceThunk,
  updateGalleryThunk,
  setPaymentMethodThunk,
  getEmployeeThunk,
  getPlaceBaseSettingsThunk,
  setSpacePlanStatusThunk,
  updateFirstStepsToFalseThunk,
  updateTableStatusThunk,
  updateOpeningHoursThunk,
  getCurrentSmsLimitThunk,
} from '../thunks/settingsThunk';
import { ISettingsSliceState } from '../../types/Settings';
import { getCategoriesThunk } from '../thunks/firstStepsThunk';
import { STATUS_COLORS, employeeRolePriority } from '../../constants/constants';
import { IImage } from '../../components/UI/organisms/ImageGalleryModal/ImageGalleryModal.types';

const initialState: ISettingsSliceState = {
  place: null,
  timezone: null,
  isSpacePlanPublished: false,
  isPlaceFirstSteps: false,
  isActive: false,
  contractStartDate: null,
  contractEndDate: null,
  tableStatuses: {
    freeTable: STATUS_COLORS.slate,
    reservationWithoutSms: STATUS_COLORS.yellow,
    reservationWithSms: STATUS_COLORS.purple,
    reservationInProgress: STATUS_COLORS.green,
    reservationDelayed: STATUS_COLORS.orange,
    reservationCanceled: STATUS_COLORS.red,
  },
  openingHours: null,
  categories: null,
  currentEmployeeId: null,
  currentUsers: null,
  userOffset: 0,
  minReservationTime: 60,
  maxReservationTime: 240,
  slotInterval: 30,
  beforeReservationTime: 15,
  isChatAvailable: false,
  isSchedule: false,
  isDialogMinimalized: false,
  employees: null,
  currentEmployee: null,
  listEmployeesStatus: null,
  getEmployeeStatus: null,
  createEmployeeStatus: null,
  updateEmployeeStatus: null,
  deleteEmployeeStatus: null,
  updateGalleryStatus: null,
  setPaymentMethodStatus: null,
  getPlaceForSettingsStatus: null,
  updatePlaceStatus: null,
  getCategoriesStatus: null,
  updateOpeningHoursStatus: null,
  updateTableStatusesStatus: null,
  getPlaceFirstStepsStatus: null,
  setSpacePlanStatusStatus: null,
  updateFirstStepsToFalseStatus: null,
  currentSms: null,
  getCurrentSmsStatus: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    clearSettings: () => initialState,
    removeFromGallery: (state, { payload }) => {
      if (!state.place) return;
      if (state.place.gallery) {
        state.place.gallery = state.place.gallery.filter(
          (item) => !payload.some((image: IImage) => image.imgUrl === item),
        );
      }
    },
    sortByGalleryIds: (state, { payload }) => {
      // const { sortedArray } = payload;
      // const sortedGallery: string[] = [];

      // sortedArray.map((item: string) => {
      //   state.place?.gallery.map((photoItem) => {
      //     if (item === photoItem) {
      //       sortedGallery.push(photoItem);
      //     }
      //   });
      // });

      // if (state.place) {
      //   state.place.gallery = sortedGallery;
      // }
      state.updateGalleryStatus = null;
    },
    setCurrentEmployeeId: (state, { payload }) => {
      state.currentEmployeeId = payload;
    },
    resetCurrentEmployee: (state) => {
      state.currentEmployee = null;
    },
    resetDeleteEmployeeStatus: (state) => {
      state.deleteEmployeeStatus = null;
    },
    // TODO uncomment and adapt if employees pagination will be needed
    // getNextUserChunk: (state) => {
    // const nextOffset = state.userOffset + 5;
    // if (state.place?.users) {
    //   const nextChunk = state.place.users.slice(state.userOffset, nextOffset);
    //   state.currentUsers = state.currentUsers
    //     ? [...state.currentUsers, ...nextChunk]
    //     : nextChunk;
    //   state.userOffset = nextOffset;
    // }
    // },
    setWindowMinimalized: (state) => {
      state.isDialogMinimalized = true;
    },
    resetWindowMinimalized: (state) => {
      state.isDialogMinimalized = initialState.isDialogMinimalized;
    },
  },
  extraReducers: (builder) => {
    // getPlaceBaseSettingsThunk
    builder
      .addCase(getPlaceBaseSettingsThunk.pending, (state) => {
        state.getPlaceFirstStepsStatus = 'loading';
      })
      .addCase(getPlaceBaseSettingsThunk.fulfilled, (state, { payload }) => {
        state.getPlaceFirstStepsStatus = 'success';
        if (!payload) return;
        state.isSpacePlanPublished = payload.isSpacePlanPublished;
        state.isPlaceFirstSteps = payload.isFirstSteps;
        state.contractStartDate = payload.contractStartDate;
        state.contractEndDate = payload.contractEndDate;
        state.isActive = payload.isActive;
        state.tableStatuses = payload.tableStatuses
          ? payload.tableStatuses
          : initialState.tableStatuses;
        state.timezone = payload.timezone;
        state.minReservationTime = payload.minReservationTime;
        state.maxReservationTime = payload.maxReservationTime;
        state.slotInterval = payload.slotInterval;
        state.isChatAvailable = payload.isChatAvailable;
        state.isSchedule = payload.isSchedule;
        state.openingHours = payload.openingHours;
        state.beforeReservationTime = payload.beforeReservationTime;
      })
      .addCase(getPlaceBaseSettingsThunk.rejected, (state) => {
        state.getPlaceFirstStepsStatus = 'failed';
      });

    // setAvailabilityStatus
    builder.addCase(setSpacePlanStatusThunk.pending, (state) => {
      state.setSpacePlanStatusStatus = 'loading';
    });
    builder.addCase(setSpacePlanStatusThunk.fulfilled, (state, { payload }) => {
      state.setSpacePlanStatusStatus = 'success';
      state.isSpacePlanPublished = payload;
    });
    builder.addCase(setSpacePlanStatusThunk.rejected, (state) => {
      state.setSpacePlanStatusStatus = 'failed';
    });

    // updateFirstStepsToFalseThunk
    builder
      .addCase(updateFirstStepsToFalseThunk.pending, (state) => {
        state.updateFirstStepsToFalseStatus = 'loading';
      })
      .addCase(updateFirstStepsToFalseThunk.fulfilled, (state, { payload }) => {
        state.isPlaceFirstSteps = payload.firstSteps;
        state.updateFirstStepsToFalseStatus = 'success';
      })
      .addCase(updateFirstStepsToFalseThunk.rejected, (state) => {
        state.updateFirstStepsToFalseStatus = 'failed';
      });

    // getPlaceForSettingsThunk
    builder
      .addCase(getPlaceForSettingsThunk.pending, (state) => {
        state.getPlaceForSettingsStatus = 'loading';
      })
      .addCase(getPlaceForSettingsThunk.fulfilled, (state, { payload }) => {
        state.getPlaceForSettingsStatus = 'success';
        state.place = payload;

        // TODO uncomment and adapt if employees pagination will be needed
        // state.currentUsers = payload.users?.slice(0, 5);
        // state.userOffset = 5;
      })

      .addCase(getPlaceForSettingsThunk.rejected, (state) => {
        state.getPlaceForSettingsStatus = 'failed';
      });

    // updatePlaceThunk
    builder
      .addCase(updatePlaceThunk.pending, (state) => {
        state.updatePlaceStatus = 'loading';
      })
      .addCase(updatePlaceThunk.fulfilled, (state, { payload }) => {
        state.updatePlaceStatus = 'success';
        if (!state.place) return;

        state.place = {
          ...state.place,
          ...payload,
        };

        // base settings update
        state.minReservationTime =
          payload.minReservationTime ?? state.minReservationTime;
        state.maxReservationTime =
          payload.maxReservationTime ?? state.maxReservationTime;
        state.slotInterval = payload.slotInterval ?? state.slotInterval;
        state.isChatAvailable =
          payload.isChatAvailable ?? state.isChatAvailable;
        state.beforeReservationTime =
          payload.beforeReservationTime ?? state.beforeReservationTime;
      })
      .addCase(updatePlaceThunk.rejected, (state) => {
        state.updatePlaceStatus = 'failed';
      });

    builder
      .addCase(getCategoriesThunk.pending, (state) => {
        state.getCategoriesStatus = 'loading';
      })
      .addCase(getCategoriesThunk.fulfilled, (state, { payload }) => {
        state.categories = payload;
        state.getCategoriesStatus = 'success';
      })
      .addCase(getCategoriesThunk.rejected, (state) => {
        state.getCategoriesStatus = 'failed';
      });

    builder
      .addCase(listEmployeesThunk.pending, (state) => {
        state.listEmployeesStatus = 'loading';
      })
      .addCase(listEmployeesThunk.fulfilled, (state, { payload }) => {
        state.listEmployeesStatus = 'success';

        state.employees = payload;

        // TODO uncomment and adapt if employees pagination will be needed
        // state.currentUsers = payload;
      })
      .addCase(listEmployeesThunk.rejected, (state) => {
        state.listEmployeesStatus = 'failed';
      });

    builder
      .addCase(getEmployeeThunk.pending, (state) => {
        state.getEmployeeStatus = 'loading';
      })
      .addCase(getEmployeeThunk.fulfilled, (state, { payload }) => {
        state.getEmployeeStatus = 'success';

        state.currentEmployee = payload;
      })
      .addCase(getEmployeeThunk.rejected, (state) => {
        state.getEmployeeStatus = 'failed';
      });

    builder
      .addCase(createEmployeeThunk.pending, (state) => {
        state.createEmployeeStatus = 'loading';
      })
      .addCase(createEmployeeThunk.fulfilled, (state, { payload }) => {
        state.createEmployeeStatus = 'success';

        if (state.employees) {
          const newEmployee = payload;
          const newEmployeePriority = employeeRolePriority[newEmployee.role];

          let insertIndex = state.employees.findIndex((employee) => {
            const employeePriority = employeeRolePriority[employee.role];
            return employeePriority > newEmployeePriority;
          });
          if (insertIndex === -1) {
            insertIndex = state.employees.length;
          }

          state.employees.splice(insertIndex, 0, newEmployee);
        }

        // TODO uncomment and adapt if employees pagination will be needed
        // if (state.currentUsers) {
        //   state.currentUsers.push(payload);
        // }
      })
      .addCase(createEmployeeThunk.rejected, (state) => {
        state.createEmployeeStatus = 'failed';
      });

    builder
      .addCase(updateEmployeeThunk.pending, (state) => {
        state.updateEmployeeStatus = 'loading';
      })
      .addCase(updateEmployeeThunk.fulfilled, (state, { payload }) => {
        state.updateEmployeeStatus = 'success';

        if (state.employees) {
          const employeeIndex = state.employees.findIndex(
            (employee) => employee.firebaseId === state.currentEmployeeId,
          );
          if (employeeIndex !== -1) {
            state.employees[employeeIndex] = {
              ...state.employees[employeeIndex],
              ...(payload.email && { email: payload.email }),
              ...(payload.firstName && { firstName: payload.firstName }),
              ...(payload.lastName && { lastName: payload.lastName }),
              ...(payload.role && { role: payload.role }),
            };
          }
        }
        // TODO uncomment and adapt if employees pagination will be needed
        // if (state.currentUsers) {
        //   const employeeIndex = state.currentUsers.findIndex(
        //     (employee) => employee.firebaseId === state.currentEmployeeId,
        //   );
        //   if (employeeIndex !== -1) {
        //     state.currentUsers[employeeIndex] = {
        //       ...state.currentUsers[employeeIndex],
        //       ...(payload.email && { email: payload.email }),
        //       ...(payload.firstName && { firstName: payload.firstName }),
        //       ...(payload.lastName && { lastName: payload.lastName }),
        //       ...(payload.role && { role: payload.role }),
        //     };
        //   }
        // }
      })
      .addCase(updateEmployeeThunk.rejected, (state) => {
        state.updateEmployeeStatus = 'failed';
      });

    builder
      .addCase(deleteEmployeeThunk.pending, (state) => {
        state.deleteEmployeeStatus = 'loading';
      })
      .addCase(deleteEmployeeThunk.fulfilled, (state) => {
        state.deleteEmployeeStatus = 'success';

        if (state.employees) {
          state.employees = state.employees.filter(
            (employee) => employee.firebaseId !== state.currentEmployeeId,
          );
        }
        state.currentEmployeeId = null;
        state.currentEmployee = null;
      })
      .addCase(deleteEmployeeThunk.rejected, (state) => {
        state.deleteEmployeeStatus = 'failed';
      });

    builder
      .addCase(updateGalleryThunk.pending, (state) => {
        state.updateGalleryStatus = 'loading';
      })
      .addCase(updateGalleryThunk.fulfilled, (state, { payload }) => {
        state.updateGalleryStatus = 'success';
        if (!state.place) return;

        state.place.gallery = payload;
      })
      .addCase(updateGalleryThunk.rejected, (state) => {
        state.updateGalleryStatus = 'failed';
      });

    // setPaymentMethodThunk
    builder.addCase(setPaymentMethodThunk.pending, (state) => {
      state.setPaymentMethodStatus = 'loading';
    });
    builder.addCase(setPaymentMethodThunk.fulfilled, (state, { payload }) => {
      state.setPaymentMethodStatus = 'success';
      if (!state.place) return;
      state.place.paymentMethods = payload;
    });
    builder.addCase(setPaymentMethodThunk.rejected, (state) => {
      state.setPaymentMethodStatus = 'failed';
    });

    // updateTableStatusThunk
    builder
      .addCase(updateTableStatusThunk.pending, (state) => {
        state.updateTableStatusesStatus = 'loading';
      })
      .addCase(updateTableStatusThunk.fulfilled, (state, { payload }) => {
        state.tableStatuses = payload;
        state.updateTableStatusesStatus = 'success';
      })
      .addCase(updateTableStatusThunk.rejected, (state) => {
        state.updateTableStatusesStatus = 'failed';
      });

    // updateOpeningHoursThunk
    builder
      .addCase(updateOpeningHoursThunk.pending, (state) => {
        state.updateOpeningHoursStatus = 'loading';
      })
      .addCase(updateOpeningHoursThunk.fulfilled, (state, { payload }) => {
        if (!payload) return;

        state.openingHours = payload;
        state.updateOpeningHoursStatus = 'success';
      })
      .addCase(updateOpeningHoursThunk.rejected, (state) => {
        state.updateOpeningHoursStatus = 'failed';
      });

    // getCurrentSms
    builder
      .addCase(getCurrentSmsLimitThunk.pending, (state) => {
        state.getCurrentSmsStatus = 'loading';
      })
      .addCase(getCurrentSmsLimitThunk.fulfilled, (state, { payload }) => {
        state.currentSms = payload;
        state.getCurrentSmsStatus = 'success';
      })
      .addCase(getCurrentSmsLimitThunk.rejected, (state) => {
        state.getCurrentSmsStatus = 'failed';
      });
  },
});

export const {
  clearSettings,
  setCurrentEmployeeId,
  resetDeleteEmployeeStatus,
  removeFromGallery,
  setWindowMinimalized,
  resetWindowMinimalized,
  sortByGalleryIds,
  resetCurrentEmployee,
} = settingsSlice.actions;

export default settingsSlice.reducer;
