const UnseenMessagesTabIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.87508 11.4577H11.75V10.2077H4.87508V11.4577ZM4.87508 8.64516H15.125V7.3952H4.87508V8.64516ZM4.87508 5.83266H15.125V4.5827H4.87508V5.83266ZM1.41675 18.4063V1.16602H18.5834V14.916H4.90714L1.41675 18.4063Z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default UnseenMessagesTabIcon;
