interface IInfoIconProps {
  isWhite?: boolean;
}

const InfoIcon = ({ isWhite }: IInfoIconProps) => {
  return (
    <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.625 14.291h1.791V9.166H9.625v5.125zm.871-6.885a.861.861 0 00.63-.252.843.843 0 00.255-.625.862.862 0 00-.252-.63.843.843 0 00-.625-.255.862.862 0 00-.63.252.843.843 0 00-.256.625c0 .249.085.459.253.63.168.17.377.255.625.255zm.005 11.177a8.36 8.36 0 01-3.347-.676 8.669 8.669 0 01-2.726-1.835 8.665 8.665 0 01-1.835-2.724A8.354 8.354 0 011.917 10c0-1.191.225-2.31.677-3.358A8.697 8.697 0 014.43 3.91a8.52 8.52 0 012.725-1.828 8.45 8.45 0 013.342-.665c1.191 0 2.31.222 3.358.665a8.522 8.522 0 012.734 1.827 8.517 8.517 0 011.828 2.734A8.523 8.523 0 0119.083 10a8.44 8.44 0 01-.665 3.346 8.537 8.537 0 01-1.827 2.72 8.693 8.693 0 01-2.733 1.84 8.387 8.387 0 01-3.357.677z"
        fill={isWhite ? 'white' : '#B3ADC1'}
      />
    </svg>
  );
};

InfoIcon.defaultProps = {
  isWhite: false,
};

export default InfoIcon;
