import { ISpinnerProps } from '../../components/UI/atoms/Spinner/Spinner.types';

const LoaderIcon = ({ isButtonWhite }: ISpinnerProps) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5.042A4.958 4.958 0 1014.958 10h1.75a6.708 6.708 0 11-3.832-6.062l-.752 1.58A4.935 4.935 0 0010 5.042z"
        fill={isButtonWhite ? '#000' : '#fff'}
      />
    </svg>
  );
};

export default LoaderIcon;
