import { Link } from 'react-router-dom';

import { routes } from '../../../../constants/constants';
import logoImageSvg from '../../../../assets/images/gradient-logo.svg';
import logoImagePng from '../../../../assets/images/gradient-logo.png';
import { isSafari } from '../../../../functions/functions';

const HeaderLogo = () => {
  return (
    <div className="flex w-full justify-center">
      <Link to={routes.home}>
        <div className="mr-6 h-[64px] w-[80px] opacity-50 xl:absolute xl:left-0 xl:top-0 xl:w-[110px]" />
      </Link>
      <img
        src={isSafari() ? logoImagePng : logoImageSvg}
        alt="logo"
        width={231}
        height={52}
        className="absolute left-1/2 top-[-52px] -z-30 -translate-x-1/2 scale-75 xl:left-[-50px] xl:translate-x-0"
      />
    </div>
  );
};

export default HeaderLogo;
