import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';
import { AxiosError } from 'axios';

import { IMarkNotificationModalAsCheckedThunk } from '../../types/User';
import { firestore, functions } from '../../firebase/firebaseConfig';
import i18n from '../../configuration/i18n';
import { REST_API_URLS, apiErrorMessages } from '../../constants/constants';
import { GetEmployeeSchema } from '../../schema/EmployeeSchemas';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { errorSchema } from '../../schema/ErrorSchemas';

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (uid: string | undefined, { rejectWithValue }) => {
    try {
      if (!uid) return;

      const result = await axiosMiddleware({
        url: REST_API_URLS.getEmployee(uid),
        method: 'GET',
      });

      const validatedData = GetEmployeeSchema.parse(result);

      return validatedData.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const parsedError = errorSchema.safeParse(error.response?.data);

        if (!parsedError.success) {
          toast.error(
            i18n.t('errorMessages.somethingWentWrongDuringFetchingEmployee'),
          );
          return rejectWithValue(error);
        }

        const err = parsedError.data.error;
        if (err && err.message === 'No employee') {
          toast.error(
            i18n.t('errorMessages.cantLoginToTheAdminPanelWithUserAccount'),
          );
        } else {
          toast.error(
            i18n.t('errorMessages.somethingWentWrongDuringFetchingEmployee'),
          );
        }
      } else {
        toast.error(
          i18n.t('errorMessages.somethingWentWrongDuringFetchingEmployee'),
        );
      }
      return rejectWithValue(error);
    }
  },
);

export const disableTempPasswordUser = createAsyncThunk(
  'user/disableTempPassword',
  async (_, { rejectWithValue }) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updateTempPassword,
        method: 'POST',
        data: { tempPassword: false },
      });

      return { tempPassword: false };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSubscriptionThunk = createAsyncThunk(
  'settings/createSubscription',
  async (_, { rejectWithValue }) => {
    try {
      const createSubscription = httpsCallable(functions, 'createSubscription');

      const response = await createSubscription();

      const { data } = response;

      return data as string;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === apiErrorMessages.CHECKOUT_EXISTS) {
          toast.error(i18n.t('errorMessages.checkoutExist'));
        } else if (error.message === apiErrorMessages.SUBSCRIPTION_EXISTS) {
          toast.error(i18n.t('errorMessages.subscriptionExist'));
        } else {
          toast.error(i18n.t('errorMessages.somethingWentWrong'));
        }
      }

      return rejectWithValue(error);
    }
  },
);

export const cancelSubscriptionThunk = createAsyncThunk(
  'settings/cancelSubscription',
  async (_, { rejectWithValue }) => {
    try {
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');

      const response = await cancelSubscription();

      const { data } = response;

      toast.success(i18n.t('messages.subscriptionCancelled'));

      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === apiErrorMessages.NO_SUBSCRIPTION) {
          toast.error(i18n.t('errorMessages.youAreNotAuthorizedToSeeThisPage'));
        } else {
          toast.error(i18n.t('errorMessages.somethingWentWrong'));
        }
      }
      return rejectWithValue(error);
    }
  },
);

export const uncancelSubscriptionThunk = createAsyncThunk(
  'settings/uncancelSubscription',
  async (_, { rejectWithValue }) => {
    try {
      const cancelSubscription = httpsCallable(
        functions,
        'uncancelSubscription',
      );

      const response = await cancelSubscription();

      const { data } = response;

      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === apiErrorMessages.NO_SUBSCRIPTION) {
          toast.error(i18n.t('errorMessages.youAreNotAuthorizedToSeeThisPage'));
        } else {
          toast.error(i18n.t('errorMessages.somethingWentWrong'));
        }
      }
      return rejectWithValue(error);
    }
  },
);

export const markNotificationModalAsCheckedThunk = createAsyncThunk(
  'user/markNotificationModalAsCheckedThunk',
  async (
    { userId, callback }: IMarkNotificationModalAsCheckedThunk,
    { rejectWithValue },
  ) => {
    try {
      const userRef = doc(firestore, 'users', userId);

      await updateDoc(userRef, {
        isNotificationsModalChecked: true,
      });

      callback();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
