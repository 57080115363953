const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.7674 21.2266L13.4367 14.8959C12.9367 15.292 12.3242 15.6026 11.5992 15.8276C10.8742 16.0526 10.1559 16.1651 9.44436 16.1651C7.56852 16.1651 5.98034 15.515 4.6798 14.2147C3.37927 12.9145 2.729 11.3267 2.729 9.4513C2.729 7.5759 3.37912 5.98756 4.67935 4.68627C5.97959 3.38501 7.5674 2.73438 9.4428 2.73438C11.3182 2.73438 12.9065 3.38464 14.2078 4.68518C15.5091 5.98571 16.1597 7.57389 16.1597 9.44973C16.1597 10.1805 16.0524 10.8875 15.8376 11.5709C15.6229 12.2542 15.3155 12.8529 14.9155 13.367L21.2713 19.7228L19.7674 21.2266ZM9.44436 14.0151C10.7328 14.0151 11.8158 13.5763 12.6934 12.6988C13.571 11.8212 14.0098 10.7382 14.0098 9.44973C14.0098 8.16126 13.571 7.07824 12.6934 6.20068C11.8158 5.32311 10.7328 4.88432 9.44436 4.88432C8.15589 4.88432 7.07287 5.32311 6.1953 6.20068C5.31775 7.07824 4.87898 8.16126 4.87898 9.44973C4.87898 10.7382 5.31775 11.8212 6.1953 12.6988C7.07287 13.5763 8.15589 14.0151 9.44436 14.0151Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchIcon;
