import { useLocation } from 'react-router-dom';

import {
  availabilityTemplateTabs,
  routes,
} from '../../../../constants/constants';
import Tab from '../../atoms/Tab/Tab';

const AvailabilityTemplateTabs = ({ isEmployee }: { isEmployee: boolean }) => {
  const { pathname } = useLocation();

  const hideSpacePlanForEmployee = (route: string) => {
    return route === routes.availabilitySpacePlan && isEmployee;
  };

  return (
    <div className="flex w-full overflow-auto">
      {availabilityTemplateTabs.map((tab) => {
        if (hideSpacePlanForEmployee(tab.route)) {
          return null;
        }
        return (
          <Tab
            key={tab.route}
            label={tab.label}
            route={tab.route}
            icon={tab.icon}
            isActive={pathname === tab.route}
          />
        );
      })}
    </div>
  );
};

export default AvailabilityTemplateTabs;
