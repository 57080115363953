import { z } from 'zod';

export const StatisticsSchema = z.array(
  z.object({
    date: z.string(),
    count: z.number(),
  }),
);

export const ReservationStatisticsSchema = z.array(
  z.object({
    date: z.string(),
    countFunzy: z.number(),
    countGoogle: z.number(),
  }),
);

export const statisticsListResponseValidateSchema = z.array(
  z.object({
    id: z.string().uuid(),
    date: z.string(),
    countReservation: z.number(),
    countSMS: z.number(),
    countUsedCoupon: z.number(),
    countViewProfile: z.number(),
  }),
);
