import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import { MouseEvent, useState } from 'react';

import { IDropdownOption } from '../../atoms/MoreOptionsDropdown/MoreOptionsDropdown.types';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import PenIcon from '../../../../assets/icons/PenIcon';
import MoreOptionsDropdown from '../../atoms/MoreOptionsDropdown/MoreOptionsDropdown';
import Button from '../Button/Button';
import TableRowNameWithDescription from '../../atoms/TableRowNameWithDescription/TableRowNameWithDescription';
import { getOfferCategoryImage } from '../../../../functions/storage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  setBasicOfferInfomations,
  setOffer,
} from '../../../../redux/features/offerSlice';
import { IOfferProps } from './Offer.types';
import { changeOrderOfOffersThunk } from '../../../../redux/thunks/offerThunk';
import { reduxStatus, routes } from '../../../../constants/constants';
import useMobile from '../../../../hooks/useIsMobile';

const Offer = ({ offer, showDeleteModal, showEditModal }: IOfferProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: offer.id,
    });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const isMobile = useMobile();

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const offers = useAppSelector((state) => state.offer.offers);

  const updateOffersStatus = useAppSelector(
    (state) => state.offer.updateOffersStatus,
  );

  const [currentlyPublishing, setCurrentlyPublising] = useState<string>('');

  const handleEditOffer = () => {
    dispatch(setOffer(offer));
    showEditModal();
  };

  const handleDeleteOffer = () => {
    dispatch(setOffer(offer));
    showDeleteModal();
  };

  const dropownOptions: IDropdownOption[] = [
    {
      label: t('messages.edit'),
      icon: PenIcon,
      onClick: handleEditOffer,
    },
    {
      label: t('messages.delete'),
      icon: TrashIcon,
      onClick: handleDeleteOffer,
      isDelete: true,
    },
  ];

  const handlePublishOffer = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!offers) return;
    setCurrentlyPublising(offer.id);
    const newOffers = offers.map((item) => {
      if (item.id === offer.id) {
        return { ...item, published: !item.published };
      }
      return item;
    });
    dispatch(changeOrderOfOffersThunk({ offers: newOffers }));
  };

  const handleNavigateToOffer = () => {
    dispatch(setBasicOfferInfomations(offer));
    navigate(`${routes.offer}/${offer.id}`);
  };

  return (
    <div>
      <div
        className="flex h-[72px] w-full items-center justify-between border-b bg-brand-50 px-2 xl:px-4"
        style={style}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...listeners}
        ref={setNodeRef}
        onClick={handleNavigateToOffer}
      >
        <div className="flex items-center gap-4 truncate">
          <TableRowNameWithDescription
            key={offer.id}
            description={offer.categories ? offer.categories.join(', ') : ''}
            img={
              offer.photo
                ? getOfferCategoryImage(offer.photo, '150', '150')
                : undefined
            }
            text={offer.name}
            isImageHorizontal
          />
        </div>
        <div className="relative flex items-center gap-4 md:gap-6">
          {!isMobile && (
            <Button
              type="button"
              text={
                offer.published
                  ? t('messages.cancelPublication')
                  : t('messages.publish')
              }
              variant={offer.published ? 'tertiary' : 'quaternary'}
              isLoading={
                updateOffersStatus === reduxStatus.loading &&
                currentlyPublishing === offer.id
              }
              onClick={handlePublishOffer}
              isDisabled={isEmployee}
            />
          )}
          {!isEmployee && (
            <MoreOptionsDropdown
              options={dropownOptions}
              stopTouchPropagation
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Offer;
