import { FC } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { IWarningModalProps } from './WarningModal.types';
import { reduxStatus } from '../../../../constants/constants';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import TertiaryHeader from '../../atoms/TertiaryHeader/TertiaryHeader';
import Button from '../Button/Button';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import ErrorIcon from '../../../../assets/icons/ErrorIcon';

const WarningModal: FC<IWarningModalProps> = ({
  visible,
  type,
  onClose,
  onCancel,
  warningTitle,
  warningDescription,
  confirmAction,
  confirmText,
  cancelText,
  actionStatus,
}) => {
  return createPortal(
    visible ? (
      <div
        className={clsx(
          'absolute left-0 top-0 z-[100] h-screen w-screen items-center justify-center bg-black/75 p-5',
          visible ? 'flex' : 'hidden',
        )}
      >
        <div className="w-full max-w-[500px] rounded-xs border bg-brand-50 p-6">
          <div className="relative flex justify-center pb-4 ">
            <div
              className={clsx(
                'flex h-12 w-12 min-w-[48px] items-center justify-center rounded-full',
                type === 'warning' ? 'bg-[#C2780380]' : 'bg-red-400/50',
              )}
            >
              {type === 'warning' ? <WarningIcon /> : <ErrorIcon />}
            </div>
            {onClose ? (
              <button
                type="button"
                onClick={onClose}
                className="absolute right-0 top-0"
              >
                <CloseIcon />
              </button>
            ) : null}
          </div>
          <div className="flex flex-col items-center gap-4">
            <TertiaryHeader text={warningTitle} />
            <div className="text-center leading-6">{warningDescription}</div>
            <div className="flex flex-wrap justify-center gap-4">
              {cancelText ? (
                <Button
                  type="button"
                  text={cancelText}
                  variant="tertiary"
                  onClick={onCancel || onClose}
                />
              ) : null}
              <Button
                type="button"
                text={confirmText}
                variant="secondary"
                onClick={confirmAction}
                isLoading={actionStatus === reduxStatus.loading}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null,
    document.body,
  );
};

export default WarningModal;
