import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { format } from 'date-fns';

import Dialog from '../../molecules/Dialog/Dialog';
import Input from '../../molecules/Input/Input';
import { IAvailabilityPopupProps } from './AvailabilityPopup.types';
import ColorPicker from '../../molecules/ColorPicker/ColorPicker';
import Button from '../../molecules/Button/Button';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import { IAvailabilityFormValues } from '../../../../types/IAvailabilityFormValues';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  resetAddAvailabilityDialog,
  resetAvailabilityFormValues,
  resetEditAvailability,
  setAvailabilityWithingOpeningHoursError,
  setCurrentColor,
  setCurrentDays,
  setCurrentEnd,
  setCurrentStart,
} from '../../../../redux/features/scheduleSlice';
import {
  createAvailabilityThunk,
  deleteAvailabilityThunk,
  updateAvailabilityThunk,
} from '../../../../redux/thunks/scheduleThunk';
import {
  closeSpecialDayShiftModal,
  resetEditShift,
} from '../../../../redux/features/specialDaySlice';
import {
  createSpecialDayAvailabilityThunk,
  deleteSpecialDayAvailabilityThunk,
  updateSpecialDayAvailabilityThunk,
} from '../../../../redux/thunks/specialDayThunk';
import { reduxStatus } from '../../../../constants/constants';
import HolidaysIcon from '../../../../assets/icons/HolidaysIcon';
import ClockIcon from '../../../../assets/icons/ClockIcon';
import {
  checkIfWithinOpeningHours,
  formatStringHour,
  isValidTime,
  timeStringToDate,
} from '../../../../functions/functions';
import useModal from '../../../../hooks/useModal';
import WarningModal from '../../molecules/WarningModal/WarningModal';

const AvailabilityPopup = ({
  isVisible,
  isForSpecialDay,
}: IAvailabilityPopupProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IAvailabilityFormValues>();

  const currentDays = useAppSelector((state) => state.schedule.currentDays);

  const createAvailabilityStatus = useAppSelector(
    (state) => state.schedule.createAvailabilityStatus,
  );

  const updateAvailabilityStatus = useAppSelector(
    (state) => state.schedule.updateAvailabilityStatus,
  );

  const deleteAvailabilityStatus = useAppSelector(
    (state) => state.schedule.deleteAvailabilityStatus,
  );

  const currentAvailability = useAppSelector(
    (state) => state.schedule.currentAvailability,
  );

  const user = useAppSelector((state) => state.user.user);

  const specialDay = useAppSelector((state) => state.specialDay.specialDay);

  const currentShift = useAppSelector((state) => state.specialDay.currentShift);

  const openingHours = useAppSelector(
    (state) => state.schedule.openingHoursSchedule,
  );

  const updateSpecialDayStatus = useAppSelector(
    (state) => state.specialDay.updateSpecialDayStatus,
  );

  const {
    visible: isSpecialDayAvailabilityErrorModalVisible,
    showModal: showSpecialDayAvailabilityErrorModal,
    hideModal: hideSpecialDayAvailabilityErrorModal,
  } = useModal();

  const {
    visible: isAvailabilityErrorModalVisible,
    showModal: showAvailabilityErrorModal,
    hideModal: hideAvailabilityErrorModal,
  } = useModal();

  const colorWatch = watch('color');

  const startWatch = watch('start');

  const endWatch = watch('end');

  const [isWithinOpeningHours, setIsWithinOpeningHours] =
    useState<boolean>(true);

  const specialDayShiftUpdateCallback = () => {
    dispatch(closeSpecialDayShiftModal());
    dispatch(resetAvailabilityFormValues());
    dispatch(resetEditShift());
    reset();
  };

  const onSubmit: SubmitHandler<IAvailabilityFormValues> = ({
    availabilityTitle,
    start,
    end,
    color,
  }) => {
    if (!user?.placeId) return;

    if (currentShift && isForSpecialDay && specialDay) {
      if (currentDays.length > 0) {
        dispatch(
          updateSpecialDayAvailabilityThunk({
            availabilityId: currentShift?.id,
            specialDayId: specialDay.id,
            name: availabilityTitle,
            start: formatStringHour(start),
            end: formatStringHour(end),
            color,
            callback: specialDayShiftUpdateCallback,
            errorCallback: showSpecialDayAvailabilityErrorModal,
          }),
        );
      } else {
        dispatch(
          deleteSpecialDayAvailabilityThunk({
            availabilityId: currentShift?.id,
            specialDayId: specialDay.id,
            callback: specialDayShiftUpdateCallback,
            errorCallback: showSpecialDayAvailabilityErrorModal,
          }),
        );
      }
    } else if (isForSpecialDay && specialDay && !currentShift) {
      dispatch(
        createSpecialDayAvailabilityThunk({
          specialDayId: specialDay.id,
          name: availabilityTitle,
          start: formatStringHour(start),
          end: formatStringHour(end),
          color,
          callback: specialDayShiftUpdateCallback,
          errorCallback: showSpecialDayAvailabilityErrorModal,
        }),
      );
    } else if (!isForSpecialDay && currentAvailability) {
      if (currentDays.length > 0) {
        dispatch(
          updateAvailabilityThunk({
            id: currentAvailability.id,
            name: availabilityTitle,
            start: formatStringHour(start),
            end: formatStringHour(end),
            color,
            weekdays: currentDays,
            errorCallback: showAvailabilityErrorModal,
          }),
        );
      } else {
        dispatch(
          deleteAvailabilityThunk({
            id: currentAvailability.id,
            errorCallback: showAvailabilityErrorModal,
          }),
        );
      }
    } else if (!isForSpecialDay && !currentAvailability) {
      dispatch(
        createAvailabilityThunk({
          name: availabilityTitle,
          start: formatStringHour(start),
          end: formatStringHour(end),
          color,
          weekdays: currentDays,
          errorCallback: showAvailabilityErrorModal,
        }),
      );
    }
  };

  useEffect(() => {
    if (colorWatch && isVisible) dispatch(setCurrentColor(colorWatch));
  }, [colorWatch, dispatch]);

  useEffect(() => {
    if (startWatch && isVisible && isForSpecialDay) {
      dispatch(setCurrentStart(startWatch.getTime()));
    }

    if (endWatch && isVisible && isForSpecialDay) {
      dispatch(setCurrentEnd(endWatch.getTime()));
    }
  }, [startWatch, endWatch, dispatch]);

  useEffect(() => {
    if (currentAvailability) {
      setValue('availabilityTitle', currentAvailability.name);
      setValue('start', timeStringToDate(currentAvailability.start));
      setValue('end', timeStringToDate(currentAvailability.end));
      setValue('color', currentAvailability.color);
      dispatch(setCurrentDays(currentAvailability.weekdays));
    }
    if (currentShift) {
      setValue('availabilityTitle', currentShift.name);
      setValue('start', timeStringToDate(currentShift.start));
      setValue('end', timeStringToDate(currentShift.end));
      setValue('color', currentShift.color);
      dispatch(setCurrentDays([currentShift.dayOfWeek]));
    }
  }, [currentAvailability, currentShift, setValue, dispatch]);

  useEffect(() => {
    if (createAvailabilityStatus === reduxStatus.success) {
      dispatch(resetAddAvailabilityDialog());
      dispatch(resetAvailabilityFormValues());
      reset();
    }
  }, [createAvailabilityStatus, dispatch, reset]);

  useEffect(() => {
    if (updateAvailabilityStatus === reduxStatus.success) {
      dispatch(resetEditAvailability());
      dispatch(resetAvailabilityFormValues());
      reset();
    }
  }, [updateAvailabilityStatus, dispatch, reset]);

  useEffect(() => {
    if (deleteAvailabilityStatus === reduxStatus.success) {
      dispatch(resetEditAvailability());
      dispatch(resetAvailabilityFormValues());
      reset();
    }
  }, [deleteAvailabilityStatus, dispatch, reset]);

  const handleDialogClose = () => {
    if (!isForSpecialDay) {
      if (currentAvailability) {
        dispatch(resetEditAvailability());
      } else {
        dispatch(resetAddAvailabilityDialog());
      }
    }
    reset();
    dispatch(closeSpecialDayShiftModal());
    dispatch(resetEditShift());
    dispatch(resetAvailabilityFormValues());
  };

  useEffect(() => {
    if (isForSpecialDay || !startWatch || !endWatch || !openingHours) return;

    if (!isValidTime(startWatch) || !isValidTime(endWatch)) {
      return;
    }

    const formattedCurrentStart = format(
      new Date(startWatch.getTime()),
      'HH:mm',
    );
    const formattedCurrentEnd = format(new Date(endWatch.getTime()), 'HH:mm');

    const result = checkIfWithinOpeningHours(
      formattedCurrentStart,
      formattedCurrentEnd,
      openingHours,
    );

    setIsWithinOpeningHours(result);
    dispatch(setAvailabilityWithingOpeningHoursError(result));

    if (!result) return;

    if (startWatch && isVisible) {
      dispatch(setCurrentStart(startWatch.getTime()));
    }

    if (endWatch && isVisible) {
      dispatch(setCurrentEnd(endWatch.getTime()));
    }
  }, [startWatch, endWatch, openingHours, dispatch, isVisible]);

  return (
    <Dialog
      title={
        currentAvailability || currentShift
          ? t('messages.editShift')
          : t('messages.addShift')
      }
      visible={isVisible}
      onClose={handleDialogClose}
      icon={isForSpecialDay ? <HolidaysIcon /> : <ClockIcon />}
    >
      <WarningModal
        type="error"
        visible={isSpecialDayAvailabilityErrorModalVisible}
        onClose={hideSpecialDayAvailabilityErrorModal}
        confirmAction={hideSpecialDayAvailabilityErrorModal}
        warningDescription={t(
          'errorMessages.somethingWentWrongDuringSpecialDayAvailability',
        )}
        warningTitle={t('messages.warning')}
        confirmText={t('messages.close')}
        actionStatus={null}
      />
      <WarningModal
        type="error"
        visible={isAvailabilityErrorModalVisible}
        onClose={hideAvailabilityErrorModal}
        confirmAction={hideAvailabilityErrorModal}
        warningDescription={t(
          'errorMessages.somethingWentWrongDuringAvailability',
        )}
        warningTitle={t('messages.warning')}
        confirmText={t('messages.close')}
        actionStatus={null}
      />
      <form className="mt-4 h-full w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2 md:flex-row md:gap-x-8 xl:flex-col">
          <Controller
            control={control}
            name="availabilityTitle"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t('errorMessages.thisFieldIsRequired'),
              },
            }}
            render={({ field: { value, name, onChange } }) => (
              <div className="w-full">
                <Input
                  variant="secondary"
                  name={name}
                  type="text"
                  labelText={t('messages.name')}
                  placeholder={t('messages.typeAvailabilityTitle')}
                  onChange={onChange}
                  value={value}
                  error={errors.availabilityTitle?.message as string}
                  maxLength={20}
                />
              </div>
            )}
          />
          <div className="min-h-[100px] w-full xl:min-h-[101px]">
            <div className="mb-1 w-full xl:mb-2">
              <InputLabel
                htmlFor="time"
                variant="secondary"
                text={t('messages.hours')}
              />
            </div>
            <div className="flex w-full">
              <Controller
                control={control}
                name="start"
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TimeField
                    className={clsx(
                      errors.start?.message ? 'error-input' : 'time-input',
                    )}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <span className="mx-4 flex items-center justify-center text-brand-1700">
                {t('messages.to')}
              </span>
              <Controller
                control={control}
                name="end"
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TimeField
                    className={clsx(
                      errors.end?.message ? 'error-input' : 'time-input',
                    )}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            {(errors.start?.message || errors.end?.message) && (
              <InputErrorMessage
                error={
                  (errors.start?.message as string) ||
                  (errors.end?.message as string)
                }
              />
            )}
          </div>
        </div>
        <Controller
          control={control}
          name="color"
          defaultValue="purple"
          rules={{
            required: {
              value: true,
              message: t('errorMessages.thisFieldIsRequired'),
            },
          }}
          render={({ field: { value, name, onChange } }) => (
            <ColorPicker
              value={value}
              name={name}
              onChange={onChange}
              label={t('messages.colors')}
            />
          )}
        />
        <div className="mt-4 h-6">
          {!isWithinOpeningHours && (
            <span className="text-sm text-red-600">
              {t('messages.restaurantIsClosedDuringSelectedHours')}
            </span>
          )}
        </div>
        <div className="mt-4 flex w-full">
          <div className="ml-auto">
            <Button
              type="submit"
              text={
                currentAvailability || currentShift
                  ? t('messages.editShift')
                  : t('messages.addShift')
              }
              variant="secondary"
              isLoading={
                createAvailabilityStatus === reduxStatus.loading ||
                updateAvailabilityStatus === reduxStatus.loading ||
                deleteAvailabilityStatus === reduxStatus.loading ||
                updateSpecialDayStatus === reduxStatus.loading
              }
              isDisabled={
                (currentDays.length === 0 &&
                  !currentAvailability &&
                  currentDays.length === 0 &&
                  !currentShift) ||
                !isWithinOpeningHours ||
                endWatch <= startWatch ||
                createAvailabilityStatus === reduxStatus.loading ||
                updateAvailabilityStatus === reduxStatus.loading ||
                deleteAvailabilityStatus === reduxStatus.loading ||
                updateSpecialDayStatus === reduxStatus.loading
              }
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default AvailabilityPopup;
