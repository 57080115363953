import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../molecules/Checkbox/Checkbox';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { DropdownCheckboxesProps } from './DropdownCheckboxes.types';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import { IOption } from '../../organisms/ReservationsPanel/ReservationsPanel.types';
import InputArea from '../InputArea/InputArea';
import InputLabel from '../InputLabel/InputLabel';

const DropdownCheckboxes: React.FC<DropdownCheckboxesProps> = ({
  label,
  options,
  selectedOptions,
  emptyStateText,
  onChange,
  isFullWidth,
  isDisabled,
  isSearchFilter,
  isTranslation,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const stickyElementRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: IOption) => {
    const isOptionSelected = selectedOptions.some(
      (selected) => selected.id === option.id,
    );

    if (isOptionSelected) {
      const updatedOptions = selectedOptions.filter(
        (selected) => selected.id !== option.id,
      );
      onChange(updatedOptions);
    } else {
      const updatedOptions = [...selectedOptions, option];
      onChange(updatedOptions);
    }
  };

  const handleCloseList = () => {
    setIsOpen(false);
  };

  useDetectOutsideClick(wrapperRef, handleCloseList);

  const handleIsOptionChecked = (option: IOption) => {
    const isOptionChecked = selectedOptions.some(
      (selected) => selected.id === option.id,
    );
    return isOptionChecked;
  };

  const placeholderContent =
    selectedOptions.length !== 0
      ? selectedOptions
          .map((option) =>
            isTranslation ? t(`messages.${option.name}`) : option.name,
          )
          .join(', ')
      : emptyStateText;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  useEffect(() => {
    if (isOpen) {
      if (scrollContainerRef.current && wrapperRef.current) {
        const scrollContainerRect =
          scrollContainerRef.current.getBoundingClientRect();

        const wrapperRect = wrapperRef.current?.getBoundingClientRect();

        const spaceBelow = window.innerHeight - wrapperRect.bottom;

        if (scrollContainerRect.height > spaceBelow) {
          setDirection('up');
        } else {
          setDirection('down');
        }
      }
    }
  }, [direction, isOpen]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const checkScroll = () => {
      if (scrollContainer && scrollContainer.scrollTop > 10) {
        if (stickyElementRef.current) {
          stickyElementRef.current.style.paddingBottom = '12px';
        }
      } else if (stickyElementRef.current) {
        stickyElementRef.current.style.paddingBottom = '0px';
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', checkScroll);
      }
    };
  }, [isOpen]);

  return (
    <div className="flex flex-col">
      {label ? (
        <InputLabel htmlFor="" text={label} variant="secondary" />
      ) : null}
      <div
        ref={wrapperRef}
        className={clsx(
          'relative flex items-center justify-center rounded-xs border-[1px] border-solid xl:max-h-[45px]',
          isFullWidth ? 'w-full' : 'lg:min-w-[252px] lg:max-w-[252px]',
          isOpen ? 'border-white' : 'border-brand-700',
        )}
      >
        <button
          type="button"
          onClick={toggleDropdown}
          disabled={isDisabled}
          className={clsx(
            'flex w-full justify-between gap-2.5 py-2 pl-4 pr-3',
            selectedOptions.length === 0 && 'text-brand-1700',
          )}
        >
          <span className="truncate">{placeholderContent}</span>
          <span
            className={clsx(
              'duration-100 ease-in-out',
              isOpen ? 'rotate-0' : 'rotate-180',
            )}
          >
            <ArrowIcon isDisabled={isDisabled} />
          </span>
        </button>
        <div
          ref={scrollContainerRef}
          className={clsx(
            'custom-scrollbar scrolling absolute z-10 flex w-full flex-col overflow-y-auto rounded-xs border bg-brand-50',
            isOpen ? 'visible' : 'invisible',
            isSearchFilter
              ? 'max-h-[268px] gap-3 px-2.5 pb-2.5'
              : 'max-h-[230px] gap-4 p-5',
            direction === 'down'
              ? 'top-[calc(100%+10px)]'
              : 'bottom-[calc(100%+10px)]',
          )}
        >
          {isSearchFilter && (
            <div
              ref={stickyElementRef}
              className="z-11 sticky top-0 -mt-0.5 bg-brand-50 pl-2 pt-2.5"
            >
              <InputArea
                name="search"
                type="text"
                placeholder={t('messages.searchSubcategoriesPlaceholder')}
                value={searchValue}
                variant="quaternary"
                onChange={handleSearchInputChange}
                isError={false}
                isDisabled={false}
                maxLength={100}
              />
            </div>
          )}
          {filteredOptions.map((option) => (
            <div
              key={option.id}
              className={clsx(
                'flex items-center gap-2',
                handleIsOptionChecked(option) && 'bg-brand-300',
                isSearchFilter && 'p-2',
              )}
            >
              <Checkbox
                name={`option-${option.id}`}
                labelText={
                  isTranslation ? t(`messages.${option.name}`) : option.name
                }
                labelTextColor="text-white"
                isChecked={handleIsOptionChecked(option)}
                onChange={() => handleOptionClick(option)}
                isLargeIcon={isSearchFilter}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownCheckboxes;
