import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import backgroundGradient from '../../../assets/images/background-gradient.png';
import Header from '../../UI/molecules/Header/Header';
import LoginContentLogo from '../../UI/atoms/LoginContentLogo/LoginContentLogo';

const PublicTemplate = () => {
  const { t } = useTranslation();
  return (
    <div className="relative h-screen w-screen">
      <Header isHamburgerDisabled />
      <img
        src={backgroundGradient}
        alt="background gradient"
        className="absolute left-0 top-0 -z-50 h-full w-full object-cover object-right-bottom"
      />
      <div className="px-5 py-4 md:p-8 xl:flex xl:h-[calc(100%-64px)] xl:items-center xl:justify-center xl:gap-44 xl:p-10">
        <Outlet />
        <div className="hidden xl:relative xl:flex xl:max-w-[650px] xl:flex-col">
          <LoginContentLogo />
          <h2 className="pb-6 text-left text-3xl font-bold leading-9">
            {t('messages.welcomeSlogan')}
          </h2>
          <p className="text-left text-lg">
            {t('messages.loginPageIntroduction')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublicTemplate;
