import InputLabel from '../../atoms/InputLabel/InputLabel';
import ColorBox from '../../atoms/ColorBox/ColorBox';
import { pickerColors } from '../../../../constants/constants';
import { IColorPickerProps } from './ColorPicker.types';

const ColorPicker = ({ value, name, onChange, label }: IColorPickerProps) => {
  return (
    <div className="w-full">
      <InputLabel text={label} htmlFor={name} variant="secondary" />
      <div className="mt-2 flex w-full flex-row gap-x-3">
        {pickerColors.map((item) => (
          <ColorBox
            color={item}
            key={item}
            isChecked={value === item}
            onClick={() => onChange(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
