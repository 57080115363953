import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import { IInputRadioProps } from './InputRadio.types';

const InputRadio = ({
  name,
  labelText,
  options,
  value,
  onChange,
  error,
  isHorizontal,
  inputDescription,
}: IInputRadioProps) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <InputLabel text={labelText} variant="secondary" htmlFor={name} />
      {inputDescription && <p className="mt-2 text-xs">{inputDescription}</p>}
      <div
        className={clsx(
          'mt-2 flex h-max w-full flex-col justify-between gap-2 md:flex-row',
          isHorizontal && 'flex-col md:flex-col',
        )}
      >
        {options.map((option) => (
          <label
            htmlFor={`${name}-${option.value}`}
            className={clsx('w-[50%] cursor-pointer', isHorizontal && 'w-full')}
            key={option.value}
          >
            <input
              className="styled-radio"
              id={`${name}-${option.value}`}
              type="radio"
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
            />
            <div className="radio-name">
              <div className="flex flex-col gap-1">
                {t(`messages.${option.label}`)}
                {option.description && (
                  <p className="text-xs text-brand-1700">
                    {option.description}
                  </p>
                )}
              </div>
            </div>
          </label>
        ))}
      </div>
      {!!error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default InputRadio;
