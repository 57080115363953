import { SVGProps } from 'react';

const HomeIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.9 21.15V8.975l8.1-6.07 8.1 6.07V21.15h-5.946v-7.342H9.846v7.342H3.9z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default HomeIcon;
