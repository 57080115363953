import clsx from 'clsx';

import { IPickerListProps } from './PickerList.types';

const PickerList = ({
  options,
  handleSelectOption,
  label,
  value,
}: IPickerListProps) => {
  return (
    <div className="absolute top-20 z-10 flex w-[296px] flex-row flex-wrap gap-3 rounded-sm border border-brand-700 bg-brand-50 p-5">
      <span className="mb-1 w-full text-lg font-semibold">{label}</span>
      {options &&
        options.map((option: string) => (
          <span
            key={option}
            className={clsx(
              'font-semiBold flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-brand-700 px-4 py-[14px] text-base transition-all',
              option === value ? 'border-white' : 'border-brand-700',
            )}
            onClick={() => handleSelectOption(option)}
          >
            {option}
          </span>
        ))}
    </div>
  );
};

export default PickerList;
