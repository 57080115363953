import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { store } from '../redux/store';
import { AuthContextProvider } from '../context/AuthContext';
import router from '../routes/router';

const Providers = () => {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={plLocale}
        >
          <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
            <ToastContainer theme="dark" />
            <RouterProvider router={router} />
          </IntercomProvider>
          {/* <RouterProvider /> */}
        </LocalizationProvider>
      </AuthContextProvider>
    </Provider>
  );
};

export default Providers;
