import { SVGProps } from 'react';

interface ArrowRightProps {
  isBlocked?: boolean;
}

const ArrowRightIcon = ({ isBlocked }: ArrowRightProps) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.61655 18.5454L5.07971 17.0086L12.1086 9.97975L5.07971 2.9509L6.61655 1.41406L15.1822 9.97975L6.61655 18.5454Z"
        fill={isBlocked ? '#B3ADC1' : 'white'}
      />
    </svg>
  );
};

ArrowRightIcon.defaultProps = {
  isBlocked: false,
};

export default ArrowRightIcon;
