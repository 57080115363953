import LoaderIcon from '../../../../assets/icons/LoaderIcon';
import { ISpinnerProps } from './Spinner.types';

const Spinner = ({ isButtonWhite }: ISpinnerProps) => {
  return (
    <div className="flex animate-spin items-center justify-center">
      <LoaderIcon isButtonWhite={isButtonWhite} />
    </div>
  );
};

export default Spinner;
