import clsx from 'clsx';

import { IInputProps } from './Input.types';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputArea from '../../atoms/InputArea/InputArea';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import ErrorMessageWithIcon from '../../atoms/ErrorMessageWithIcon/ErrorMessageWithIcon';

const Input = ({
  type,
  variant,
  placeholder,
  name,
  labelText,
  value,
  onChange,
  error,
  isLoading,
  maxLength,
  isDisabled = false,
  isFixedSize = true,
  prefixLabel,
  tooltipText,
}: IInputProps) => {
  return (
    <div
      className={clsx(
        'flex flex-1 flex-col',
        variant === 'primary' && isFixedSize && 'h-[120px] min-h-[120px]',
        variant === 'secondary' &&
          isFixedSize &&
          type !== 'time' &&
          'h-[100px] min-h-[100px]',
        variant === 'tertiary' && isFixedSize && 'h-[100px] min-h-[100px]',
      )}
    >
      <InputLabel
        text={labelText}
        htmlFor={name}
        variant={variant}
        isDisabled={isDisabled}
      />
      <InputArea
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        variant={variant}
        onChange={onChange}
        isError={!!error}
        isDisabled={isDisabled}
        maxLength={maxLength}
        isLoading={isLoading}
        prefixLabel={prefixLabel}
      />
      {!!error &&
        (tooltipText ? (
          <ErrorMessageWithIcon text={error} tooltipText={tooltipText} />
        ) : (
          <InputErrorMessage error={error} />
        ))}
    </div>
  );
};

export default Input;
