import { get, isEmpty } from 'lodash';
import clsx from 'clsx';

import { ITableMobileProps } from './TableMobile.types';

const TableMobile = ({ columns, data = [], variant }: ITableMobileProps) => {
  return (
    !isEmpty(data) && (
      <div className="relative flex w-full flex-col gap-4">
        {data.map((row: any) => {
          return (
            <div
              key={row.id}
              className="flex flex-col gap-1 border-b-[1px] border-solid border-brand-700 pb-4"
            >
              {columns.map((col, i) => {
                return (
                  <div
                    className="flex items-center gap-1"
                    key={`${row.id}-${col.accessor}`}
                  >
                    {i > 0 && (
                      <span
                        className={clsx(
                          'text-xs font-semibold uppercase text-brand-1700',
                          variant === 'event' && 'min-w-[120px]',
                          variant === 'coupon' && 'min-w-[110px]',
                          variant === 'specialDay' && 'min-w-[80px]',
                        )}
                      >
                        {col.Header}
                      </span>
                    )}
                    <span className="block w-full truncate">
                      {get(row, col.accessor)}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    )
  );
};

export default TableMobile;
