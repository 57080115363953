import { createContext, useState, useEffect, RefObject } from 'react';

export default function useScrollListener(ref: RefObject<HTMLElement>) {
  const [data, setData] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentRef = ref.current;
      if (currentRef) {
        setData((last) => ({
          x: currentRef.scrollLeft,
          y: currentRef.scrollTop,
          lastX: last.x,
          lastY: last.y,
        }));
      }
    };

    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return data;
}

export const ScrollContext = createContext(null);
