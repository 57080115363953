import clsx from 'clsx';

import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import TextAreaInput from '../../atoms/TextAreaInput/TextAreaInput';
import { ITextAreaProps } from './TextArea.types';

const TextArea = ({
  variant,
  placeholder,
  name,
  labelText,
  value,
  onChange,
  error,
  isDisabled = false,
  maxLength,
  height,
}: ITextAreaProps) => {
  return (
    <div
      className={clsx(
        'flex flex-1 flex-col',
        variant === 'primary' && 'h-[120px] min-h-[120px]',
        variant === 'secondary' && 'h-[100px] min-h-[100px]',
        variant === 'flexible' && 'min-h-auto h-auto',
      )}
    >
      <InputLabel
        text={labelText}
        htmlFor={name}
        variant={variant}
        isDisabled={isDisabled}
      />
      <TextAreaInput
        name={name}
        placeholder={placeholder}
        value={value}
        variant={variant}
        onChange={onChange}
        isError={!!error}
        isDisabled={isDisabled}
        maxLength={maxLength}
        height={height}
      />
      {!!error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default TextArea;
