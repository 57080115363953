import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import {
  IAddSpecialDayVariables,
  ICreateSpecialDayAvailabilityVariables,
  IDeleteSpecialDayAvailabilityVariables,
  IDeleteSpecialDayVariables,
  IGetSpecialDaysVariables,
  IImportSpecialDayAvailabilitiesVariables,
  IListSpecialDaysAvailabilitiesVariables,
  ISpecialDay,
  IUpdateSpecialDayAvailabilityVariables,
  IUpdateSpecialDayVariables,
} from '../../types/SpecialDay';
import { apiErrorMessages, REST_API_URLS } from '../../constants/constants';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import {
  createSpecialDayResponseValidateSchema,
  listSpecialDaysCountResponseValidateSchema,
  listSpecialDaysAvailabilitiesResponseValidateSchema,
  createSpecialDayAvailabilityResponseValidateSchema,
  updateSpecialDayAvailabilityResponseValidateSchema,
  importSpecialDayAvailabilitiesResponseValidateSchema,
} from '../../schema/SpecialDaysSchemas';
import { handleForbiddenError } from '../../functions/functions';

export const listSpecialDaysAvailabilitiesThunk = createAsyncThunk(
  'schedule/listSpecialDaysAvailabilities',
  async (
    { specialDayId }: IListSpecialDaysAvailabilitiesVariables,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        specialDayId,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.specialDayListSpecialDaysAvailabilities,
        method: 'GET',
        params,
      });

      listSpecialDaysAvailabilitiesResponseValidateSchema.parse(response.data);

      const transformedData = {
        ...response.data,
        shifts: response.data.availabilities,
        availabilities: undefined,
      };

      return transformedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const listSpecialDaysThunk = createAsyncThunk(
  'schedule/listSpecialDays',
  async (
    {
      placeId,
      offset,
      limit,
      sortBy,
      order,
      nextId,
      previousId,
    }: IGetSpecialDaysVariables,
    { rejectWithValue },
  ) => {
    try {
      const optionalParams = {
        ...(nextId !== undefined && { nextId }),
        ...(previousId !== undefined && { previousId }),
        ...(sortBy !== null && { sortBy }),
        ...(order !== null && { order }),
      };

      const params = {
        placeId,
        limit,
        offset,
        ...optionalParams,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.listSpecialDays,
        method: 'GET',
        params,
      });

      return {
        specialDays: response.data,
        listSpecialDaysNextPageToken: response.nextId,
        listSpecialDaysPreviousPageToken: response.previousId,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const listSpecialDaysCountThunk = createAsyncThunk(
  'schedule/listSpecialDaysCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listSpecialDaysCount,
        method: 'GET',
      });

      listSpecialDaysCountResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSpecialDayThunk = createAsyncThunk(
  'schedule/createSpecialDay',
  async (
    {
      name,
      date,
      isOpen,
      open = '8:00',
      close = '16:00',
      callback,
      errorCallback,
    }: IAddSpecialDayVariables,
    { rejectWithValue },
  ) => {
    try {
      const createSpecialDayInput = {
        name,
        date,
        isOpen,
        open,
        close,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createSpecialDay,
        method: 'POST',
        data: createSpecialDayInput,
      });

      createSpecialDayResponseValidateSchema.parse(response.data);

      const newSpecialDay: ISpecialDay = {
        ...response.data,
        date: response.data.date,
        shifts: [],
      };

      if (callback) callback(response.data.id);

      return newSpecialDay;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.SPECIAL_DAY_EXISTS) {
            toast.error(t('errorMessages.specialDayAlreadyExists'));
          } else if (
            errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION
          ) {
            if (errorCallback) {
              errorCallback.showSpecialDayCreationExistingReservationsError();
            }
          } else if (
            errorData.errorCode === apiErrorMessages.OPENING_HOURS_NOT_MATCH
          ) {
            if (errorCallback) {
              errorCallback.showOpeningHoursConflictError();
            }
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);

export const updateSpecialDayThunk = createAsyncThunk(
  'schedule/updateSpecialDay',
  async (
    { id, updates, callback, errorCallback }: IUpdateSpecialDayVariables,
    { rejectWithValue },
  ) => {
    try {
      const updateSpecialDayInput = {
        id,
        ...updates,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateSpecialDay,
        method: 'PATCH',
        data: updateSpecialDayInput,
      });

      if (response.data) {
        if (callback) callback();

        return { id, ...updates };
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.SPECIAL_DAY_EXISTS) {
            toast.error(t('errorMessages.specialDayAlreadyExists'));
          } else if (
            errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION
          ) {
            if (errorCallback) {
              errorCallback.showSpecialDayCreationExistingReservationsError();
            }
          } else if (
            errorData.errorCode === apiErrorMessages.OPENING_HOURS_NOT_MATCH
          ) {
            if (errorCallback) {
              errorCallback.showOpeningHoursConflictError();
            }
          } else if (
            errorData.errorCode ===
            apiErrorMessages.AVAILABILITY_COLLISION_WITH_SPECIAL_DAY_OPENING_HOURS
          ) {
            if (errorCallback) {
              errorCallback.showSpecialDayAvailabilityConflictError();
            }
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);

export const deleteSpecialDayThunk = createAsyncThunk(
  'schedule/deleteSpecialDay',
  async (
    { id, errorCallback }: IDeleteSpecialDayVariables,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.deleteSpecialDay,
        method: 'DELETE',
        data: { id },
      });

      if (response.data) {
        return id;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));
      return rejectWithValue(error);
    }
  },
);

export const importSpecialDayAvailabilitiesThunk = createAsyncThunk(
  'schedule/importSpecialDayAvailabilities',
  async (
    {
      specialDayId,
      weekDay,
      errorCallback,
    }: IImportSpecialDayAvailabilitiesVariables,
    { rejectWithValue },
  ) => {
    try {
      const importSpecialDayAvailabilitiesInput = {
        specialDayId,
        weekday: new Date(weekDay).getDay(),
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.importSpecialDayAvailabilities,
        method: 'POST',
        data: importSpecialDayAvailabilitiesInput,
      });

      importSpecialDayAvailabilitiesResponseValidateSchema.parse(response.data);

      return { specialDayId, importedSpecialDayAvailabilities: response.data };
    } catch (error: unknown) {
      handleForbiddenError(error);

      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) {
              errorCallback.showImportSpecialDayScheduleErrorModal();
            }
          } else if (
            errorData.errorCode === apiErrorMessages.OPENING_HOURS_NOT_MATCH
          ) {
            if (errorCallback) {
              errorCallback.showImportSpecialDayScheduleOpeningHoursErrorModal();
            }
          } else {
            toast.error(t('errorMessages.somethingWentWrong'));
          }
        } else {
          toast.error(t('errorMessages.somethingWentWrong'));
        }
      } else {
        toast.error(t('errorMessages.somethingWentWrong'));
      }
      return rejectWithValue(error);
    }
  },
);

export const deleteSpecialDayAvailabilityThunk = createAsyncThunk(
  'schedule/deleteSpecialDayAvailability',
  async (
    {
      availabilityId,
      specialDayId,
      callback,
      errorCallback,
    }: IDeleteSpecialDayAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.deleteSpecialDayAvailability,
        method: 'DELETE',
        data: { availabilityId },
      });

      if (response.data) {
        if (callback) callback();
        return { specialDayId, availabilityId };
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);

export const createSpecialDayAvailabilityThunk = createAsyncThunk(
  'schedule/createSpecialDayAvailability',
  async (
    {
      specialDayId,
      name,
      start,
      end,
      color,
      callback,
      errorCallback,
    }: ICreateSpecialDayAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const createSpecialDayAvailabilityInput = {
        specialDayId,
        name,
        start,
        end,
        color,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createSpecialDayAvailability,
        method: 'POST',
        data: createSpecialDayAvailabilityInput,
      });

      createSpecialDayAvailabilityResponseValidateSchema.parse(response.data);

      if (callback) callback();

      return { specialDayId, createdSpecialDayAvailability: response.data };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);

export const updateSpecialDayAvailabilityThunk = createAsyncThunk(
  'schedule/updateSpecialDayAvailability',
  async (
    {
      specialDayId,
      availabilityId,
      name,
      color,
      start,
      end,
      callback,
      errorCallback,
    }: IUpdateSpecialDayAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const updateSpecialDayInput = {
        availabilityId,
        name,
        color,
        start,
        end,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.specialDayUpdateSpecialDayAvailability,
        method: 'PATCH',
        data: updateSpecialDayInput,
      });

      updateSpecialDayAvailabilityResponseValidateSchema.parse(response.data);

      if (callback) callback();

      return {
        specialDayId,
        availabilityId,
        updatedSpecialDayAvailability: response.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);
