import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../Button/Button';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import PhoneIcon from '../../../../assets/icons/PhoneIcon';
import Modal from '../Modal/Modal';
import { SUPPORT_PHONE_NUMBER } from '../../../../constants/constants';

const SupportPhoneModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(SUPPORT_PHONE_NUMBER);
      toast.success(t('messages.supportModule.copySuccess'));
    } catch (error) {
      toast.error(t('messages.supportModule.copyError'));
    }
  };

  const handleCallNumber = () => {
    window.location.href = `tel:${SUPPORT_PHONE_NUMBER}`;
  };

  return (
    <Modal
      title={t('messages.supportModule.contactUs')}
      visible={visible}
      onClose={onClose}
    >
      <div className="flex items-center justify-center gap-2 pb-6 pt-8">
        <PhoneIcon />
        <span className="text-xl font-bold">{SUPPORT_PHONE_NUMBER}</span>
      </div>
      <div className="flex flex-wrap justify-center gap-4">
        <Button
          type="button"
          text={t('messages.supportModule.copyNumber')}
          variant="tertiary"
          onClick={handleCopyNumber}
          isLoading={false}
        />
        <ButtonWithIcon
          type="button"
          text={t('messages.supportModule.call')}
          variant="quaternary"
          onClick={handleCallNumber}
          icon={PhoneIcon}
          isLoading={false}
        />
      </div>
    </Modal>
  );
};

export default SupportPhoneModal;
