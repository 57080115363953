const CloseMobileSideMenuIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.81387 6.24905L6.30034 4.71316L12.0164 10.4541L12.0253 10.4631L12.0341 10.4541L17.7001 4.7132L19.1866 6.24905L13.5201 11.9904L13.5114 11.9992L13.5202 12.008L19.1865 17.6993L17.7001 19.2351L12.0341 13.4942L12.0253 13.4852L12.0164 13.4941L6.30034 19.2351L4.8139 17.6993L10.4802 12.008L10.489 11.9992L10.4803 11.9904L4.81387 6.24905Z"
        fill="#B3ADC1"
        stroke="#B3ADC1"
        strokeWidth="0.025"
      />
    </svg>
  );
};

export default CloseMobileSideMenuIcon;
