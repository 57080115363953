import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IConfirmModalProps } from './ConfirmModal.types';
import { reduxStatus } from '../../../../constants/constants';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import TertiaryHeader from '../../atoms/TertiaryHeader/TertiaryHeader';
import Button from '../Button/Button';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import ConfirmCheckIcon from '../../../../assets/icons/ConfirmCheckIcon';

const ConfirmModal: FC<IConfirmModalProps> = ({
  visible,
  onClose,
  questionText,
  confirmText,
  confirmAction,
  loadingStatus,
  isCancelReservationModal,
}) => {
  const { t } = useTranslation();

  return createPortal(
    visible ? (
      <div
        className={clsx(
          'absolute left-0 top-0 z-[100] h-screen w-screen items-center justify-center bg-black/75 p-5',
          visible ? 'flex' : 'hidden',
        )}
      >
        <div className="w-full max-w-[500px] rounded-xs border bg-brand-50 p-6">
          <div className="relative flex justify-center pb-4 ">
            {loadingStatus === reduxStatus.success ? (
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-900/50">
                <ConfirmCheckIcon />
              </div>
            ) : (
              <TrashIcon className="fill-brand-1700" />
            )}
            <button
              type="button"
              onClick={onClose}
              className="absolute right-0 top-0"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col items-center gap-4">
            <TertiaryHeader
              text={
                loadingStatus === reduxStatus.success
                  ? confirmText
                  : questionText
              }
            />
            <div className="flex flex-wrap justify-center gap-4">
              {loadingStatus === reduxStatus.success ? (
                <Button
                  type="button"
                  text={t('messages.continue')}
                  variant="secondary"
                  onClick={onClose}
                />
              ) : (
                <>
                  <Button
                    type="button"
                    text={
                      !isCancelReservationModal
                        ? t('messages.cancel')
                        : t('messages.back')
                    }
                    variant="tertiary"
                    onClick={onClose}
                  />
                  <Button
                    type="button"
                    text={
                      !isCancelReservationModal
                        ? t('messages.delete')
                        : t('messages.cancel')
                    }
                    variant="tertiary"
                    onClick={confirmAction}
                    isLoading={loadingStatus === reduxStatus.loading}
                    isDeleteButton
                    isDisabled={loadingStatus === reduxStatus.loading}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null,
    document.body,
  );
};

export default ConfirmModal;
