import { createSlice } from '@reduxjs/toolkit';

import {
  IStatisticsItem,
  IStatisticsSliceState,
  TReservationStatistics,
  TStatistics,
} from '../../types/Statistics';
import { statisticsListThunk } from '../thunks/statisticsThunk';

const initialState: IStatisticsSliceState = {
  listProfileViews: [],
  listCoupons: [],
  listReservations: [],
  listMessages: [],
  totalViews: 0,
  totalCoupons: 0,
  totalReservations: 0,
  totalMessages: 0,
  statisticsListStatus: null,
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    clearStatistics: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(statisticsListThunk.pending, (state) => {
      state.statisticsListStatus = 'loading';
    });
    builder.addCase(statisticsListThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      const newProfileViews: TStatistics[] = [];
      const newCoupons: TStatistics[] = [];
      const newReservations: TReservationStatistics[] = [];
      const newMessages: TStatistics[] = [];

      let totalViews: number = 0;
      let totalCoupons: number = 0;
      let totalReservations: number = 0;
      let totalMessages: number = 0;

      payload.forEach((item: IStatisticsItem) => {
        const {
          date,
          countReservation,
          countUsedCoupon,
          countViewProfile,
          countSMS,
        } = item;

        newProfileViews.push({ date, count: countViewProfile });
        newCoupons.push({ date, count: countUsedCoupon });
        newMessages.push({ date, count: countSMS });
        newReservations.push({
          date,
          countFunzy: countReservation,
          countGoogle: 0,
        });

        totalViews += countViewProfile;
        totalCoupons += countUsedCoupon;
        totalMessages += countSMS;
        totalReservations += countReservation;
      });

      state.listProfileViews = newProfileViews;
      state.listCoupons = newCoupons;
      state.listReservations = newReservations;
      state.listMessages = newMessages;
      state.totalViews = totalViews;
      state.totalCoupons = totalCoupons;
      state.totalReservations = totalReservations;
      state.totalMessages = totalMessages;
      state.statisticsListStatus = 'success';
    });
    builder.addCase(statisticsListThunk.rejected, (state) => {
      state.statisticsListStatus = 'failed';
    });
  },
});

export const { clearStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;
