import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getDaysToDisplayInSchedule } from '../../../../functions/functions';

const CalendarHeader = ({ daysToShow }: { daysToShow: number | number[] }) => {
  const { t } = useTranslation();

  const daysToDisplay = getDaysToDisplayInSchedule(daysToShow);

  return (
    <div className="sticky top-0 z-[22] flex min-w-[1200px] [&>*:last-child]:border-r-0">
      <div className="sticky left-0 top-0 z-[22] w-[78px] border-b border-r  bg-brand-50" />
      {daysToDisplay.map((day) => {
        return (
          <div
            className="flex h-[50px] flex-1 items-center justify-center gap-x-2 border-b border-r  bg-brand-50 text-sm font-medium"
            key={day.name}
          >
            {t(`messages.${day.label}`)}
          </div>
        );
      })}
    </div>
  );
};

export default CalendarHeader;
