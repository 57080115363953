import clsx from 'clsx';

import CheckIcon from '../../../../assets/icons/CheckIcon';
import { IColorBoxProps } from './ColorBox.types';

const ColorBox = ({ color, isChecked, onClick }: IColorBoxProps) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex h-6 w-6 cursor-pointer items-center justify-center rounded fill-white',
        color === 'purple' && 'bg-purple-500',
        color === 'indigo' && 'bg-indigo-500',
        color === 'blue' && 'bg-blue-600',
        color === 'pink' && 'bg-pink-500',
        color === 'teal' && 'bg-teal-500',
        color === 'green' && 'bg-green-500',
        color === 'yellow' && 'bg-yellow-300',
        color === 'orange' && 'bg-orange-500',
        color === 'red' && 'bg-red-600',
      )}
    >
      {isChecked && <CheckIcon className="stroke-white" />}
    </div>
  );
};

export default ColorBox;
