import { SVGProps } from 'react';

const EventIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.62305 22.473L6.85763 7.80385L16.2922 17.2384L1.62305 22.473ZM14.7076 12.6038L13.5691 11.5153L21.3979 3.68652L23.9268 6.41536L22.8076 7.5038L21.3076 6.0038L14.7076 12.6038ZM10.5537 8.44998L9.4153 7.31155L10.8961 5.93075L9.38455 4.4192L10.523 3.33074L13.098 5.90575L10.5537 8.44998ZM12.6307 10.5269L11.4922 9.43844L16.0922 4.83845L13.5422 2.33845L14.6807 1.2L18.269 4.88845L12.6307 10.5269ZM16.7845 14.6807L15.6461 13.5923L19.346 9.8923L23.046 13.5923L21.9076 14.6807L19.371 12.1442L16.7845 14.6807Z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default EventIcon;
