import clsx from 'clsx';

import { ICheckboxAreaProps } from './CheckboxArea.types';
import CheckIcon from '../../../../assets/icons/CheckIcon';
import CheckLargeIcon from '../../../../assets/icons/CheckLargeIcon';

const CheckboxArea = ({ isChecked, isLargeIcon }: ICheckboxAreaProps) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        isLargeIcon
          ? 'h-5 w-5'
          : 'min-h-4 min-w-4 rounded border border-brand-1200',
        isChecked && !isLargeIcon && 'border border-white bg-white',
        !isChecked && isLargeIcon && 'border border-white',
      )}
    >
      <div className={isChecked ? '' : 'invisible'}>
        {isLargeIcon ? <CheckLargeIcon /> : <CheckIcon />}
      </div>
    </div>
  );
};

export default CheckboxArea;
