import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks/useRedux';
import { routes } from '../constants/constants';

const GuestRoute = () => {
  const user = useAppSelector((state) => state.user.user);

  return user && user.firebaseId && user.placeId ? (
    <Navigate to={routes.home} />
  ) : (
    <Outlet />
  );
};

export default GuestRoute;
