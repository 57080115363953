import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import PenIcon from '../../../../assets/icons/PenIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import UploadIcon from '../../../../assets/icons/UploadIcon';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import { IFile, IFileUploadProps } from '../../../../types/Support';

const FileUpload = ({
  name,
  labelText,
  placeholder = '',
  maxFileSize,
  value = [],
  onChange,
}: IFileUploadProps) => {
  const [fileNames, setFileNames] = useState<string[]>([]);

  const { watch } = useFormContext();
  const { t } = useTranslation();

  const files = watch('files');

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (errorr) => reject(errorr);
    });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newFileNames: string[] = [];
      const filePromises: Promise<IFile>[] = acceptedFiles.map(async (file) => {
        if (file.size / 1024 / 1024 > maxFileSize) {
          toast.error(t('errorMessages.fileTooLarge', { maxFileSize }));
          return Promise.reject();
        }

        const base64String = await toBase64(file);
        const fileExtension = `.${file.name.split('.').pop()}`;
        newFileNames.push(file.name);

        return { base64String, fileExtension };
      });

      try {
        const newFiles = await Promise.all(filePromises);
        onChange(newFiles.filter(Boolean));
        setFileNames(newFileNames);
      } catch (error) {
        console.error('Błąd w konwersji plików:', error);
      }
    },
    [maxFileSize, onChange, value],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'text/plain': ['.txt'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    multiple: true,
    noClick: true,
  });

  const handleRemoveFiles = () => {
    onChange([]);
  };

  useEffect(() => {
    if (fileNames.length !== 0 && (!files || files?.length === 0)) {
      setFileNames([]);
    }
  }, [fileNames.length, files]);

  return (
    <div className="flex flex-col">
      <InputLabel text={labelText} htmlFor={name} variant="secondary" />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps()} className="mb-6">
        <div className="flex items-center gap-4">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} type="file" />

          <input
            type="text"
            className={clsx(
              'h-[42px] w-full rounded-xs border border-brand-700 bg-transparent px-4 placeholder-brand-1700 outline-none',
            )}
            placeholder={placeholder}
            value={fileNames.join(', ')}
            readOnly
          />

          {fileNames.length === 0 && (
            <ButtonWithIcon
              type="button"
              text="Wybierz pliki"
              variant="secondary"
              icon={UploadIcon}
              onClick={open}
              isJustIconOnMobile
            />
          )}

          {fileNames.length > 0 && (
            <>
              <ButtonWithIcon
                type="button"
                text="Usuń"
                variant="secondary"
                icon={TrashIcon}
                onClick={handleRemoveFiles}
                isDeleteButton
                isJustIconOnMobile
              />
              <ButtonWithIcon
                type="button"
                text="Zmień"
                variant="primary"
                icon={PenIcon}
                onClick={open}
                isJustIconOnMobile
              />
            </>
          )}
        </div>
        <div>
          <p className="mt-2 text-xs text-brand-1700">
            {t('messages.fileExtensionsAndSize', { maxFileSize })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
