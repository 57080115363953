import { useLocation } from 'react-router-dom';

import { reservationsTabs, routes } from '../../../../constants/constants';
import Tab from '../../atoms/Tab/Tab';
import { IReservationsTabs } from './ReservationsTabs.types';

const ReservationsTabs = ({
  isChatAvailable,
  isAnyUnseenMessage,
  handleTabChange,
}: IReservationsTabs) => {
  const { pathname } = useLocation();

  return (
    <div className="relative flex w-full overflow-auto">
      {reservationsTabs.map((tab) => {
        if (
          !isChatAvailable &&
          (pathname === routes.reservations ||
            pathname === routes.reservationsNeedAction)
        ) {
          if (tab.route === routes.reservationsUnseen) {
            return null;
          }
        }
        return (
          <Tab
            key={tab.route}
            label={tab.label}
            route={tab.route}
            icon={tab.icon}
            isActive={pathname === tab.route}
            isAnyUnseenMessage={isAnyUnseenMessage}
            onClick={handleTabChange}
          />
        );
      })}
    </div>
  );
};

export default ReservationsTabs;
