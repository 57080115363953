import { configureStore } from '@reduxjs/toolkit';

import userReducer from './features/userSlice';
import offerReducer from './features/offerSlice';
import spacePlanReducer from './features/spacePlanSlice';
import couponReducer from './features/couponSlice';
import scheduleReducer from './features/scheduleSlice';
import chatReducer from './features/chatSlice';
import specialDayReducer from './features/specialDaySlice';
import reservationReducer from './features/reservationSlice';
import statisticsReducer from './features/statisticsSlice';
import settingsReducer from './features/settingsSlice';
import firstStepsReducer from './features/firstStepsSlice';
import locationReducer from './features/locationSlice';
import eventReducer from './features/eventSlice';
import supportReducer from './features/supportSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    offer: offerReducer,
    spacePlan: spacePlanReducer,
    coupon: couponReducer,
    schedule: scheduleReducer,
    chat: chatReducer,
    specialDay: specialDayReducer,
    reservation: reservationReducer,
    statistics: statisticsReducer,
    settings: settingsReducer,
    firstSteps: firstStepsReducer,
    location: locationReducer,
    event: eventReducer,
    support: supportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
