import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

import i18n from '../../configuration/i18n';
import {
  TSpacePlan,
  ISpacePlanSliceState,
  TTable,
} from '../../types/SpacePlan';
import {
  createSpacePlanThunk,
  deleteSpacePlanThunk,
  listSpacePlansThunk,
  updateSpacePlanThunk,
  updateTableSpecsThunk,
  listTablesThunk,
  createDefaultSpacePlanThunk,
  addTablesThunk,
  deleteTableThunk,
} from '../thunks/spacePlanThunk';

const initialState: ISpacePlanSliceState = {
  currentTool: 'cursor',
  scale: 1,
  spacePlans: [],
  tables: [],
  tablesCopy: [],
  isCanvaDraggable: false,
  currentSpacePlan: null,
  spacePlanForEdit: null,
  selectedTable: null,
  listSpacePlansStatus: null,
  listTablesStatus: null,
  createSpacePlanStatus: null,
  updateSpacePlanStatus: null,
  deleteSpacePlanStatus: null,
  addTablesStatus: null,
  updateTableSpecsStatus: null,
  deleteTableStatus: null,
  updateTablesGroupsStatus: null,
  addTableToTablesGroupStatus: null,
  removeTableFromTablesGroupStatus: null,
  excludedAvailabilitySeries: null,
  excludedAvailabilitySeriesTemporary: null,
};

export const spacePlanSlice = createSlice({
  name: 'spacePlan',
  initialState,
  reducers: {
    addTable: (state, { payload }: PayloadAction<TTable>) => {
      state.tables = state.tables ? [...state.tables, payload] : [payload];

      if (state.currentSpacePlan == null) {
        state.currentSpacePlan = {
          id: payload.spacePlanId,
          name: i18n.t('messages.defaultSpacePlanName'),
        };

        state.tables = [payload];
      }
    },
    updateTable: (state, { payload }: PayloadAction<TTable>) => {
      state.tables = state.tables.map((table) => {
        if (table.id === payload.id) {
          return payload;
        }
        return table;
      });
    },
    duplicateTable: (state, { payload }: PayloadAction<string>) => {
      const tableToDuplicate = state.tables.find(
        (table) => table.id === payload,
      );

      if (tableToDuplicate) {
        const newTable = {
          ...tableToDuplicate,
          name: `${state.tables.length + 1}`,
          positionX: tableToDuplicate.positionX + 20,
          positionY: tableToDuplicate.positionY + 20,
          id: uuid(),
        } satisfies TTable;
        state.tables.push(newTable);
      }
    },
    rotateTable: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        rotate: 90 | 180 | 270;
      }>,
    ) => {
      state.tables = state.tables.map((table) => {
        if (table.id === payload.id) {
          return {
            ...table,
            rotate: table.rotate >= 270 ? 0 : table.rotate + payload.rotate,
          };
        }
        return table;
      });
    },

    deleteTable: (state, { payload }: PayloadAction<string>) => {
      state.tables = state.tables.filter((table) => table.id !== payload);
    },
    setSelectedTable: (state, { payload }: PayloadAction<TTable>) => {
      state.selectedTable = payload;
    },
    setExcludedAvailabilitiesSeries: (state, { payload }) => {
      state.excludedAvailabilitySeriesTemporary = payload.blockedAvailabilities;
      state.excludedAvailabilitySeries = payload.blockedAvailabilities;
    },
    clearExcludedAvailabilitiesSeries: (state) => {
      state.excludedAvailabilitySeries = null;
    },
    clearExcludedAvailabilitiesSeriesTemporary: (state) => {
      state.excludedAvailabilitySeriesTemporary = null;
    },
    setExcludedAvailabilitySeries: (state, { payload }) => {
      state.excludedAvailabilitySeriesTemporary = payload;
    },
    saveExcludedAvailabiltySeries: (state) => {
      state.excludedAvailabilitySeries =
        state.excludedAvailabilitySeriesTemporary;
    },
    setCurrentTool: (state, { payload }) => {
      state.currentTool = payload;
      if (payload === 'move') {
        state.isCanvaDraggable = true;
      } else {
        state.isCanvaDraggable = false;
      }
    },
    setScale: (state, { payload }) => {
      state.scale = payload;
    },
    setCurrentSpacePlan: (
      state,
      { payload }: PayloadAction<TSpacePlan | null>,
    ) => {
      const prevSpacePlan = state.currentSpacePlan;
      if (!isEmpty(state.spacePlans) && state.spacePlans) {
        state.spacePlans = state.spacePlans?.map((spacePlan) => {
          if (spacePlan.id === prevSpacePlan?.id) {
            return prevSpacePlan;
          }
          return spacePlan;
        });
      }
      state.currentSpacePlan = payload;
    },
    setSpacePlanForEdit: (state, { payload }: PayloadAction<TSpacePlan>) => {
      state.spacePlanForEdit = payload;
    },

    clearSpacePlans: () => initialState,
  },
  extraReducers: (builder) => {
    // listSpacePlans
    builder.addCase(listSpacePlansThunk.pending, (state) => {
      state.listSpacePlansStatus = 'loading';
      state.listTablesStatus = 'loading';
      state.tables = initialState.tables;
      state.tablesCopy = initialState.tablesCopy;
      state.currentSpacePlan = initialState.currentSpacePlan;
    });
    builder.addCase(listSpacePlansThunk.fulfilled, (state, { payload }) => {
      state.listSpacePlansStatus = 'success';
      if (payload.length === 0) {
        state.listTablesStatus = 'success';
      }
      [state.spacePlans, state.currentSpacePlan] = [payload, payload[0]];
    });
    builder.addCase(listSpacePlansThunk.rejected, (state) => {
      state.listSpacePlansStatus = 'failed';
      state.listTablesStatus = 'failed';
    });

    // createSpacePlan
    builder.addCase(createSpacePlanThunk.pending, (state) => {
      state.createSpacePlanStatus = 'loading';
    });
    builder.addCase(createSpacePlanThunk.fulfilled, (state, { payload }) => {
      state.createSpacePlanStatus = 'success';
      state.spacePlans = state.spacePlans
        ? [...state.spacePlans, payload]
        : [payload];
      state.currentSpacePlan = payload;
      state.tables = initialState.tables;
      state.tablesCopy = initialState.tablesCopy;
    });
    builder.addCase(createSpacePlanThunk.rejected, (state) => {
      state.createSpacePlanStatus = 'failed';
    });

    // createDefaultSpacePlan
    builder.addCase(createDefaultSpacePlanThunk.pending, (state) => {
      state.createSpacePlanStatus = 'loading';
    });
    builder.addCase(
      createDefaultSpacePlanThunk.fulfilled,
      (state, { payload }) => {
        state.createSpacePlanStatus = 'success';
        state.spacePlans = state.spacePlans
          ? [...state.spacePlans, payload.spacePlan]
          : [payload.spacePlan];
        state.currentSpacePlan = payload.spacePlan;
        state.tables = payload.tables;
        state.tablesCopy = payload.tables;
      },
    );
    builder.addCase(createDefaultSpacePlanThunk.rejected, (state) => {
      state.createSpacePlanStatus = 'failed';
    });

    // updateSpacePlan
    builder.addCase(updateSpacePlanThunk.pending, (state) => {
      state.updateSpacePlanStatus = 'loading';
    });
    builder.addCase(updateSpacePlanThunk.fulfilled, (state, { payload }) => {
      state.updateSpacePlanStatus = 'success';
      if (!payload) return;
      state.spacePlans = state.spacePlans.map((spacePlan) => {
        if (spacePlan.id === payload.id) {
          return payload;
        }
        return spacePlan;
      });

      if (state.currentSpacePlan?.id === payload.id) {
        state.currentSpacePlan = payload;
      }
    });
    builder.addCase(updateSpacePlanThunk.rejected, (state) => {
      state.updateSpacePlanStatus = 'failed';
    });

    // deleteSpacePlan
    builder.addCase(deleteSpacePlanThunk.pending, (state) => {
      state.deleteSpacePlanStatus = 'loading';
    });
    builder.addCase(deleteSpacePlanThunk.fulfilled, (state, { payload }) => {
      state.deleteSpacePlanStatus = 'success';
      if (!payload) return;
      state.spacePlans = state.spacePlans.filter(
        (spacePlan) => spacePlan.id !== payload,
      );
      state.tables = initialState.tables;
      state.tablesCopy = initialState.tablesCopy;

      if (state.currentSpacePlan?.id === payload) {
        if (isEmpty(state.spacePlans) || state.spacePlans == null) {
          state.currentSpacePlan = null;
        } else {
          [state.currentSpacePlan] = state.spacePlans;
        }
      }
      state.deleteSpacePlanStatus = null;
    });
    builder.addCase(deleteSpacePlanThunk.rejected, (state) => {
      state.deleteSpacePlanStatus = 'failed';
    });

    // listTables
    builder.addCase(listTablesThunk.pending, (state) => {
      state.listTablesStatus = 'loading';
    });
    builder.addCase(listTablesThunk.fulfilled, (state, { payload }) => {
      state.listTablesStatus = 'success';
      state.tables = payload;
      state.tablesCopy = payload;
    });
    builder.addCase(listTablesThunk.rejected, (state) => {
      state.listTablesStatus = 'failed';
    });

    // updateTableSpecs
    builder.addCase(updateTableSpecsThunk.pending, (state) => {
      state.updateTableSpecsStatus = 'loading';
    });
    builder.addCase(updateTableSpecsThunk.fulfilled, (state, { payload }) => {
      state.updateTableSpecsStatus = 'success';
      if (!payload) return;

      state.tables = state.tables.map((table) => {
        if (table.id === payload.id) {
          return payload;
        }
        return table;
      });
      state.tablesCopy = state.tables;
    });
    builder.addCase(updateTableSpecsThunk.rejected, (state) => {
      state.updateTableSpecsStatus = 'failed';
    });

    // deleteTable
    builder.addCase(deleteTableThunk.pending, (state) => {
      state.deleteTableStatus = 'loading';
    });
    builder.addCase(deleteTableThunk.fulfilled, (state, { payload }) => {
      state.deleteTableStatus = 'success';
      if (!payload) return;
      state.tables = state.tables.filter((table) => table.id !== payload);
      state.tablesCopy = state.tables;
    });
    builder.addCase(deleteTableThunk.rejected, (state) => {
      state.deleteTableStatus = 'failed';
    });

    // addTables
    builder.addCase(addTablesThunk.pending, (state) => {
      state.addTablesStatus = 'loading';
    });
    builder.addCase(addTablesThunk.fulfilled, (state, { payload }) => {
      state.addTablesStatus = 'success';
      const tableIds = payload.map((table) => table.id);
      state.tables = state.tables.map((table) => {
        if (tableIds.includes(table.id)) {
          const updatedTable = payload.find((t) => t.id === table.id);
          return updatedTable || table;
        }
        return table;
      });

      state.tablesCopy = state.tables;
    });
    builder.addCase(addTablesThunk.rejected, (state) => {
      state.addTablesStatus = 'failed';
    });
  },
});

export const {
  addTable,
  updateTable,
  deleteTable,
  rotateTable,
  duplicateTable,
  setSelectedTable,
  setCurrentTool,
  setScale,
  setCurrentSpacePlan,
  setSpacePlanForEdit,
  clearSpacePlans,
  setExcludedAvailabilitiesSeries,
  setExcludedAvailabilitySeries,
  saveExcludedAvailabiltySeries,
  clearExcludedAvailabilitiesSeries,
  clearExcludedAvailabilitiesSeriesTemporary,
} = spacePlanSlice.actions;

export default spacePlanSlice.reducer;
