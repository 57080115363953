const SupportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill="#B3ADC1"
      transform="translate(2, 2)"
      d="M9.237 17.75v-1.771h6.742V9.793q0-2.43-1.762-4.096T10 4.032q-2.456 0-4.207 1.665T4.042 9.793v5.186H1.417v-4.742h.854l.038-.937a7.3 7.3 0 0 1 .764-2.799 7.7 7.7 0 0 1 1.7-2.236 8 8 0 0 1 2.374-1.491A7.5 7.5 0 0 1 10 2.229q1.522 0 2.867.543a7.8 7.8 0 0 1 2.381 1.492A7.8 7.8 0 0 1 16.94 6.5q.656 1.287.771 2.773l.039.956h.833v4.75h-.833v2.77zm-1.575-5.588q-.412 0-.711-.279a.9.9 0 0 1-.299-.69q0-.413.299-.707a.98.98 0 0 1 .71-.296.94.94 0 0 1 .7.294.98.98 0 0 1 .286.708.93.93 0 0 1-.287.691.97.97 0 0 1-.698.279m4.7 0q-.412 0-.71-.279a.9.9 0 0 1-.3-.69q0-.413.3-.707a.98.98 0 0 1 .71-.296q.411 0 .699.294a.98.98 0 0 1 .287.708.93.93 0 0 1-.287.691.97.97 0 0 1-.7.279m-7.303-1.579q-.15-2.248 1.313-3.918t3.67-1.67q1.896 0 3.286 1.24 1.392 1.239 1.717 3.132a7.7 7.7 0 0 1-3.31-.777 6.2 6.2 0 0 1-2.512-2.197 5.9 5.9 0 0 1-1.585 2.56 7.7 7.7 0 0 1-2.579 1.63"
    />
  </svg>
);
export default SupportIcon;
