const EyeOpenIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0024 16.1769C13.3022 16.1769 14.4062 15.722 15.3146 14.8121C16.2229 13.9023 16.6771 12.7975 16.6771 11.4977C16.6771 10.198 16.2222 9.09391 15.3123 8.18558C14.4025 7.27724 13.2977 6.82308 11.9979 6.82308C10.6981 6.82308 9.59411 7.27799 8.68577 8.18783C7.77744 9.09768 7.32327 10.2025 7.32327 11.5023C7.32327 12.802 7.77819 13.906 8.68802 14.8144C9.59787 15.7227 10.7027 16.1769 12.0024 16.1769ZM11.9972 14.25C11.2325 14.25 10.5835 13.9823 10.0502 13.447C9.51684 12.9117 9.25017 12.2617 9.25017 11.497C9.25017 10.7323 9.51782 10.0833 10.0531 9.54998C10.5884 9.01664 11.2384 8.74998 12.0031 8.74998C12.7678 8.74998 13.4168 9.01763 13.9502 9.55293C14.4835 10.0882 14.7502 10.7382 14.7502 11.5029C14.7502 12.2676 14.4825 12.9166 13.9472 13.45C13.4119 13.9833 12.7619 14.25 11.9972 14.25ZM12.0015 19.25C9.4686 19.25 7.17327 18.5448 5.11557 17.1346C3.0579 15.7243 1.56561 13.8461 0.638672 11.5C1.56559 9.15383 3.05744 7.27563 5.11422 5.86538C7.17099 4.45513 9.46585 3.75 11.9988 3.75C14.5317 3.75 16.8271 4.45513 18.8848 5.86538C20.9425 7.27563 22.4348 9.15383 23.3617 11.5C22.4348 13.8461 20.9429 15.7243 18.8861 17.1346C16.8294 18.5448 14.5345 19.25 12.0015 19.25Z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default EyeOpenIcon;
