import { z } from 'zod';

import { EMPLOYEE_ROLES } from '../constants/constants';

export const EmployeeSchema = z.object({
  createdAt: z.string().optional(),
  email: z.string().email(),
  firebaseId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string().optional().nullable(),
  role: z.enum(EMPLOYEE_ROLES),
  tempPassword: z.boolean(),
  updatedAt: z.string().optional(),
  id: z.string(),
  placeId: z.string(),
});

export const ListedEmployeeSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  role: z.enum(EMPLOYEE_ROLES),
  id: z.string(),
  firebaseId: z.string(),
});

export const ListedEmployeesSchema = z.array(ListedEmployeeSchema);

export const GetEmployeeSchema = z.object({
  successCode: z.string(),
  data: EmployeeSchema,
});

export const ListEmployeesSchema = z.object({
  successCode: z.string(),
  data: ListedEmployeesSchema,
});

export const CreateEmployeeSchema = z.object({
  successCode: z.string(),
  data: ListedEmployeeSchema,
});

export const UpdateEmployeeSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});

export const DeleteEmployeeSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});
