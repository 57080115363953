import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'i18next';
import Compressor from 'compressorjs';

import { storage } from '../firebase/firebaseConfig';
import { ImageCompressionError, TCollectionName } from '../types/Storage';

export const getFileNameFromStorageLink = (
  str: string,
  collectionName: TCollectionName,
) => {
  const prefix = `${collectionName}%2F`;
  const char1 = str.indexOf(prefix) + prefix.length;
  const char2 = str.lastIndexOf('?alt=media');
  return str.substring(char1, char2);
};

export const getFileCdnFromStorageLink = (link: any) => {
  let cdn = link.replace(import.meta.env.VITE_BUNNY_CDN_URL, '');
  const altMediaIndex = cdn.indexOf('?alt=media');
  cdn = cdn.substring(0, altMediaIndex + 10);
  return cdn;
};

export const removeImageFromStorage = async (
  imagePath: any,
  collectionName: TCollectionName,
) => {
  try {
    const storageImageName = getFileNameFromStorageLink(
      imagePath,
      collectionName,
    );
    const imageRef = ref(storage, `${collectionName}/${storageImageName}`);

    await deleteObject(imageRef);
  } catch (e) {
    toast.error(t('errorMessages.somethingWentWrongDuringRemovingOldImage'));
  }
};

export const compressImage = async (file: File) => {
  const compressedBlob = await new Promise<Blob>((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      maxWidth: 1920,
      maxHeight: 1080,
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(new ImageCompressionError(error.message));
      },
    });
  });

  return compressedBlob;
};

export const uploadPhoto = async (photo: any, path: string) => {
  try {
    const compressedPhoto = await compressImage(photo);

    const filename = `${uuidv4()}.${photo.name.split('.').pop()}`;
    const storageRef = ref(storage, `${path}/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, compressedPhoto);

    await uploadTask;

    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    const index = downloadURL.indexOf('/o/');
    let photoUrl = '';
    if (index !== -1) {
      const urlBeforeParams = downloadURL.split('&')[0];
      photoUrl = urlBeforeParams.substring(index + 3);
    }

    const gsPath = uploadTask.snapshot.ref.fullPath;

    return {
      http: downloadURL,
      gs: `gs://${uploadTask.snapshot.ref.bucket}/${gsPath}`,
      referencePath: gsPath,
      fileName: filename,
      task: uploadTask,
      photoUrl,
    };
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getBunnyImage = (
  url: string,
  width: string,
  height: string,
  aspRatio: string = '1:1',
) => {
  if (!url) {
    return '';
  }

  let optimizedUrl = '';

  if (!url.includes('http')) {
    optimizedUrl = `${import.meta.env.VITE_BUNNY_CDN_URL}${url}`;
  } else {
    const index = url.indexOf('/o/');
    if (index !== -1) {
      const tempId = url.substring(index + 3);
      optimizedUrl = `${import.meta.env.VITE_BUNNY_CDN_URL}${tempId}`;
    } else {
      optimizedUrl = url;
    }
  }

  const urlObject = new URL(optimizedUrl);

  if (width) {
    urlObject.searchParams.set('width', width);
  }

  if (height) {
    urlObject.searchParams.set('height', height);
  }

  if (aspRatio) {
    urlObject.searchParams.set('aspect_ratio', aspRatio);
  }

  return urlObject.toString();
};

export const getOfferCategoryImage = (
  fileName: string,
  width: string,
  height: string,
) => {
  if (fileName.startsWith('defaultImage/')) {
    const cleanFileName = fileName.replace('defaultImage/', '');
    return new URL(
      `../assets/images/offerCategories/${cleanFileName}.webp`,
      import.meta.url,
    ).href;
  }
  return getBunnyImage(fileName, width, height);
};
