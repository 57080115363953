const PanelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M4.9089 17.0284L6.62365 12.7368H9.10442V9.57487C7.06383 9.49368 5.36912 9.18299 4.0203 8.64279C2.67148 8.1026 1.99707 7.46827 1.99707 6.73981C1.99707 5.9568 2.77393 5.28618 4.32765 4.72796C5.88136 4.16974 7.77013 3.89062 9.99395 3.89062C12.2268 3.89062 14.1199 4.1684 15.6733 4.72394C17.2267 5.27949 18.0034 5.95471 18.0034 6.74958C18.0034 7.48076 17.3277 8.11414 15.9762 8.64973C14.6247 9.1853 12.9313 9.49368 10.8961 9.57487V12.7368H13.3768L15.0916 17.0284H13.2422L12.2422 14.5284H7.75824L6.75824 17.0284H4.9089Z"
        fill="white"
      />
    </svg>
  );
};

export default PanelIcon;
