import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks/useRedux';
import { routes } from '../constants/constants';

const EmployeeRoute = () => {
  const user = useAppSelector((state) => state.user.user);
  const isEmployee = user?.role === 'EMPLOYEE';

  return isEmployee ? <Navigate to={routes.summary} /> : <Outlet />;
};

export default EmployeeRoute;
