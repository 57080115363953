import InfoIconWithTooltip from '../InfoIconWithTooltip/InfoIconWithTooltip';
import { IErrorMessageWithIconProps } from './ErrorMessageWithIcon.types';

const ErrorMessageWithIcon = ({
  tooltipText,
  text,
}: IErrorMessageWithIconProps) => {
  return (
    <div className="flex gap-2">
      <div className="mt-[3px]">
        <InfoIconWithTooltip text={tooltipText} isErrorMessage />
      </div>
      <span className="mt-1 text-sm text-red-600">{text}</span>
    </div>
  );
};

export default ErrorMessageWithIcon;
