import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { isBefore, isSameDay } from 'date-fns';
import { z } from 'zod';
import { current } from 'immer';

import {
  createReservationThunk,
  cancelReservationThunk,
  listReservationsWithUnseenMessagesThunk,
  listAvailableHoursThunk,
  listFreeTablesThunk,
  listReservationsThunk,
  updateReservationThunk,
  hasPlaceAnyReervationThunk,
  listSummaryPageReservationsThunk,
  markMessagesAsSeenThunk,
  startReservationThunk,
  finishReservationThunk,
  sendSmsThunk,
  addDelayToReservationThunk,
  changeTableForReservationThunk,
  checkIsAnyUnseenMessageThunk,
  getTotalReservationsCountThunk,
} from '../thunks/reservationThunk';
import { TReservation, IReservationSliceState } from '../../types/Reservation';
import { ReservationSchema } from '../../schema/ReservationSchemas';
import {
  addMinutesToTime,
  calculateDurationInMinutes,
  extractHourFromIsoString,
  getDifferenceInMinutes,
} from '../../functions/functions';

const initialState: IReservationSliceState = {
  reservations: {
    items: [],
    total: 0,
  },
  summaryPageDate: new Date().toISOString(),
  summaryPageReservations: [],
  availableHours: [],
  currentReservationId: null,
  reservation: null,
  isAnyMessageUnseen: false,
  reservationsProblem: false,
  hasPlaceAnyReservation: false,
  freeTables: [],
  sendSmsStatus: null,
  listReservationStatus: null,
  listAvailableHoursStatus: null,
  createReservationStatus: null,
  updateReservationStatus: null,
  cancelReservationStatus: null,
  checkIsAnyMessageUnseenStatus: null,
  listReservationsWithUnseenMessagesStatus: null,
  hasPlaceAnyReservationStatus: null,
  listFreeTablesStatus: null,
  markMessagesAsSeenStatus: null,
  startReservationStatus: null,
  finishReservationStatus: null,
  addDelayToReservationStatus: null,
  changeTableForReservationStatus: null,
  getTotalReservationsCountStatus: null,
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    addReservationFromSocket: (
      state,
      {
        payload,
      }: PayloadAction<{
        reservation: z.infer<typeof ReservationSchema>;
        timezone: string;
        callback: () => void;
      }>,
    ) => {
      const { reservation, timezone, callback } = payload;
      if (
        isSameDay(
          new Date(state.summaryPageDate),
          new Date(reservation.dateStart),
        )
      ) {
        const reservationExists = state.summaryPageReservations.some(
          (res) => res.id === reservation.id,
        );

        if (reservationExists) return;

        let indexToInsert = -1;
        state.summaryPageReservations.forEach((res, i) => {
          if (indexToInsert !== -1) return;
          if (
            isBefore(new Date(reservation.dateStart), new Date(res.dateStart))
          ) {
            indexToInsert = i;
          }
        });

        const walkIn = !!(!reservation.firstName || !reservation.lastName);
        const time = calculateDurationInMinutes(
          reservation.dateStart,
          reservation.dateEnd,
        );
        const hour = extractHourFromIsoString(reservation.dateStart, timezone);
        const formattedTableNames = reservation.ReservationTable.map(
          (table) => table.name,
        ).join(', ');

        const newReservation = {
          ...reservation,
          fullName: walkIn
            ? 'Walk-in'
            : `${reservation.firstName} ${reservation.lastName}`,
          hour,
          time,
          walkIn,
          formattedTableNames,
        } satisfies TReservation;

        if (indexToInsert === -1) {
          state.summaryPageReservations.push(newReservation);
        } else {
          state.summaryPageReservations.splice(
            indexToInsert,
            0,
            newReservation,
          );
        }

        callback();
      }
    },
    updateReservationFromSocket: (
      state,
      { payload }: PayloadAction<z.infer<typeof ReservationSchema>>,
    ) => {
      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.id) {
            return {
              ...payload,
              formattedTableNames: reservation.formattedTableNames,
              fullName: reservation.fullName,
              walkIn: reservation.walkIn,
              time: reservation.time,
              hour: reservation.hour,
            };
          }
          return reservation;
        },
      );
    },
    setReservationStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        reservationId: string;
        status: TReservation['status'];
      }>,
    ) => {
      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.reservationId) {
            return {
              ...reservation,
              status: payload.status,
            };
          }
          return reservation;
        },
      );
    },
    setSummaryPageDate: (state, { payload }: PayloadAction<string>) => {
      state.summaryPageDate = payload;
    },
    clearAvailableHours: (state) => {
      state.availableHours = initialState.availableHours;
    },
    setCurrentReservationId: (state, { payload }) => {
      state.currentReservationId = payload;
    },
    resetCancelReservationStatus: (state) => {
      state.cancelReservationStatus = null;
    },
    setReservationForEdit: (
      state,
      { payload }: PayloadAction<TReservation>,
    ) => {
      state.reservation = payload;
    },
    setReservationForEditById: (state, { payload }: PayloadAction<string>) => {
      if (state.reservations) {
        const [matchingReservation] = state.reservations.items.filter(
          (item) => item.id === payload,
        );

        state.reservation = matchingReservation;
      }
    },
    setActionNeededReservationForEditById: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      if (state.reservations) {
        const [matchingReservation] = state.reservations.items.filter(
          (item) => item.id === payload,
        );
        state.reservation = matchingReservation;
      }
    },
    clearReservations: () => initialState,
    clearCurrentReservation: (state) => {
      state.reservation = initialState.reservation;
    },
    setReservationsProblem: (state, { payload }) => {
      state.reservationsProblem = payload;
    },
    updateReservations: (state, action) => {
      state.reservations = action.payload;
    },
    removeUnseenReservationAfterReadingMessage: (state, { payload }) => {
      state.reservations.items = state.reservations.items.filter(
        (reservation) => {
          if (reservation.id === payload.reservationId) {
            return false;
          }
          return true;
        },
      );
    },
  },
  extraReducers: (builder) => {
    // checkReservation
    builder.addCase(hasPlaceAnyReervationThunk.pending, (state) => {
      state.hasPlaceAnyReservationStatus = 'loading';
    });
    builder.addCase(
      hasPlaceAnyReervationThunk.fulfilled,
      (state, { payload }) => {
        state.hasPlaceAnyReservation = payload;
        state.hasPlaceAnyReservationStatus = 'success';
      },
    );
    builder.addCase(hasPlaceAnyReervationThunk.rejected, (state) => {
      state.hasPlaceAnyReservationStatus = 'failed';
    });

    // list summary page reservations
    builder.addCase(listSummaryPageReservationsThunk.pending, (state) => {
      state.listReservationStatus = 'loading';
    });
    builder.addCase(
      listSummaryPageReservationsThunk.fulfilled,
      (state, { payload }) => {
        state.summaryPageReservations = payload;
        state.listReservationStatus = 'success';
      },
    );
    builder.addCase(listSummaryPageReservationsThunk.rejected, (state) => {
      state.listReservationStatus = 'failed';
    });

    // listReservations
    builder.addCase(listReservationsThunk.pending, (state) => {
      state.listReservationStatus = 'loading';
    });
    builder.addCase(listReservationsThunk.fulfilled, (state, { payload }) => {
      state.reservations.items = [...payload.items];
      state.listReservationStatus = 'success';
    });
    builder.addCase(listReservationsThunk.rejected, (state) => {
      state.listReservationStatus = 'failed';
    });

    // listAllReservations
    builder.addCase(
      listReservationsWithUnseenMessagesThunk.pending,
      (state) => {
        state.listReservationsWithUnseenMessagesStatus = 'loading';
      },
    );
    builder.addCase(
      listReservationsWithUnseenMessagesThunk.fulfilled,
      (state, { payload }) => {
        state.reservations.items = [...payload.items];
        state.listReservationsWithUnseenMessagesStatus = 'success';
      },
    );
    builder.addCase(
      listReservationsWithUnseenMessagesThunk.rejected,
      (state) => {
        state.listReservationsWithUnseenMessagesStatus = 'failed';
      },
    );

    // getTotalReservationCount
    builder.addCase(getTotalReservationsCountThunk.pending, (state) => {
      state.getTotalReservationsCountStatus = 'loading';
    });
    builder.addCase(
      getTotalReservationsCountThunk.fulfilled,
      (state, { payload }) => {
        state.reservations.total = payload;
        state.getTotalReservationsCountStatus = 'success';
      },
    );
    builder.addCase(getTotalReservationsCountThunk.rejected, (state) => {
      state.getTotalReservationsCountStatus = 'failed';
    });

    // listAvailableHoursThunk
    builder.addCase(listAvailableHoursThunk.pending, (state) => {
      state.listAvailableHoursStatus = 'loading';
    });
    builder.addCase(listAvailableHoursThunk.fulfilled, (state, { payload }) => {
      state.listAvailableHoursStatus = 'success';
      if (!payload) return;
      state.availableHours = payload;
    });
    builder.addCase(listAvailableHoursThunk.rejected, (state) => {
      state.listAvailableHoursStatus = 'failed';
    });

    // listFreeTables
    builder.addCase(listFreeTablesThunk.pending, (state) => {
      state.listFreeTablesStatus = 'loading';
    });
    builder.addCase(listFreeTablesThunk.fulfilled, (state, { payload }) => {
      state.listFreeTablesStatus = 'success';
      if (!payload) return;
      state.freeTables = payload;
    });
    builder.addCase(listFreeTablesThunk.rejected, (state) => {
      state.listFreeTablesStatus = 'failed';
    });

    // createReservation
    builder.addCase(createReservationThunk.pending, (state) => {
      state.createReservationStatus = 'loading';
    });
    builder.addCase(createReservationThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      if (payload.isWithinSelectedFilters) {
        const { newReservation } = payload;

        const {
          reservations: { items },
        } = state;

        const index = items.findIndex(
          (reservation) =>
            new Date(reservation.dateStart) >
            new Date(newReservation.dateStart),
        );

        if (index === -1) {
          items.push(newReservation);
        } else {
          items.splice(index, 0, newReservation);
        }
        state.reservations.total += 1;

        if (!state.hasPlaceAnyReservation) {
          state.hasPlaceAnyReservation = true;
        }
      }
      state.createReservationStatus = 'success';
    });
    builder.addCase(createReservationThunk.rejected, (state, error) => {
      if (error.payload === 'NO_PLACE') {
        toast.error(t('errorMessages.noPlace'));
      }
      if (error.payload === 'DATE_PASSED') {
        toast.error(t('errorMessages.datePassed'));
      }
      if (error.payload === 'NO_SPACE_PLAN') {
        toast.error(t('errorMessages.noSpacePlan'));
      }
      if (error.payload === 'NO_TABLE') {
        toast.error(t('errorMessages.noTable'));
      }
      if (error.payload === 'TABLE_TAKEN') {
        toast.error(t('errorMessages.tableTaken'));
      }
      state.createReservationStatus = 'failed';
    });

    // updateReservation
    builder.addCase(updateReservationThunk.pending, (state) => {
      state.updateReservationStatus = 'loading';
    });
    builder.addCase(updateReservationThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      const { updatedReservation } = payload;

      const hasReservationDataChanged = (
        currentReservation: TReservation,
        newReservation: Partial<TReservation>,
      ) => {
        const keysToCompare: (keyof TReservation)[] = ['dateStart', 'time'];

        return keysToCompare.some(
          (key) => currentReservation[key] !== newReservation[key],
        );
      };

      if (payload.isWithinSelectedFilters) {
        state.reservations.items = state.reservations.items.map(
          (reservation) => {
            if (reservation.id === payload.updatedReservation.id) {
              const hasDataChanged = hasReservationDataChanged(
                reservation,
                updatedReservation,
              );

              const formattedUpdatedReservation = {
                ...payload.updatedReservation,
                createdAt: reservation.createdAt,
                status: hasDataChanged
                  ? updatedReservation.status
                  : reservation.status,
              };
              return formattedUpdatedReservation;
            }
            return reservation;
          },
        );
      } else {
        state.reservations.items = state.reservations.items.filter(
          (item) => item.id !== payload.updatedReservation.id,
        );
      }

      // logic for updating reservation in summary page reservations list
      if (
        isSameDay(
          new Date(state.summaryPageDate),
          new Date(payload.updatedReservation.dateStart),
        )
      ) {
        state.summaryPageReservations = state.summaryPageReservations.map(
          (reservation) => {
            if (reservation.id === payload.updatedReservation.id) {
              return {
                ...reservation,
                ...payload.updatedReservation,
              };
            }
            return reservation;
          },
        );
      }

      state.updateReservationStatus = 'success';
    });
    builder.addCase(updateReservationThunk.rejected, (state) => {
      state.updateReservationStatus = 'failed';
    });

    // sendSms
    builder.addCase(sendSmsThunk.pending, (state) => {
      state.sendSmsStatus = 'loading';
    });
    builder.addCase(sendSmsThunk.fulfilled, (state, { payload }) => {
      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.id) {
            return {
              ...reservation,
              status: 'SMS_CONFIRMED',
            };
          }
          return reservation;
        },
      );

      state.reservations.items = state.reservations.items.map((reservation) => {
        if (reservation.id === payload.id) {
          return {
            ...reservation,
            status: 'SMS_CONFIRMED',
          };
        }
        return reservation;
      });

      state.sendSmsStatus = 'success';
    });
    builder.addCase(sendSmsThunk.rejected, (state) => {
      state.sendSmsStatus = 'failed';
    });

    // startReservation
    builder.addCase(startReservationThunk.pending, (state) => {
      state.startReservationStatus = 'loading';
    });
    builder.addCase(startReservationThunk.fulfilled, (state, { payload }) => {
      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.id) {
            return {
              ...reservation,
              status: 'STARTED',
            };
          }
          return reservation;
        },
      );

      if (!payload.containsInProgressInFilter) {
        state.reservations.items = state.reservations.items.filter(
          (reservation) => reservation.id !== payload.id,
        );
      } else {
        state.reservations.items = state.reservations.items.map(
          (reservation) => {
            if (reservation.id === payload.id) {
              return {
                ...reservation,
                status: 'STARTED',
              };
            }
            return reservation;
          },
        );
      }

      state.startReservationStatus = 'success';
    });
    builder.addCase(startReservationThunk.rejected, (state) => {
      state.startReservationStatus = 'failed';
    });

    // changeTableForReservation
    builder.addCase(changeTableForReservationThunk.pending, (state) => {
      state.changeTableForReservationStatus = 'loading';
    });
    builder.addCase(
      changeTableForReservationThunk.fulfilled,
      (state, { payload }) => {
        state.summaryPageReservations = state.summaryPageReservations.map(
          (reservation) => {
            if (reservation.id === payload.id) {
              return {
                ...reservation,
                ReservationTable: [payload.table],
              };
            }
            return reservation;
          },
        );
        state.changeTableForReservationStatus = 'success';
      },
    );
    builder.addCase(changeTableForReservationThunk.rejected, (state) => {
      state.changeTableForReservationStatus = 'failed';
    });

    // finishReservation
    builder.addCase(finishReservationThunk.pending, (state) => {
      state.finishReservationStatus = 'loading';
    });

    builder.addCase(finishReservationThunk.fulfilled, (state, { payload }) => {
      state.summaryPageReservations = state.summaryPageReservations.filter(
        (reservation) => reservation.id !== payload.id,
      );

      if (!payload.containsEndedInFilter) {
        state.reservations.items = state.reservations.items.filter(
          (reservation) => reservation.id !== payload.id,
        );
      } else {
        state.reservations.items = state.reservations.items.map(
          (reservation) => {
            if (reservation.id === payload.id) {
              return {
                ...reservation,
                status: 'FINISHED',
              };
            }
            return reservation;
          },
        );
      }

      state.finishReservationStatus = 'success';
    });
    builder.addCase(finishReservationThunk.rejected, (state) => {
      state.finishReservationStatus = 'failed';
    });

    // addDelayToReservation
    builder.addCase(addDelayToReservationThunk.pending, (state) => {
      state.addDelayToReservationStatus = 'loading';
    });
    builder.addCase(
      addDelayToReservationThunk.fulfilled,
      (state, { payload }) => {
        state.summaryPageReservations = state.summaryPageReservations.map(
          (reservation) => {
            if (reservation.id === payload.id) {
              return {
                ...reservation,
                dateStart: payload.newStartDate,
                time: payload.newTime,
              };
            }
            return reservation;
          },
        );

        state.reservations.items = state.reservations.items.map(
          (reservation) => {
            if (reservation.id === payload.id) {
              const newTime = getDifferenceInMinutes(
                reservation.dateStart,
                payload.newStartDate,
              );
              return {
                ...reservation,
                dateStart: payload.newStartDate,
                time: payload.newTime,
                hour: addMinutesToTime(reservation.hour, newTime),
              };
            }
            return reservation;
          },
        );

        state.addDelayToReservationStatus = 'success';
      },
    );
    builder.addCase(addDelayToReservationThunk.rejected, (state) => {
      state.addDelayToReservationStatus = 'failed';
    });

    // cancelReservationThunk
    builder.addCase(cancelReservationThunk.pending, (state) => {
      state.cancelReservationStatus = 'loading';
    });
    builder.addCase(cancelReservationThunk.fulfilled, (state, { payload }) => {
      state.reservations.items = state.reservations.items
        .map((reservation: any) => {
          if (reservation.id === payload.id) {
            if (payload.containsIsCancelInFilter) {
              return {
                ...reservation,
                status: 'CANCELLED',
              };
            }
            return null;
          }
          return reservation;
        })
        .filter((reservation) => reservation !== null);

      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.id) {
            return {
              ...reservation,
              status: 'CANCELLED',
            };
          }
          return reservation;
        },
      );

      state.cancelReservationStatus = 'success';
    });
    builder.addCase(cancelReservationThunk.rejected, (state) => {
      state.cancelReservationStatus = 'failed';
    });

    // markMessagesAsSeen
    builder.addCase(markMessagesAsSeenThunk.pending, (state) => {
      state.markMessagesAsSeenStatus = 'loading';
    });
    builder.addCase(markMessagesAsSeenThunk.fulfilled, (state, { payload }) => {
      if (!state.reservations || !payload) return;

      state.reservations.items = state.reservations.items.map((reservation) => {
        if (reservation.id === payload.reservationId) {
          return { ...reservation, messageCount: 0 };
        }
        return reservation;
      });

      state.summaryPageReservations = state.summaryPageReservations.map(
        (reservation) => {
          if (reservation.id === payload.reservationId) {
            return { ...reservation, messageCount: 0 };
          }
          return reservation;
        },
      );

      state.markMessagesAsSeenStatus = 'success';
    });
    builder.addCase(markMessagesAsSeenThunk.rejected, (state) => {
      state.markMessagesAsSeenStatus = 'failed';
    });

    // isAnyMessageUnseen
    builder.addCase(checkIsAnyUnseenMessageThunk.pending, (state) => {
      state.checkIsAnyMessageUnseenStatus = 'loading';
    });
    builder.addCase(
      checkIsAnyUnseenMessageThunk.fulfilled,
      (state, { payload }) => {
        state.isAnyMessageUnseen = payload;
        state.checkIsAnyMessageUnseenStatus = 'success';
      },
    );
    builder.addCase(checkIsAnyUnseenMessageThunk.rejected, (state) => {
      state.checkIsAnyMessageUnseenStatus = 'failed';
    });
  },
});

export const {
  addReservationFromSocket,
  updateReservationFromSocket,
  setReservationStatus,
  setSummaryPageDate,
  clearAvailableHours,
  setCurrentReservationId,
  resetCancelReservationStatus,
  setReservationForEdit,
  setReservationForEditById,
  clearReservations,
  clearCurrentReservation,
  setReservationsProblem,
  setActionNeededReservationForEditById,
  updateReservations,
  removeUnseenReservationAfterReadingMessage,
} = reservationSlice.actions;

export default reservationSlice.reducer;
