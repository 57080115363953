import { createPortal } from 'react-dom';
import clsx from 'clsx';

import CloseIcon from '../../../../assets/icons/CloseIcon';
import TertiaryHeader from '../../atoms/TertiaryHeader/TertiaryHeader';
import { IDialogProps } from './Dialog.types';
import MinusIcon from '../../../../assets/icons/MinusIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  resetWindowMinimalized,
  setWindowMinimalized,
} from '../../../../redux/features/settingsSlice';

const Dialog = ({ visible, title, onClose, children, icon }: IDialogProps) => {
  const dispatch = useAppDispatch();

  const isMinimalized = useAppSelector(
    (store) => store.settings.isDialogMinimalized,
  );

  return createPortal(
    visible ? (
      <div
        className={clsx(
          'pointer-events-none absolute left-0 top-0 h-screen w-screen items-end xl:justify-end',
          visible ? 'flex' : 'hidden',
        )}
      >
        {!isMinimalized ? (
          <div className="pointer-events-auto z-[91] w-full rounded-t-[8px] border-t bg-brand-50 p-6 xl:mb-10 xl:mr-10 xl:w-[375px] xl:rounded-[8px] xl:border">
            <div className="flex justify-between pb-4 md:border-b">
              <TertiaryHeader text={title} />
              <div className="flex items-center">
                <button
                  type="button"
                  className="mr-4"
                  onClick={() => dispatch(setWindowMinimalized())}
                >
                  <MinusIcon />
                </button>
                <button type="button" onClick={onClose}>
                  <CloseIcon />
                </button>
              </div>
            </div>
            {children}
          </div>
        ) : (
          <div
            className="pointer-events-auto z-[91] mb-10 ml-auto mr-10 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border bg-brand-50"
            onClick={() => dispatch(resetWindowMinimalized())}
          >
            {icon}
          </div>
        )}
      </div>
    ) : null,
    document.body,
  );
};
export default Dialog;
