import { SVGProps } from 'react';

const PenIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.84 7.734L12.902 3.78l1.923-1.923 3.925 3.967-1.91 1.91zM2.19 18.458V14.5l9.83-9.829 3.937 3.938-9.808 9.85H2.191z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default PenIcon;
