const ExitIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.7 21.3V2.7h9.31v2.15H4.85v14.3h7.16v2.15H2.7zm13.33-4.03l-1.563-1.585 2.635-2.635H8.946V10.9h8.156l-2.635-2.635 1.564-1.534L21.3 12l-5.27 5.27z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default ExitIcon;
