import { FC, MouseEvent, createElement, useRef, useState } from 'react';
import clsx from 'clsx';

import MoreIcon from '../../../../assets/icons/MoreIcon';
import { IMoreOptionsDropdownProps } from './MoreOptionsDropdown.types';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';

const MoreOptionsDropdown: FC<IMoreOptionsDropdownProps> = ({
  options,
  stopTouchPropagation,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [dropdownDirection, setDropdownDirection] = useState('bottom');

  const handleToggleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);

    if (!isOpen && wrapperRef.current) {
      const wrapperRect = wrapperRef.current?.getBoundingClientRect();
      const spaceBelow = window.innerHeight - wrapperRect.bottom;
      const spaceAbove = wrapperRect.top;

      if (wrapperRect && spaceBelow < 230 && spaceAbove > 150) {
        setDropdownDirection('top');
      } else {
        setDropdownDirection('bottom');
      }
    }
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (
    e: MouseEvent<HTMLElement>,
    callback: () => void,
  ) => {
    e.stopPropagation();
    callback();
    setIsOpen(false);
  };

  useDetectOutsideClick(wrapperRef, handleCloseDropdown);

  return (
    <div
      ref={wrapperRef}
      className="flex items-center justify-center"
      onTouchStart={
        stopTouchPropagation ? (event) => event.stopPropagation() : undefined
      }
    >
      <button type="button" onClick={handleToggleOpen}>
        <MoreIcon />
      </button>
      {isOpen ? (
        <div
          className={clsx(
            'absolute z-10 mr-[200px] flex w-56 flex-col gap-4 rounded-xs border bg-brand-50 p-5',
            dropdownDirection === 'top' ? '-mt-[150px]' : 'mt-[150px]',
          )}
        >
          {options.map((option) => (
            <button
              key={option.label}
              type="button"
              className="flex items-center gap-2"
              onClick={(e) => handleOptionClick(e, option.onClick)}
            >
              {option.icon &&
                createElement(option.icon, {
                  className: option.isDelete ? 'fill-red-500' : '',
                })}
              <span
                className={clsx(
                  'text-left font-medium ',
                  option.isDelete && 'text-red-500',
                )}
              >
                {option.label}
              </span>
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default MoreOptionsDropdown;
