import { v4 as uuidv4 } from 'uuid';

import { IProductSkeleton } from './ProductSkeleton.types';

export const ProductSkeleton = ({ count = 2 }: IProductSkeleton) => {
  return (
    <>
      {[...Array(count)].map(() => (
        <div
          key={uuidv4()}
          className="flex h-[72px] w-full items-center justify-between gap-4 border-b px-2 xl:px-4"
        >
          <div className="flex items-center gap-4 pl-4 md:pl-16">
            <span className="h-11 w-11 rounded-xs bg-brand-700" />
            <div className="flex flex-col gap-1">
              <span className="h-6 w-20 rounded-xs bg-brand-700 md:w-52" />
              <span className="h-6 w-28 rounded-xs bg-brand-300 md:w-96" />
            </div>
          </div>
          <span className="h-6 w-6 rounded-xs bg-brand-700" />
        </div>
      ))}
    </>
  );
};
