import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../hooks/useRedux';
import { reduxStatus, routes } from '../constants/constants';
import { IDisabledChatRoute } from '../types/Chat';
import SectionLoader from '../components/UI/atoms/SectionLoader/SectionLoader';

const DisabledChatRoute = ({ children }: IDisabledChatRoute) => {
  const isChatAvailable = useAppSelector(
    (state) => state.settings.isChatAvailable,
  );

  const getPlaceFirstStepsStatus = useAppSelector(
    (state) => state.settings.getPlaceFirstStepsStatus,
  );

  if (getPlaceFirstStepsStatus === reduxStatus.success) {
    return isChatAvailable ? children : <Navigate to={routes.summary} />;
  }
};

export default DisabledChatRoute;
