import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { uuidv4 } from '@firebase/util';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';

import { IImageCropModalProps } from './ImageCropModal.types';
import Modal from '../../molecules/Modal/Modal';
import UndoIcon from '../../../../assets/icons/UndoIcon';
import Button from '../../molecules/Button/Button';
import ArrowLeftIcon from '../../../../assets/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/ArrowRightIcon';

const ImageCropModal = ({
  visible,
  onClose,
  onChange,
  imagesWorkspace,
  imageFilename,
  setImagePreview,
  isSingleImage,
  labelText,
  gallery,
  photos,
  setGallery,
  setPhotos,
  sortImageArray,
  aspect,
}: IImageCropModalProps) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<Crop[]>([]);

  const [elementWidth, setElementWidth] = useState<number>(0);

  const [initImageSize, setInitImageSize] = useState<
    { width: number; height: number }[]
  >([]);

  const [imageNumber, setImageNumber] = useState<number>(0);

  const cropRef = useRef<ReactCrop | null>(null);

  const imageRef = useRef<HTMLImageElement>(null);

  const handlePreviousImage = () => {
    if (imageNumber <= 0) return;
    setImageNumber((prev) => prev - 1);
  };

  const handleNextImage = () => {
    if (imagesWorkspace && imageNumber + 1 >= imagesWorkspace?.length) return;
    setImageNumber((prev) => prev + 1);
  };

  const handleSave = () => {
    if (imagesWorkspace && imagesWorkspace?.length > imageNumber + 1) {
      handleNextImage();
      return;
    }
    const imagesArray = imagesWorkspace || [];
    const updatedGallery = gallery ? [...gallery] : [];
    const updatedPhoto = photos ? [...photos] : [];

    imagesArray.forEach((imageSrc, idx) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = imageSrc;

      image.onload = () => {
        const scaleX = image.width / initImageSize[idx].width;
        const scaleY = image.height / initImageSize[idx].height;

        const canvas = document.createElement('canvas');
        canvas.width = crop[idx].width * scaleX;
        canvas.height = crop[idx].height * scaleY;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(
            image,
            crop[idx].x * scaleX,
            crop[idx].y * scaleY,
            crop[idx].width * scaleX,
            crop[idx].height * scaleY,
            0,
            0,
            crop[idx].width * scaleX,
            crop[idx].height * scaleY,
          );
        }

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const file = new File([blob], imageFilename || '', {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              if (isSingleImage) {
                onChange(file);
                if (setImagePreview) {
                  const url = URL.createObjectURL(file);
                  setImagePreview(url);
                }
              } else {
                const url = URL.createObjectURL(file);
                const uuid = uuidv4();
                updatedGallery.push({
                  uuid,
                  imgUrl: url,
                  fileName: uuid,
                  fileExtension: '.jpg',
                });
                updatedPhoto.push({
                  uuid,
                  image: file,
                  fileExtension: '.jpg',
                });

                if (sortImageArray && setGallery && setPhotos) {
                  setPhotos(updatedPhoto);
                  setGallery(updatedGallery);
                  sortImageArray(updatedGallery);
                }
              }
              onClose();
            } else {
              toast.error(
                t('errorMessages.somethingWentWrongDuringLoadingImage', {
                  imageSrc,
                }),
              );
            }
          },
          'image/jpeg',
          0.8,
        );
      };

      image.onerror = () => {
        toast.error(
          t('errorMessages.somethingWentWrongDuringLoadingImage', { imageSrc }),
        );
      };
    });
  };

  const handleChangeCrop = (newCrop: PixelCrop, imageIndex: number) => {
    const updatedCrops = crop.map((cropItem, index) => {
      if (index === imageIndex) {
        return newCrop;
      }
      return cropItem;
    });

    setCrop(updatedCrops);
  };

  const handleSetFull = (init?: boolean, reset?: boolean) => {
    const updatedCrop = [...crop];

    const updatedImageSize = [...initImageSize];

    if (init) {
      if (updatedCrop[imageNumber] && initImageSize[imageNumber]) {
        return;
      }
    }

    if (aspect === '1/1') {
      const value =
        imageRef.current && imageRef.current.width >= imageRef.current.height
          ? imageRef.current?.height
          : imageRef.current?.width;
      updatedCrop[imageNumber] = {
        x: 0,
        y: 0,
        width: value || 0,
        height: value || 0,
        unit: 'px',
      };
    } else if (aspect === '16/9') {
      if (
        imageRef.current &&
        imageRef.current.width > imageRef.current.height
      ) {
        if (imageRef.current.width > imageRef.current.height * (16 / 9)) {
          updatedCrop[imageNumber] = {
            x: 0,
            y: 0,
            width: imageRef.current.height * (16 / 9) || 0,
            height: imageRef.current.height || 0,
            unit: 'px',
          };
        } else {
          updatedCrop[imageNumber] = {
            x: 0,
            y: 0,
            width: imageRef.current.width || 0,
            height: imageRef.current.width * (9 / 16) || 0,
            unit: 'px',
          };
        }
      } else if (
        imageRef.current &&
        imageRef.current.width <= imageRef.current.height
      ) {
        updatedCrop[imageNumber] = {
          x: 0,
          y: 0,
          width: imageRef.current.width || 0,
          height: imageRef.current.width * (9 / 16) || 0,
          unit: 'px',
        };
      }
    } else if (aspect === '9/16') {
      if (
        imageRef.current &&
        imageRef.current.height > imageRef.current.width
      ) {
        if (imageRef.current.height > imageRef.current.width * (16 / 9)) {
          updatedCrop[imageNumber] = {
            x: 0,
            y: 0,
            width: imageRef.current.width || 0,
            height: imageRef.current.width * (16 / 9) || 0,
            unit: 'px',
          };
        } else {
          updatedCrop[imageNumber] = {
            x: 0,
            y: 0,
            width: imageRef.current.height * (9 / 16) || 0,
            height: imageRef.current.height || 0,
            unit: 'px',
          };
        }
      } else if (
        imageRef.current &&
        imageRef.current.width >= imageRef.current.height
      ) {
        updatedCrop[imageNumber] = {
          x: 0,
          y: 0,
          width: imageRef.current.height * (9 / 16) || 0,
          height: imageRef.current.height || 0,
          unit: 'px',
        };
      }
    } else if (aspect === '8/1') {
      if (imageRef.current) {
        updatedCrop[imageNumber] = {
          x: 0,
          y: 0,
          width: imageRef.current.width,
          height: imageRef.current.width / 8,
          unit: 'px',
        };
      }
    }
    if (init || reset) {
      updatedImageSize[imageNumber] = {
        width: imageRef.current?.width || 0,
        height: imageRef.current?.height || 0,
      };

      setInitImageSize(updatedImageSize);
    }

    setCrop(updatedCrop);
  };

  const getAspect = (aspectValue: string) => {
    switch (aspectValue) {
      case '1/1':
        return 1 / 1;
      case '16/9':
        return 16 / 9;
      case '9/16':
        return 9 / 16;
      case '8/1':
        return 8 / 1;
      default:
        return 1 / 1;
    }
  };

  useEffect(() => {
    if (visible) {
      setImageNumber(0);
      setInitImageSize([]);
    }
  }, [visible]);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current) {
        const imgWidth = imageRef.current.clientWidth;
        if (elementWidth !== imgWidth) {
          setElementWidth(imgWidth);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      handleSetFull(false, true);
    }
  }, [elementWidth, imageRef.current]);

  return (
    <Modal
      title={labelText}
      visible={visible}
      onClose={onClose}
      titleBorderDisabled
    >
      <div className="flex w-full flex-col items-center gap-4">
        <ReactCrop
          crop={crop[imageNumber]}
          onChange={(c) => handleChangeCrop(c, imageNumber)}
          ref={cropRef}
          className="max-h-[213px] min-h-[100px] md:max-h-[424px]"
          keepSelection
          aspect={getAspect(aspect)}
        >
          <img
            src={(imagesWorkspace && imagesWorkspace[imageNumber]) || ''}
            alt="workspaceImage"
            onLoad={() => handleSetFull(true)}
            ref={imageRef}
            className="h-full"
          />
        </ReactCrop>
        <div className="flex flex-row gap-6">
          <div className="cursor-pointer" onClick={() => handleSetFull()}>
            <UndoIcon />
          </div>
        </div>
        <div className="flex w-full flex-row">
          <div className="mt-2 flex w-full items-center">
            {imagesWorkspace && imagesWorkspace?.length > 1 && (
              <div className="flex items-center justify-center gap-4">
                <div
                  className={clsx(
                    imageNumber + 1 <= 1 ? 'cursor-default' : 'cursor-pointer',
                  )}
                  onClick={handlePreviousImage}
                >
                  <ArrowLeftIcon isBlocked={imageNumber + 1 <= 1} />
                </div>
                <div className="font-medium">
                  {imageNumber + 1}/{imagesWorkspace?.length.toString()}
                </div>
                <div
                  className={clsx(
                    imagesWorkspace &&
                      imageNumber + 1 >= imagesWorkspace?.length
                      ? 'cursor-default'
                      : 'cursor-pointer',
                  )}
                  onClick={handleNextImage}
                >
                  <ArrowRightIcon
                    isBlocked={
                      imagesWorkspace
                        ? imageNumber + 1 >= imagesWorkspace?.length
                        : false
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-2 flex flex-row gap-4">
            <Button
              type="button"
              text={t('messages.cancel')}
              variant="tertiary"
              onClick={onClose}
            />
            <Button
              type="button"
              text={t('messages.save')}
              variant="secondary"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropModal;
