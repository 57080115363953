interface ArrowLeftProps {
  isBlocked?: boolean;
}

const ArrowLeftIcon = ({ isBlocked }: ArrowLeftProps) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6454 18.5454L15.1822 17.0086L8.15338 9.97975L15.1822 2.9509L13.6454 1.41406L5.07971 9.97975L13.6454 18.5454Z"
        fill={isBlocked ? '#B3ADC1' : 'white'}
      />
    </svg>
  );
};

ArrowLeftIcon.defaultProps = {
  isBlocked: false,
};

export default ArrowLeftIcon;
