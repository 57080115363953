import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../assets/icons/PlusIcon';
import PageEmptyState from '../../UI/molecules/PageEmptyState/PageEmptyState';
import useMobile from '../../../hooks/useIsMobile';
import TableMobile from '../../UI/atoms/TableMobile/TableMobile';
import PaginationSelectorCount from '../../UI/molecules/PaginationSelectorCount/PaginationSelectorCount';
import PaginationButtons from '../../UI/atoms/PaginationButtons/PaginationButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  deleteEventThunk,
  getEventThunk,
  listEventsCountThunk,
  listEventsThunk,
} from '../../../redux/thunks/eventThunk';
import { IDropdownOption } from '../../UI/atoms/MoreOptionsDropdown/MoreOptionsDropdown.types';
import PenIcon from '../../../assets/icons/PenIcon';
import TrashIcon from '../../../assets/icons/TrashIcon';
import TableRowName from '../../UI/atoms/TableRowName/TableRowName';
import TableRowText from '../../UI/atoms/TableRowText/TableRowText';
import MoreOptionsDropdown from '../../UI/atoms/MoreOptionsDropdown/MoreOptionsDropdown';
import { getZonedTimeFromIsoDateAndFormat } from '../../../functions/functions';
import Table from '../../UI/atoms/Table/Table';
import {
  resetDeleteEventStatus,
  setCurrentEvent,
} from '../../../redux/features/eventSlice';
import { reduxStatus } from '../../../constants/constants';
import useModal from '../../../hooks/useModal';
import EventModal from '../../UI/organisms/EventModal/EventModal';
import ConfirmModal from '../../UI/molecules/ConfirmModal/ConfirmModal';
import { TSingleEvent } from '../../../types/Event';

const EventsPage = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [pageLimit, setPageLimit] = useState<number>(10);

  const [sortField, setSortField] = useState<string>('');

  const [sortDirection, setSortDirection] = useState<string>('');

  const user = useAppSelector((state) => state.user.user);

  const total = useAppSelector((state) => state.event.total);

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const listEvents = useAppSelector((state) => state.event.listEvents);

  const eventsListStatus = useAppSelector(
    (state) => state.event.listEventsStatus,
  );

  const deleteEventStatus = useAppSelector(
    (state) => state.event.deleteEventStatus,
  );

  const currentEvent = useAppSelector((state) => state.event.event);

  const loaded = useRef<boolean>(false);

  const isLoading = eventsListStatus === reduxStatus.loading;

  const {
    visible: isEventModalVisible,
    showModal: showEventModal,
    hideModal: hideEventModal,
  } = useModal();

  const {
    visible: isEditEventModalVisible,
    showModal: showEditEventModal,
    hideModal: hideEditEventModal,
  } = useModal();

  const {
    visible: isDeleteEventModalVisible,
    showModal: showDeleteEventModal,
    hideModal: hideDeleteEventModal,
  } = useModal();

  const isEmployee = user?.role === 'EMPLOYEE';

  const handleSetPageLimit = (newLimit: number) => {
    setPageLimit(newLimit);
    setCurrentPage(0);
  };

  const handleCloseEventModal = () => {
    hideDeleteEventModal();
    dispatch(resetDeleteEventStatus());
  };

  const handleDeleteEvent = () => {
    if (!user?.placeId || !currentEvent) return;
    const input = {
      eventId: currentEvent.id,
      placeId: user.placeId,
      oldImage: currentEvent.image,
    };
    dispatch(deleteEventThunk(input));
  };

  const isEmptyState =
    currentPage === 0 &&
    eventsListStatus === reduxStatus.success &&
    isEmpty(listEvents);

  const listEventsData = useMemo(() => {
    if (isEmpty(listEvents)) return;

    const handleEditEvent = (id: string) => {
      if (!user?.placeId) return;

      dispatch(
        getEventThunk({
          eventId: id,
          placeId: user?.placeId,
        }),
      );
      showEditEventModal();
    };

    const handleDeleteModal = (event: TSingleEvent) => {
      dispatch(setCurrentEvent(event));
      showDeleteEventModal();
    };

    return listEvents.slice(0, pageLimit).map((event) => {
      const { id, image, name, startDate, endDate, likesCount } = event;

      const dropdownOptions: IDropdownOption[] = [
        {
          label: t('messages.edit'),
          icon: PenIcon,
          onClick: () => handleEditEvent(id),
        },
        {
          label: t('messages.delete'),
          icon: TrashIcon,
          onClick: () => handleDeleteModal(event),
          isDelete: true,
        },
      ];

      return {
        ...event,
        name: (
          <div className="flex w-full justify-between">
            <TableRowName
              key={event.id}
              img={image}
              text={name}
              isImageHorizontal
            />
            {isMobile && !isEmployee && (
              <MoreOptionsDropdown options={dropdownOptions} />
            )}
          </div>
        ),
        interested: (
          <div className={clsx(isMobile && 'mt-1')}>
            <TableRowText text={likesCount > 0 ? likesCount.toString() : '-'} />
          </div>
        ),
        startDate: (
          <div>
            <TableRowText
              text={
                startDate
                  ? getZonedTimeFromIsoDateAndFormat(startDate, placeTimezone)
                  : '-'
              }
            />
          </div>
        ),
        endDate: (
          <div className="flex items-center justify-between gap-4">
            <TableRowText
              text={
                endDate
                  ? getZonedTimeFromIsoDateAndFormat(endDate, placeTimezone)
                  : '-'
              }
            />
            {!isMobile && !isEmployee && (
              <MoreOptionsDropdown options={dropdownOptions} />
            )}
          </div>
        ),
      };
    });
  }, [
    listEvents,
    pageLimit,
    user?.placeId,
    dispatch,
    showEditEventModal,
    showDeleteEventModal,
    t,
    isMobile,
    isEmployee,
    placeTimezone,
  ]);

  const listEventsSkeleton = useMemo(() => {
    return [...Array(5)].map(() => {
      return {
        name: (
          <div className="flex w-full animate-pulse items-center gap-3 pr-4">
            <span className="h-11 w-[72px] rounded-xs bg-brand-700" />
            <span className="h-6 w-52 rounded-xs bg-brand-700" />
          </div>
        ),
        interested: (
          <span
            className={clsx(
              'flex h-6 w-6 animate-pulse rounded-xs bg-brand-300',
              isMobile && 'mt-1',
            )}
          />
        ),
        startDate: (
          <span className="flex h-6 w-20 animate-pulse rounded-xs bg-brand-300" />
        ),
        endDate: (
          <div className="flex animate-pulse items-center justify-between gap-4">
            <span className="h-6 w-20 rounded-xs bg-brand-300" />
            {!isMobile && <span className="h-6 w-6 rounded-xs bg-brand-700" />}
          </div>
        ),
      };
    });
  }, [isMobile]);

  const columns = useMemo(
    () => [
      {
        Header: t('messages.name'),
        accessor: 'name',
      },
      {
        Header: t('messages.interested'),
        accessor: 'interested',
      },
      {
        Header: t('messages.start'),
        accessor: 'startDate',
      },
      {
        Header: t('messages.end'),
        accessor: 'endDate',
      },
    ],
    [t],
  );

  const nextPage = () => {
    if ((currentPage + 1) * pageLimit < total) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleListingEvents = useCallback(() => {
    if (!user?.placeId) return;

    const offset = currentPage * pageLimit;
    dispatch(
      listEventsThunk({
        placeId: user.placeId,
        limit: pageLimit,
        offset,
        sortBy: sortField === '' ? undefined : sortField,
        order: sortDirection === '' ? undefined : sortDirection,
      }),
    );
  }, [
    currentPage,
    pageLimit,
    dispatch,
    sortDirection,
    sortField,
    user?.placeId,
  ]);

  useEffect(() => {
    dispatch(listEventsCountThunk());
    handleListingEvents();
  }, [dispatch, handleListingEvents]);

  useEffect(() => {
    if (!user?.placeId || !listEvents) return;
    if (deleteEventStatus === reduxStatus.success) {
      if (currentPage > 0 && listEvents.length === 0) {
        setCurrentPage(currentPage - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, deleteEventStatus, listEvents, user?.placeId]);

  useEffect(() => {
    if (loaded.current) {
      return;
    }

    if (eventsListStatus === reduxStatus.success) {
      loaded.current = true;
    }
  }, [eventsListStatus]);

  return (
    <div className="flex h-full w-full flex-col">
      <EventModal
        visible={isEventModalVisible}
        onClose={hideEventModal}
        showDeleteEventModal={showDeleteEventModal}
      />

      <EventModal
        isEdit
        visible={isEditEventModalVisible}
        onClose={hideEditEventModal}
        showDeleteEventModal={showDeleteEventModal}
      />

      <ConfirmModal
        visible={isDeleteEventModalVisible}
        onClose={handleCloseEventModal}
        questionText={t('messages.areYouSureYouWantToDeleteThisItem')}
        confirmText={t('messages.itemDeletedSuccessfully')}
        confirmAction={handleDeleteEvent}
        loadingStatus={deleteEventStatus}
      />

      <div className="flex w-full flex-col gap-6 md:min-h-[42px] md:flex-row md:justify-between">
        <PrimaryHeader text={t('messages.events')} />
        {!isEmptyState && !isLoading && (
          <div className="flex items-center gap-4">
            {!isEmployee && (
              <ButtonWithIcon
                type="button"
                text={t('messages.addEvent')}
                variant="primary"
                icon={PlusIcon}
                onClick={showEventModal}
              />
            )}
          </div>
        )}
      </div>
      {!isLoading && isEmptyState ? (
        <div className="flex h-full w-full items-center justify-center text-center">
          <PageEmptyState
            headerText={t('messages.timeForEventsAtYourPlace')}
            paragraphText={t(
              'messages.eventsAreGreatOpportunityToEngageCustomers',
            )}
            buttonText={t('messages.addEvent')}
            onButtonClick={showEventModal}
            isButtonDisabled={isEmployee}
          />
        </div>
      ) : (
        <>
          <div className="mt-6 flex h-[calc(100%-100px)] w-full overflow-auto pr-2">
            {isMobile ? (
              <TableMobile
                variant="event"
                columns={columns}
                data={isLoading ? listEventsSkeleton : listEventsData}
              />
            ) : (
              <Table
                columns={columns}
                data={isLoading ? listEventsSkeleton : listEventsData}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
                numberOfColumns={columns.length}
              />
            )}
          </div>
          <footer className="flex justify-between border-t border-brand-700 pt-4">
            <PaginationSelectorCount
              total={total}
              pageLimit={pageLimit}
              setPageLimit={handleSetPageLimit}
              currentPage={currentPage}
            />
            <PaginationButtons
              isLoading={eventsListStatus === reduxStatus.loading}
              hasNextPage={(currentPage + 1) * pageLimit < total}
              hasPreviousPage={currentPage > 0}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </footer>
        </>
      )}
    </div>
  );
};

export default EventsPage;
