import { FC } from 'react';

import { IDropdownMenuProps } from './DropdownMenuDefaultList.types';

const DropdownMenuDefaultList: FC<Partial<IDropdownMenuProps>> = ({
  options = [],
  onOptionClick = () => {},
}) => {
  return (
    <div className="custom-scrollbar scrolling flex flex-col gap-4 overflow-auto">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className="flex items-center justify-between gap-2"
          onClick={() => {
            onOptionClick(option.value, option.onClick);
          }}
        >
          <span className="flex min-w-[90px] gap-1">
            <span className="truncate">{option.label}</span>
          </span>
        </button>
      ))}
    </div>
  );
};

export default DropdownMenuDefaultList;
