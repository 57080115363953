import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-GB';
import { difference, isEqual } from 'lodash';
import { validate as uuidValidate } from 'uuid';
import { ArrowRightIcon } from '@mui/x-date-pickers';

import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../assets/icons/PlusIcon';
import AvailabilityTemplateTabs from '../../UI/molecules/AvailabilityTemplateTabs/AvailabilityTemplateTabs';
import { reduxStatus, routes } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { setAddAvailabilityDialog } from '../../../redux/features/scheduleSlice';
import {
  showAddSpecialDayModal,
  showSpecialDayShiftModal,
} from '../../../redux/features/specialDaySlice';
import Button from '../../UI/molecules/Button/Button';
import {
  addTablesThunk,
  createDefaultSpacePlanThunk,
} from '../../../redux/thunks/spacePlanThunk';
import useMobile from '../../../hooks/useIsMobile';
import useModal from '../../../hooks/useModal';
import i18n from '../../../configuration/i18n';
import { importSpecialDayAvailabilitiesThunk } from '../../../redux/thunks/specialDayThunk';
import WarningModal from '../../UI/molecules/WarningModal/WarningModal';

const AvailabilityTemplate = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const isMobile = useMobile();

  const specialDay = useAppSelector((state) => state.specialDay.specialDay);

  const importSpecialDayAvailabilitiesStatus = useAppSelector(
    (state) => state.specialDay.importSpecialDayAvailabilitiesStatus,
  );

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const currentSpacePlan = useAppSelector(
    (state) => state.spacePlan.currentSpacePlan,
  );

  const tables = useAppSelector((state) => state.spacePlan.tables);

  const tablesCopy = useAppSelector((state) => state.spacePlan.tablesCopy);

  const createSpacePlanStatus = useAppSelector(
    (state) => state.spacePlan.createSpacePlanStatus,
  );

  const addTablesStatus = useAppSelector(
    (state) => state.spacePlan.addTablesStatus,
  );

  const {
    visible: isImportSpecialDayScheduleErrorModalVisible,
    showModal: showImportSpecialDayScheduleErrorModal,
    hideModal: hideImportSpecialDayScheduleErrorModal,
  } = useModal();

  const {
    visible: isImportSpecialDayScheduleOpeningHoursErrorModalVisible,
    showModal: showImportSpecialDayScheduleOpeningHoursErrorModal,
    hideModal: hideImportSpecialDayScheduleOpeningHoursErrorModal,
  } = useModal();

  const { specialDayId } = useParams();

  const handleClick = () => {
    if (pathname === routes.availabilitySchedule) {
      dispatch(setAddAvailabilityDialog());
    } else if (pathname === routes.availabilitySpecialDays) {
      dispatch(showAddSpecialDayModal());
    } else if (pathname === routes.availabilitySpacePlan) {
      if (!user?.placeId) return;
      if (currentSpacePlan && !uuidValidate(currentSpacePlan.id)) {
        dispatch(
          createDefaultSpacePlanThunk({
            name: currentSpacePlan.name,
            tables,
          }),
        );
      } else if (currentSpacePlan && uuidValidate(currentSpacePlan.id)) {
        dispatch(
          addTablesThunk({
            spacePlanId: currentSpacePlan.id,
            tables: difference(tables, tablesCopy),
          }),
        );
      }
    } else if (
      pathname === `${routes.availabilitySpecialDaysSchedule}/${specialDay?.id}`
    ) {
      dispatch(showSpecialDayShiftModal());
    }
  };

  const displayButtonText = () => {
    if (pathname === routes.availabilitySchedule) {
      return t('messages.addShift');
    }
    if (pathname === routes.availabilitySpecialDays) {
      if (isMobile) return t('messages.addDay');
      return t('messages.addSpecialDay');
    }
    if (
      pathname === `${routes.availabilitySpecialDaysSchedule}/${specialDay?.id}`
    ) {
      return t('messages.addShift');
    }
    return t('messages.addShift');
  };

  const getFormatedDate = (date: string): string => {
    const locale = i18n.language.substring(0, 2) === 'pl' ? pl : en;

    const parsedDate = parseISO(date);

    const formattedDate = format(parsedDate, 'dd.MM.yyyy', { locale });
    const dayOfWeek = format(parsedDate, 'EEEE', { locale });

    const formatted = `${formattedDate} (${
      dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)
    })`;

    return formatted;
  };

  const handleClickSetDefaults = () => {
    if (!user?.placeId || !specialDay?.id) return;

    dispatch(
      importSpecialDayAvailabilitiesThunk({
        specialDayId: specialDay.id,
        weekDay: specialDay.date,
        errorCallback: {
          showImportSpecialDayScheduleErrorModal,
          showImportSpecialDayScheduleOpeningHoursErrorModal,
        },
      }),
    );
  };

  return (
    <div className="h-full w-full">
      <WarningModal
        type="error"
        visible={isImportSpecialDayScheduleErrorModalVisible}
        onClose={hideImportSpecialDayScheduleErrorModal}
        confirmAction={hideImportSpecialDayScheduleErrorModal}
        warningDescription={t(
          'errorMessages.somethingWentWrongDuringImportSpecialDay',
        )}
        warningTitle={t('messages.warning')}
        confirmText={t('messages.close')}
        actionStatus={null}
      />
      <WarningModal
        type="error"
        visible={isImportSpecialDayScheduleOpeningHoursErrorModalVisible}
        onClose={hideImportSpecialDayScheduleOpeningHoursErrorModal}
        confirmAction={hideImportSpecialDayScheduleOpeningHoursErrorModal}
        warningDescription={t('errorMessages.importSpecialDayScheduleMismatch')}
        warningTitle={t('messages.warning')}
        confirmText={t('messages.close')}
        actionStatus={null}
      />
      {pathname !==
      `${routes.availabilitySpecialDaysSchedule}/${specialDayId}` ? (
        <div className="px-5 py-4 md:px-6 md:py-5 xl:pb-6 xl:pt-10">
          <div className="mb-4 flex h-[42px] flex-wrap items-center justify-between">
            <PrimaryHeader text={t('messages.availability')} />
            <div className="ml-auto max-w-[172px] md:max-w-none">
              {pathname !== routes.availabilitySpacePlan && !isEmployee ? (
                <ButtonWithIcon
                  type="button"
                  text={displayButtonText()}
                  icon={PlusIcon}
                  variant="primary"
                  onClick={handleClick}
                />
              ) : null}
              {pathname === routes.availabilitySpacePlan &&
                (!isEqual(tables, tablesCopy) ? (
                  <Button
                    type="button"
                    text={t('messages.saveChanges')}
                    variant="secondary"
                    onClick={handleClick}
                    isLoading={
                      createSpacePlanStatus === reduxStatus.loading ||
                      addTablesStatus === reduxStatus.loading
                    }
                  />
                ) : null)}
            </div>
          </div>
          <AvailabilityTemplateTabs isEmployee={isEmployee} />
        </div>
      ) : (
        <div className="w-full px-5 pt-4 md:px-6 md:pt-5 xl:px-10 xl:pt-10">
          <div className="flex w-full items-center">
            <span className="text-sm font-medium text-brand-1700">
              {t('messages.availability')}
            </span>
            <span className="xl:mx-4">
              <ArrowRightIcon />
            </span>
            <span
              className="cursor-pointer text-sm font-medium text-brand-1700 hover:text-white"
              onClick={() => navigate(routes.availabilitySpecialDays)}
            >
              {t('messages.specialDays')}
            </span>
            {specialDay && (
              <>
                <span className="xl:mx-4">
                  <ArrowRightIcon />
                </span>
                <span className="text-sm font-medium">{specialDay.name}</span>
              </>
            )}
          </div>
          <div className="mb-4 mt-4 flex w-full flex-row md:mb-6 md:mt-5 xl:mt-6">
            <div className="flex flex-col">
              <span className="text-xl font-semibold md:text-2xl">
                {specialDay && getFormatedDate(specialDay.date)}
              </span>
              <span className="mb-4 mt-2">{specialDay && specialDay.name}</span>
              {isMobile && !isEmployee && (
                <Button
                  type="button"
                  text={t('messages.importFromSchedule')}
                  variant="tertiary"
                  onClick={handleClickSetDefaults}
                  isLoading={
                    importSpecialDayAvailabilitiesStatus === reduxStatus.loading
                  }
                  isDisabled={
                    importSpecialDayAvailabilitiesStatus === reduxStatus.loading
                  }
                />
              )}
            </div>
            {isMobile && !isEmployee ? (
              <div className="ml-auto max-h-[42px] max-w-[44px]">
                <ButtonWithIcon
                  type="button"
                  text=""
                  icon={PlusIcon}
                  variant="primary"
                  onClick={handleClick}
                />
              </div>
            ) : (
              !isEmployee && (
                <div className="ml-auto flex flex-row md:gap-x-4">
                  <Button
                    type="button"
                    text={t('messages.importFromSchedule')}
                    variant="tertiary"
                    onClick={handleClickSetDefaults}
                    isDisabled={specialDay && !specialDay.isOpen}
                    isLoading={
                      importSpecialDayAvailabilitiesStatus ===
                      reduxStatus.loading
                    }
                  />
                  <ButtonWithIcon
                    type="button"
                    text={displayButtonText()}
                    icon={PlusIcon}
                    variant="primary"
                    onClick={handleClick}
                    isDisabled={specialDay && !specialDay.isOpen}
                  />
                </div>
              )
            )}
          </div>
        </div>
      )}
      <div
        className={clsx(
          'h-[calc(100%-132px)] w-full md:h-[calc(100%-140px)] xl:h-[calc(100%-164px)]',
          pathname ===
            `${routes.availabilitySpecialDaysSchedule}/${specialDay?.id}` &&
            'h-[calc(100%-220px)] md:h-[calc(100%-200px)] xl:h-[calc(100%-192px)]',
        )}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default AvailabilityTemplate;
