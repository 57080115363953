import { useState } from 'react';

const useModal = (initialVisible = false) => {
  const [visible, updateVisible] = useState<boolean>(initialVisible);
  const [idForEdit, setIdForEdit] = useState<string>('');

  const handleShowModal = () => {
    updateVisible(true);
  };

  const handleHideModal = () => {
    updateVisible(false);
  };

  return {
    visible,
    showModal: handleShowModal,
    hideModal: handleHideModal,
    setIdForEdit,
    idForEdit,
  };
};

export default useModal;
