import { FC, createElement } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ITabProps } from './Tab.types';
import { routes } from '../../../../constants/constants';

const Tab: FC<ITabProps> = ({
  label,
  icon,
  route,
  isActive,
  isAnyUnseenMessage,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      to={route}
      className={clsx(
        'flex items-center gap-2 whitespace-nowrap border-b-2 px-3 py-2 text-sm md:text-base',
        isActive ? 'border-white' : 'border-brand-50',
      )}
      onClick={onClick}
    >
      {createElement(icon, {
        className: isActive ? 'fill-white' : 'fill-brand-1700',
      })}
      {t(`messages.${label}`)}
      {isAnyUnseenMessage && route === routes.reservationsUnseen && (
        <span className="ml-auto mr-2 h-3 w-3 rounded-full bg-red-700" />
      )}
    </Link>
  );
};

export default Tab;
