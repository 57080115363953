import clsx from 'clsx';

import { ITextAreaInputProps } from './TextAreaInput.types';

const TextAreaInput = ({
  name,
  placeholder,
  value,
  variant,
  onChange,
  isError,
  isDisabled,
  isReadOnly = false,
  maxLength,
  height,
}: ITextAreaInputProps) => {
  return (
    <div
      className={clsx(
        'relative flex flex-col border border-brand-700',
        variant === 'primary' && 'rounded-full px-5 py-2.5 xl:text-lg',
        (variant === 'secondary' || variant === 'flexible') &&
          'rounded-xs px-4 pb-2 pt-3',
        isError && 'border-red-600',
      )}
    >
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        rows={height}
        className={clsx(
          'custom-scrollbar scrolling mb-1 w-full resize-none overflow-auto border-none bg-transparent p-0 pr-1 text-base',
          isDisabled
            ? 'placeholder:text-brand-1200'
            : 'placeholder:text-brand-1700',
        )}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        readOnly={isReadOnly}
        maxLength={maxLength}
        autoComplete="off"
        aria-autocomplete="none"
      />
      {maxLength && (
        <span className="flex h-6 w-full items-center justify-end text-sm text-brand-1700">
          <span>
            {value.length}/{maxLength}
          </span>
        </span>
      )}
    </div>
  );
};

export default TextAreaInput;
