import z from 'zod';

const availabilitySchema = z.object({
  name: z.string(),
});

const availabilitiesSchema = z.object({
  id: z.string().uuid(),
  end: z.string(),
  start: z.string(),
});

export const listSpecialDaysCountResponseValidateSchema = z.number().int();

export const listSpecialDaysResponseValidateSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string(),
    date: z.string(),
    isOpen: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
    open: z.string().nullable(),
    close: z.string().nullable(),
    Availability: z.array(availabilitySchema).optional(),
  }),
);

export const createSpecialDayResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  date: z.string(),
  isOpen: z.boolean(),
  open: z.string().nullable(),
  close: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const listSpecialDaysAvailabilitiesResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  date: z.string(),
  isOpen: z.boolean(),
  Availability: z.array(availabilitiesSchema).optional(),
});

export const createSpecialDayAvailabilityResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  start: z.string(),
  end: z.string(),
  color: z.string(),
});

export const updateSpecialDayAvailabilityResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  start: z.string(),
  end: z.string(),
  color: z.string(),
});

export const importSpecialDayAvailabilitiesResponseValidateSchema = z.array(
  z.object({
    id: z.string().uuid(),
    specialDayId: z.string().uuid(),
    name: z.string(),
    start: z.string(),
    end: z.string(),
    color: z.string(),
  }),
);
