import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';

import { IGetCitiesVariables } from '../../types/Location';
import { functions } from '../../firebase/firebaseConfig';
import { ISearchSuggestion } from '../../components/UI/atoms/LocationList/LocationList.types';
import i18n from '../../configuration/i18n';
import { LocationSchema } from '../../schema/LocationSchemas';
import { postCodeRegex } from '../../constants/regex';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';

export const geoapifyAutocompleteThunk = createAsyncThunk(
  'place/getCities',
  async ({ input, language }: IGetCitiesVariables, { rejectWithValue }) => {
    try {
      const preparedGeoapifyParams = JSON.stringify({
        lang: language,
        bias: `countrycode:${language}`,
      });

      const preparedInputForAxiosParams = {
        input,
        params: preparedGeoapifyParams,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.geoapifyAutocomplete,
        method: 'GET',
        params: preparedInputForAxiosParams,
      });

      const data = await response.data;

      const validatedData = LocationSchema.safeParse(data);

      if (!validatedData.success) {
        toast.error(i18n.t('errorMessages.unknownCommonError'));
        return rejectWithValue(validatedData.error);
      }

      const cities = validatedData.data.features
        .map((item) => {
          if (postCodeRegex.test(item.properties.address_line1 || '')) {
            return null;
          }
          return {
            label: `${item.properties.city}${
              item.properties.street ? ',' : ''
            } ${item.properties.street || ''} ${
              item.properties.housenumber || ''
            }`,
            placeId: item.properties.place_id,
            isCity: item.properties.result_type === 'city',
            areaLevel3: item.properties.city,
            areaLevel2: item.properties.state,
            areaLevel1: item.properties.country,
            value:
              item.properties.result_type === 'city'
                ? item.properties.city
                : `${item.properties.address_line1}, ${item.properties.city}`,
            location: {
              lat: item.properties.lat,
              lon: item.properties.lon,
            },
          };
        })
        .filter(Boolean);

      return cities;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const listSearchSuggestionsThunk = createAsyncThunk(
  'place/getLocations',
  async ({ input, language }: IGetCitiesVariables, { rejectWithValue }) => {
    try {
      const listSearchSuggestions = httpsCallable(
        functions,
        'listSearchSuggestions',
      );
      const response = await listSearchSuggestions({
        input,
        params: { language },
      });
      return response.data as ISearchSuggestion[];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
