import { createSlice } from '@reduxjs/toolkit';

import { IScheduleSliceState } from '../../types/ISchedule';
import {
  createAvailabilityThunk,
  deleteAvailabilityThunk,
  listAvailabilitiesThunk,
  updateAvailabilityThunk,
} from '../thunks/scheduleThunk';
import { daysOfWeek } from '../../constants/constants';
import {
  convertOpeningHoursForSchedule,
  isAvailabilityWithinOpeningHours,
  stringHourToMinutes,
} from '../../functions/functions';
import { IOpeningHoursSchedule } from '../../types/FirstSteps';

const initialState: IScheduleSliceState = {
  availabilityDialogVisible: false,
  currentColor: 'purple',
  currentStart: null,
  currentEnd: null,
  currentDays: [],
  currentAvailability: null,
  availabilities: [],
  collisionReservations: null,
  listAvailabilitiesStatus: null,
  createAvailabilityStatus: null,
  updateAvailabilityStatus: null,
  deleteAvailabilityStatus: null,
  isWithinOpeningHours: true,
  openingHoursSchedule: null,
  noAvailabilityWithinOpeningHoursError: false,
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    clearSchedules: () => initialState,
    setAddAvailabilityDialog: (state) => {
      state.availabilityDialogVisible = true;
    },
    setAvailabilityWithingOpeningHoursError: (state, { payload }) => {
      state.noAvailabilityWithinOpeningHoursError = payload;
    },
    resetAddAvailabilityDialog: (state) => {
      state.availabilityDialogVisible = initialState.availabilityDialogVisible;
    },
    setCurrentColor: (state, { payload }) => {
      state.currentColor = payload;
    },
    resetCurrentColor: (state) => {
      state.currentColor = initialState.currentColor;
    },
    setCurrentStart: (state, { payload }) => {
      state.currentStart = payload;
    },
    resetCurrentStart: (state) => {
      state.currentStart = initialState.currentStart;
    },
    setCurrentEnd: (state, { payload }) => {
      state.currentEnd = payload;
    },
    resetCurrentEnd: (state) => {
      state.currentEnd = initialState.currentEnd;
    },
    setCurrentDays: (state, { payload }) => {
      state.currentDays = payload;
    },
    resetCurrentDay: (state, { payload }) => {
      state.currentDays = state.currentDays.filter((item) => item !== payload);
    },
    resetCurrentDays: (state) => {
      state.currentDays = initialState.currentDays;
    },
    setEditAvailability: (state, { payload }) => {
      state.currentAvailability = payload;
      state.availabilityDialogVisible = true;
    },
    resetEditAvailability: (state) => {
      state.currentAvailability = initialState.currentAvailability;
      state.availabilityDialogVisible = initialState.availabilityDialogVisible;
    },
    resetAvailabilityFormValues: (state) => {
      state.currentColor = initialState.currentColor;
      state.currentStart = initialState.currentStart;
      state.currentEnd = initialState.currentEnd;
      state.currentDays = initialState.currentDays;
    },
    setAndConvertOpeningHoursForSchedule: (state, { payload }) => {
      state.openingHoursSchedule = convertOpeningHoursForSchedule(
        payload.openingHours,
      );
    },
    checkScheduleHours: (state, { payload }) => {
      const { openingHours } = payload;
      const value = daysOfWeek.map((item) => {
        const filteredAvailabilities = state.availabilities.filter(
          (availability) => {
            return availability.weekdays.includes(item.value);
          },
        );

        const isWithinOpeningHours = isAvailabilityWithinOpeningHours(
          filteredAvailabilities,
          item.name,
          openingHours,
        );

        if (!isWithinOpeningHours) {
          return false;
        }
        return true;
      });

      state.isWithinOpeningHours = value.every((element) => element === true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAvailabilitiesThunk.pending, (state) => {
      state.listAvailabilitiesStatus = 'loading';
    });
    builder.addCase(listAvailabilitiesThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.availabilities = payload.availabilities;

      if (payload.openHours) {
        state.openingHoursSchedule = convertOpeningHoursForSchedule(
          payload.openHours,
        );
      }

      state.listAvailabilitiesStatus = 'success';
    });
    builder.addCase(listAvailabilitiesThunk.rejected, (state) => {
      state.listAvailabilitiesStatus = 'failed';
    });

    builder.addCase(createAvailabilityThunk.pending, (state) => {
      state.createAvailabilityStatus = 'loading';
    });
    builder.addCase(createAvailabilityThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.availabilities.push(payload);

      state.createAvailabilityStatus = 'success';
    });
    builder.addCase(createAvailabilityThunk.rejected, (state) => {
      state.createAvailabilityStatus = 'failed';
    });

    builder.addCase(updateAvailabilityThunk.pending, (state) => {
      state.updateAvailabilityStatus = 'loading';
    });

    builder.addCase(updateAvailabilityThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      const { id, name, start, end, color, weekdays } = payload;

      state.availabilities = state.availabilities.map((availability) =>
        availability.id === id
          ? { id, name, start, end, color, weekdays }
          : availability,
      );

      state.updateAvailabilityStatus = 'success';
    });

    builder.addCase(updateAvailabilityThunk.rejected, (state) => {
      state.updateAvailabilityStatus = 'failed';
    });

    builder.addCase(deleteAvailabilityThunk.pending, (state) => {
      state.deleteAvailabilityStatus = 'loading';
    });
    builder.addCase(deleteAvailabilityThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.availabilities = state.availabilities.filter(
        (availability) => availability.id !== payload.id,
      );

      state.deleteAvailabilityStatus = 'success';
    });
    builder.addCase(deleteAvailabilityThunk.rejected, (state) => {
      state.deleteAvailabilityStatus = 'failed';
    });
  },
});

export const {
  clearSchedules,
  setAddAvailabilityDialog,
  resetAddAvailabilityDialog,
  setCurrentColor,
  resetCurrentColor,
  setCurrentStart,
  resetCurrentStart,
  setCurrentEnd,
  resetCurrentEnd,
  setCurrentDays,
  resetCurrentDay,
  resetCurrentDays,
  setEditAvailability,
  resetEditAvailability,
  resetAvailabilityFormValues,
  checkScheduleHours,
  setAndConvertOpeningHoursForSchedule,
  setAvailabilityWithingOpeningHoursError,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
