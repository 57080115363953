import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import WarningIcon from '../../../../assets/icons/WarningIcon';
import Modal from '../../molecules/Modal/Modal';
import TertiaryHeader from '../../atoms/TertiaryHeader/TertiaryHeader';
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from '../../../../constants/constants';
import Button from '../../molecules/Button/Button';
import EmailIcon from '../../../../assets/icons/EmailIcon';
import PhoneIcon from '../../../../assets/icons/PhoneIcon';
import CopyIcon from '../../../../assets/icons/CopyIcon';
import { IContractExpirationNoticeModal } from './ContractExpirationNoticeModal.types';

const ContractExpirationNoticeModal = ({
  confirmAction,
  onCancel,
}: IContractExpirationNoticeModal) => {
  const { t } = useTranslation();

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(SUPPORT_PHONE_NUMBER);
      toast.success(t('messages.supportModule.copySuccess'));
    } catch (error) {
      toast.error(t('messages.supportModule.copyError'));
    }
  };

  return (
    <Modal visible>
      <div className="bg-brand-50">
        <div className="relative flex justify-center pb-4 ">
          <div className="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-full bg-[#C2780380]">
            <WarningIcon />
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <TertiaryHeader text={t('messages.looksLikeYourContractExpired')} />
          <div className="text-center leading-6">
            {t('messages.contactFunzyToRenewYourContract')}
          </div>
        </div>
        <div className="mb-8 mt-8 flex flex-col items-center gap-4 text-xl font-bold">
          <div className="flex items-center">
            <PhoneIcon />
            <span className="ml-2 mr-4">{SUPPORT_PHONE_NUMBER}</span>
            <div className="cursor-pointer" onClick={handleCopyNumber}>
              <CopyIcon fill="#ffffff" />
            </div>
          </div>
          <div className="flex items-center">
            <EmailIcon />
            <span className="ml-2">{SUPPORT_EMAIL}</span>
          </div>
        </div>
        <div className="flex flex-col flex-wrap justify-center gap-4 md:flex-row">
          <Button
            type="button"
            text={t('messages.logout')}
            variant="tertiary"
            onClick={onCancel}
            isFullWidthOnMobile
          />

          <Button
            type="button"
            text={t('messages.extendContract')}
            variant="secondary"
            onClick={confirmAction}
            isFullWidthOnMobile
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContractExpirationNoticeModal;
