import { FC } from 'react';

import SecondaryHeader from '../../atoms/SecondaryHeader/SecondaryHeader';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import { IPageEmptyStateProps } from './PageEmptyState.types';

const PageEmptyState: FC<IPageEmptyStateProps> = ({
  headerText,
  paragraphText,
  buttonText,
  onButtonClick,
  isButtonDisabled,
}) => {
  return (
    <section className="flex h-full w-full flex-col items-center justify-center">
      <SecondaryHeader text={headerText} isLineBreak />
      <p className="max-w-[512px] pb-6 pt-4 text-center text-brand-1700">
        {paragraphText}
      </p>
      <div className="w-fit">
        <ButtonWithIcon
          type="button"
          text={buttonText}
          variant="primary"
          icon={PlusIcon}
          onClick={onButtonClick}
          isDisabled={isButtonDisabled}
        />
      </div>
    </section>
  );
};

export default PageEmptyState;
