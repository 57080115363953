import { SVGProps } from 'react';

const CouponIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.046 17.054c.282 0 .52-.096.714-.287a.957.957 0 00.29-.71.978.978 0 00-.286-.713.957.957 0 00-.71-.29.977.977 0 00-.714.286.957.957 0 00-.29.71c0 .282.095.52.286.713.191.194.428.29.71.29zm0-4.104c.282 0 .52-.095.714-.286a.957.957 0 00.29-.71.978.978 0 00-.286-.714.957.957 0 00-.71-.29.977.977 0 00-.714.286.957.957 0 00-.29.71c0 .282.095.52.286.714.191.193.428.29.71.29zm0-4.104c.282 0 .52-.095.714-.286a.957.957 0 00.29-.71.978.978 0 00-.286-.713.957.957 0 00-.71-.29.977.977 0 00-.714.285.957.957 0 00-.29.71c0 .282.095.52.286.714.191.193.428.29.71.29zM1.7 20.25v-6.346a3.658 3.658 0 001.44-.6c.406-.291.61-.726.61-1.304 0-.578-.204-1.021-.61-1.329a3.46 3.46 0 00-1.44-.625V3.7h20.65v6.346a3.46 3.46 0 00-1.44.625c-.407.308-.61.75-.61 1.329 0 .578.203 1.013.61 1.304.408.29.887.49 1.44.6v6.346H1.7z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default CouponIcon;
