import clsx from 'clsx';

import { IAvailabilityBoxProps } from './AvailabilityBox.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  resetAvailabilityFormValues,
  setEditAvailability,
} from '../../../../redux/features/scheduleSlice';
import { setEditShift } from '../../../../redux/features/specialDaySlice';
import { resetWindowMinimalized } from '../../../../redux/features/settingsSlice';
import { stringHourToMinutes } from '../../../../functions/functions';

const AvailabilityBox = ({
  id,
  color,
  title,
  start,
  end,
  weekdays,
  dayOfWeek,
  isDisabled,
}: IAvailabilityBoxProps) => {
  const dispatch = useAppDispatch();

  const HOUR_BOX_HEIGHT = 42.01;

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const getDateTimeToOffsetTop = (minutes: number) => {
    return (minutes / 60) * HOUR_BOX_HEIGHT;
  };

  const getEventDuration = (beginDate: number, endDate: number) => {
    const difference = endDate - beginDate;
    return (difference / 60) * HOUR_BOX_HEIGHT;
  };

  const handleClick = () => {
    if (isEmployee) return;

    if (weekdays.length === 0) {
      dispatch(
        setEditShift({
          id,
          name: title,
          color,
          start,
          end,
          weekdays,
          dayOfWeek,
        }),
      );
    } else {
      dispatch(resetAvailabilityFormValues());
      dispatch(
        setEditAvailability({ id, name: title, color, start, end, weekdays }),
      );
    }
    dispatch(resetWindowMinimalized());
  };

  return (
    <div
      onClick={handleClick}
      style={{
        top: getDateTimeToOffsetTop(stringHourToMinutes(start)),
        height: getEventDuration(
          stringHourToMinutes(start),
          stringHourToMinutes(end),
        ),
      }}
      className={clsx(
        'absolute z-20 flex w-4/5 cursor-pointer flex-col items-center justify-center overflow-hidden rounded',
        color === 'purple' && !isDisabled && 'bg-purple-800',
        color === 'indigo' && !isDisabled && 'bg-indigo-600',
        color === 'blue' && !isDisabled && 'bg-blue-600',
        color === 'pink' && !isDisabled && 'bg-pink-500',
        color === 'teal' && !isDisabled && 'bg-teal-500',
        color === 'green' && !isDisabled && 'bg-green-500',
        color === 'yellow' && !isDisabled && 'bg-yellow-400',
        color === 'orange' && !isDisabled && 'bg-orange-400',
        color === 'red' && !isDisabled && 'bg-red-600',
        isDisabled && 'pointer-events-none cursor-default bg-brand-300',
      )}
    >
      {getEventDuration(stringHourToMinutes(start), stringHourToMinutes(end)) >
      30 ? (
        <div className="flex flex-col items-center">
          <span className="text-sm font-semibold">{title}</span>
          <span className="text-sm">
            {start} - {end}
          </span>
        </div>
      ) : (
        <div className="flex w-[90%] flex-row items-center">
          <span className="w-[40%] overflow-clip text-ellipsis text-sm font-semibold">
            {title}
          </span>
          <span className="flex h-5 w-[60%] flex-nowrap justify-end text-sm">
            {start} - {end}
          </span>
        </div>
      )}
    </div>
  );
};

export default AvailabilityBox;
