import { SVGProps } from 'react';

const MenuIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.7 18.085v-2.15h18.6v2.15H2.7zm0-5.035V10.9h18.6v2.15H2.7zm0-5.035v-2.15h18.6v2.15H2.7z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default MenuIcon;
