import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import {
  ICreateSpacePlanThunk,
  IDeleteSpacePlanThunk,
  IDeleteTableThunk,
  IUpdateSpacePlanThunk,
  IUpdateTableSpecsThunk,
  IListTablesThunk,
  ICreateDefaultSpacePlanThunk,
  IAddTablesThunk,
  IListSpacePlansThunk,
} from '../../types/SpacePlan';
import {
  AddTablesSchema,
  CreateDefaultSpacePlanSchema,
  CreateSpacePlanSchema,
  DeleteSpacePlanSchema,
  DeleteTableSchema,
  ListSpacePlansSchema,
  ListTablesSchema,
  UpdateSpacePlanSchema,
  UpdateTableSpecsSchema,
} from '../../schema/SpacePlanSchemas';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import { handleForbiddenError } from '../../functions/functions';

export const listSpacePlansThunk = createAsyncThunk(
  'spacePlan/listSpacePlans',
  async ({ callback }: IListSpacePlansThunk, { rejectWithValue }) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.listSpacePlans,
        method: 'GET',
      });

      const validatedData = ListSpacePlansSchema.parse(result);

      if (validatedData.data.length > 0 && callback) {
        callback(validatedData.data[0].id);
      }

      return validatedData.data;
    } catch (error) {
      toast.error(t('errorMessages.errorDurignFetchingSpacePlans'));
      return rejectWithValue(error);
    }
  },
);

export const createSpacePlanThunk = createAsyncThunk(
  'spacePlan/createSpacePlan',
  async ({ name, callback }: ICreateSpacePlanThunk, { rejectWithValue }) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.createSpacePlan,
        method: 'POST',
        data: {
          name,
        },
      });

      const validatedData = CreateSpacePlanSchema.parse(result);

      if (callback) callback();

      return validatedData.data;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringCreatingSpacePlan'),
      );
      return rejectWithValue(error);
    }
  },
);

export const createDefaultSpacePlanThunk = createAsyncThunk(
  'spacePlan/createDefaultSpacePlan',
  async (
    { name, tables, onSuccess }: ICreateDefaultSpacePlanThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.createDefaultSpacePlan,
        method: 'POST',
        data: {
          spacePlanName: name,
          tables,
        },
      });

      const validatedData = CreateDefaultSpacePlanSchema.parse(result);

      if (onSuccess) onSuccess();

      return validatedData.data;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringCreatingSpacePlan'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateSpacePlanThunk = createAsyncThunk(
  'spacePlan/updateSpacePlan',
  async (
    { spacePlanId, name, callback }: IUpdateSpacePlanThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.updateSpacePlan,
        method: 'PATCH',
        data: {
          spacePlanId,
          name,
        },
      });

      const validatedData = UpdateSpacePlanSchema.parse(result);

      if (callback) callback();

      if (validatedData.data) {
        return {
          id: spacePlanId,
          name,
        };
      }
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingSpacePlan'),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteSpacePlanThunk = createAsyncThunk(
  'spacePlan/deleteSpacePlan',
  async (
    { spacePlanId, onSuccess, onError }: IDeleteSpacePlanThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.deleteSpacePlan,
        method: 'DELETE',
        data: {
          spacePlanId,
        },
      });

      const validatedData = DeleteSpacePlanSchema.parse(result);

      if (onSuccess) {
        onSuccess();
      }

      if (validatedData.data) {
        return spacePlanId;
      }
    } catch (error) {
      handleForbiddenError(error);
      if (onError) onError();
      return rejectWithValue(error);
    }
  },
);

export const listTablesThunk = createAsyncThunk(
  'spacePlan/listTables',
  async ({ spacePlanId, callback }: IListTablesThunk, { rejectWithValue }) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.listTables,
        method: 'GET',
        params: {
          spacePlanId,
        },
      });

      const validatedData = ListTablesSchema.parse(result);

      if (callback) callback(validatedData.data);

      return validatedData.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addTablesThunk = createAsyncThunk(
  'spacePlan/addTables',
  async (
    { spacePlanId, tables, onSuccess }: IAddTablesThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.addTables,
        method: 'POST',
        data: {
          spacePlanId,
          tables,
        },
      });

      const validatedData = AddTablesSchema.parse(result);

      if (onSuccess) onSuccess();

      return validatedData.data;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringAddingTables'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateTableSpecsThunk = createAsyncThunk(
  'spacePlan/updateTable',
  async (
    {
      table,
      excludedAvailabilitySeries,
      onSuccess,
      onError,
    }: IUpdateTableSpecsThunk,
    { rejectWithValue },
  ) => {
    const blockedAvailabilitiesIds =
      excludedAvailabilitySeries !== null &&
      excludedAvailabilitySeries.map(
        (availability: { id: string; name: string }) => {
          return availability.id;
        },
      );

    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.updateTable,
        method: 'PATCH',
        data: {
          tableId: table.id,
          table: {
            name: table.name,
            minSeats: table.minSeats,
            maxSeats: table.maxSeats,
            positionX: table.positionX,
            positionY: table.positionY,
            rotate: table.rotate,
            imageSeats: table.imageSeats,
            shape: table.shape,
            isOnlineReservations: table.isOnlineReservations,
          },
          blockedAvailabilities: blockedAvailabilitiesIds || [],
        },
      });

      const validatedData = UpdateTableSpecsSchema.parse(result);

      if (onSuccess) onSuccess();

      validatedData.data.blockedAvailabilities =
        excludedAvailabilitySeries || [];

      return validatedData.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        handleForbiddenError(error);
        if (error.response?.data.errorCode === 'COLLIDING_RESERVATION') {
          if (onError) onError();
        }
      }
      return rejectWithValue(error);
    }
  },
);

export const deleteTableThunk = createAsyncThunk(
  'spacePlan/deleteTable',
  async (
    { tableId, onSuccess, onError }: IDeleteTableThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.deleteTable,
        method: 'DELETE',
        data: {
          tableId,
        },
      });

      const validatedData = DeleteTableSchema.parse(result);

      if (onSuccess) onSuccess();

      if (validatedData.data) {
        return tableId;
      }
    } catch (error) {
      handleForbiddenError(error);
      if (onError) onError();
      return rejectWithValue(error);
    }
  },
);
