const WhiteInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.12435 14.291H10.916V9.166H9.12435V14.291ZM9.99568 7.40639C10.2444 7.40639 10.4541 7.32227 10.6247 7.15404C10.7954 6.98582 10.8807 6.77736 10.8807 6.52866C10.8807 6.27997 10.7966 6.07028 10.6284 5.8996C10.4601 5.72893 10.2517 5.6436 10.003 5.6436C9.75428 5.6436 9.5446 5.72772 9.37393 5.89595C9.20327 6.06419 9.11793 6.27266 9.11793 6.52135C9.11793 6.77004 9.20205 6.97972 9.37029 7.15039C9.53852 7.32106 9.74699 7.40639 9.99568 7.40639ZM10.0007 18.5826C8.81356 18.5826 7.6977 18.3574 6.65314 17.9068C5.60857 17.4563 4.69995 16.8448 3.92727 16.0724C3.15457 15.3001 2.54286 14.3918 2.09212 13.3477C1.64138 12.3035 1.41602 11.1879 1.41602 10.0007C1.41602 8.81 1.6417 7.69079 2.09308 6.6431C2.54445 5.59542 3.15702 4.68407 3.93079 3.90906C4.70456 3.13406 5.61281 2.5248 6.65554 2.08129C7.69824 1.63777 8.81238 1.41602 9.99793 1.41602C11.1885 1.41602 12.3076 1.63768 13.3552 2.08102C14.4028 2.52435 15.3141 3.13345 16.0893 3.90831C16.8645 4.68318 17.4739 5.59438 17.9174 6.64189C18.3609 7.68941 18.5826 8.80866 18.5826 9.99966C18.5826 11.1907 18.361 12.3061 17.9176 13.3459C17.4743 14.3858 16.8653 15.2927 16.0906 16.0668C15.3159 16.8409 14.405 17.4538 13.3577 17.9053C12.3104 18.3569 11.1914 18.5826 10.0007 18.5826Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteInfoIcon;
