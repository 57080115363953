import z from 'zod';

import { OpeningHoursSchema } from './FirstStepsSchemas';

export const TableStatusesSchema = z.object({
  freeTable: z.string(),
  reservationCanceled: z.string(),
  reservationDelayed: z.string(),
  reservationInProgress: z.string(),
  reservationWithSms: z.string(),
  reservationWithoutSms: z.string(),
});

export const OfferCategoriesSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

export const AvailabilitySchema = z.object({
  color: z.string(),
  name: z.string(),
  startHour: z.string(),
  endHour: z.string(),
  weekdays: z.array(z.number()),
});

export const ScheduleSchema = z.array(AvailabilitySchema);

export const PlaceSchema = z.object({
  averageRating: z.number(),
  avgReservationTime: z.number(),
  category: z.string(),
  city: z.string().nullable(),
  street: z.string().nullable(),
  zipCode: z.string().nullable(),
  createdAt: z.string(),
  description: z.string(),
  encodedName: z.string(),
  formattedAddress: z.string(),
  gallery: z.array(z.string()),
  id: z.string(),
  interestedPeople: z.number(),
  isChatAvailable: z.boolean(),
  isGhost: z.boolean(),
  isOfferPublished: z.boolean(),
  isPhoneReservationsOnly: z.boolean(),
  latitude: z.number(),
  locationId: z.string(),
  logo: z.string().nullable(),
  longitude: z.number(),
  maxReservationTime: z.number(),
  minReservationTime: z.number(),
  beforeReservationTime: z.number(),
  name: z.string(),
  numberReviews: z.number(),
  openingHours: OpeningHoursSchema,
  paymentMethods: z.array(z.string()),
  phoneNumber: z.string(),
  priceRange: z.string(),
  slogan: z.string(),
  slotInterval: z.number(),
  specialFeatures: z.array(z.string()),
  subcategoriesIds: z.array(z.string()),
  timezone: z.string(),
  updatedAt: z.string(),
  websiteUrl: z.string(),
  tableStatuses: TableStatusesSchema.optional(),
});

export const GetPlaceBaseSettingsSchema = z.object({
  contractEndDate: z.string().nullable(),
  contractStartDate: z.string().nullable(),
  isActive: z.boolean(),
  isFirstSteps: z.boolean(),
  tableStatuses: TableStatusesSchema.nullable(),
  isSpacePlanPublished: z.boolean(),
  // isPhoneReservationsOnly: z.boolean(),
  timezone: z.string(),
  minReservationTime: z.number(),
  maxReservationTime: z.number(),
  beforeReservationTime: z.number(),
  slotInterval: z.number(),
  isChatAvailable: z.boolean(),
  isSchedule: z.boolean(),
  openingHours: OpeningHoursSchema,
});

export const GetPlaceBaseSettingsResponseSchema = z.object({
  data: GetPlaceBaseSettingsSchema,
  successCode: z.string(),
});

export const GetCurrentSmsSchema = z.object({
  successCode: z.literal('OPERATION_SUCCESS'),
  data: z.object({
    current: z.number(),
    limit: z.number(),
  }),
});
