import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import Modal from '../../molecules/Modal/Modal';
import Input from '../../molecules/Input/Input';
import Button from '../../molecules/Button/Button';
import ButtonWithIcon from '../../molecules/ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import { IOfferModalProps } from './OfferModal.types';
import ImageUpload from '../../molecules/ImageUpload/ImageUpload';
import { TOfferFormValues } from '../../../../types/IOfferFormValues';
import InputDropdown from '../../atoms/InputDropdown/InputDropdown';
import { getOfferCategoryImage } from '../../../../functions/storage';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import { OFFER_CATEGORIES, reduxStatus } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { createOrUpdateOfferThunk } from '../../../../redux/thunks/offerThunk';
import { offerCategoryImageRegex } from '../../../../constants/regex';
import { OfferFormSchema } from '../../../../schema/FormsSchemas';

const OfferModal = ({
  visible,
  onClose,
  isEdit,
  showDeleteOfferModal,
}: IOfferModalProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const offers = useAppSelector((state) => state.offer.offers);

  const offer = useAppSelector((state) => state.offer.offer);

  const updateOffersStatus = useAppSelector(
    (state) => state.offer.updateOffersStatus,
  );

  const isLoading = updateOffersStatus === reduxStatus.loading;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    reset,
  } = useForm<TOfferFormValues>({
    resolver: zodResolver(OfferFormSchema),
  });

  const onSubmit: SubmitHandler<TOfferFormValues> = ({
    image,
    name,
    offerCategory,
  }) => {
    if (isEdit && offer) {
      const newOffer = {
        id: offer.id,
        name,
        offerCategory,
        published: offer.published,
        photo: image,
      };
      const oldOffers = offers || [];

      dispatch(
        createOrUpdateOfferThunk({
          newOffer,
          oldOffers,
          oldPhoto: offer?.photo,
          callback: onClose,
        }),
      );
    } else {
      const newOffer = { name, offerCategory, published: false, photo: image };
      dispatch(
        createOrUpdateOfferThunk({
          newOffer,
          oldOffers: offers || [],
          callback: onClose,
        }),
      );
    }
  };

  const offerCategoryWatch = watch('offerCategory');
  const imageWatch = watch('image');

  useEffect(() => {
    if (isEdit && offer) {
      setValue('name', offer.name);
      if (offer.photo) {
        const match = offer.photo.match(offerCategoryImageRegex);
        if (
          match &&
          match[1] &&
          OFFER_CATEGORIES.includes(
            match[1] as (typeof OFFER_CATEGORIES)[number],
          )
        ) {
          setValue(
            'offerCategory',
            match[1] as (typeof OFFER_CATEGORIES)[number],
          );
          setValue('image', null);
        } else {
          setValue('offerCategory', 'categoryOther');
          setValue('image', getOfferCategoryImage(offer.photo, '800', '800'));
        }
      } else {
        setValue('offerCategory', 'categoryOther');
      }
    }
  }, [visible, isEdit, offer, setValue]);

  useEffect(() => {
    if (!visible) {
      clearErrors();
      reset();
    }
  }, [clearErrors, reset, visible]);

  return (
    <Modal
      title={!isEdit ? t('messages.addOffer') : t('messages.editOffer')}
      visible={visible}
      onClose={onClose}
    >
      <form className="w-full pt-4" onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field: { value, name, onChange } }) => (
              <Input
                name={name}
                type="text"
                variant="secondary"
                labelText={t('messages.name')}
                placeholder={t('messages.typeOfferName')}
                value={value}
                onChange={onChange}
                error={errors.name?.message}
                maxLength={100}
              />
            )}
          />
          <Controller
            control={control}
            name="offerCategory"
            defaultValue={undefined}
            render={({ field: { value, onChange } }) => (
              <div className="flex min-h-[100px] flex-col gap-2">
                <span className="text-sm text-white">
                  {t('messages.offerCategory')}
                </span>
                <InputDropdown
                  placeholder={t('messages.chooseCategory')}
                  value={value}
                  options={OFFER_CATEGORIES.map((cat) => ({
                    label: t(`messages.offerCategories.${cat}`),
                    onClick: () => onChange(cat),
                    value: cat,
                  }))}
                  variant="fixedToTop"
                  initialValueReload
                  isTranslation
                  isDisabled={
                    imageWatch !== null &&
                    offerCategoryWatch === 'categoryOther'
                  }
                />
                {errors.offerCategory?.message && (
                  <span className="text-sm text-red-600">
                    {errors.offerCategory.message}
                  </span>
                )}
              </div>
            )}
          />
          {!imageWatch && (
            <div className="mb-4">
              <div className="h-[75px] w-full overflow-hidden rounded-xs bg-brand-700">
                {offerCategoryWatch && (
                  <img
                    loading="lazy"
                    alt={offerCategoryWatch}
                    className="h-full w-full object-cover"
                    src={
                      new URL(
                        `../../../../assets/images/offerCategories/${offerCategoryWatch}.webp`,
                        import.meta.url,
                      ).href
                    }
                  />
                )}
              </div>
              <span className="text-xs text-brand-1700">
                {t('messages.offerCategoryCover')}
              </span>
            </div>
          )}
          <div
            className={clsx(
              'w-full',
              offerCategoryWatch === 'categoryOther' ? 'flex' : 'hidden',
            )}
          >
            <Controller
              control={control}
              name="image"
              defaultValue={null}
              render={({ field: { value, name, onChange } }) => (
                <ImageUpload
                  name={name}
                  labelText={t('messages.image')}
                  placeholder={t('messages.noFileSelected')}
                  value={value}
                  onChange={onChange}
                  maxFileSize={20}
                  error={errors.image?.message}
                  aspect="8/1"
                  variant="offer"
                />
              )}
            />
          </div>
        </ModalContent>
        <div className="flex flex-wrap justify-end gap-4 pt-2">
          {isEdit ? (
            <ButtonWithIcon
              type="button"
              text={t('messages.delete')}
              variant="secondary"
              icon={TrashIcon}
              onClick={showDeleteOfferModal}
              isDeleteButton
            />
          ) : null}
          <Button
            type="submit"
            text={isEdit ? t('messages.editOffer') : t('messages.addOffer')}
            variant="secondary"
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default OfferModal;
