import z from 'zod';

export const TableShapeSchema = z.union([
  z.literal('RECTANGLE'),
  z.literal('CIRCLE'),
  z.literal('SQUARE'),
]);

export const TableSchema = z.object({
  id: z.string(),
  spacePlanId: z.string(),
  name: z.string(),
  shape: TableShapeSchema,
  positionX: z.number(),
  positionY: z.number(),
  minSeats: z.number(),
  maxSeats: z.number(),
  rotate: z.number(),
  imageSeats: z.number(),
  isOnlineReservations: z.boolean(),
  blockedAvailabilities: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
    )
    .optional(),
});

export const SpacePlanSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const CreateSpacePlanSchema = z.object({
  data: SpacePlanSchema,
  successCode: z.string(),
});

export const CreateDefaultSpacePlanSchema = z.object({
  data: z.object({
    spacePlan: SpacePlanSchema,
    tables: z.array(TableSchema),
  }),
  successCode: z.string(),
});

export const UpdateSpacePlanSchema = z.object({
  data: z.boolean(),
  successCode: z.string(),
});

export const ListSpacePlansSchema = z.object({
  data: z.array(SpacePlanSchema),
  successCode: z.string(),
});

export const AddTablesSchema = z.object({
  data: z.array(TableSchema),
  successCode: z.string(),
});

export const UpdateTableSpecsSchema = z.object({
  data: TableSchema,
  successCode: z.string(),
});

export const DeleteTableSchema = z.object({
  data: z.boolean(),
  successCode: z.string(),
});

export const DeleteSpacePlanSchema = z.object({
  data: z.boolean(),
  successCode: z.string(),
});

export const ListTablesSchema = z.object({
  data: z.array(TableSchema),
  successCode: z.string(),
});
