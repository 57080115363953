import { FC, createElement } from 'react';
import clsx from 'clsx';

import { IButtonWithIconProps } from './ButtonWithIcon.types';
import Spinner from '../../atoms/Spinner/Spinner';

const ButtonWithIcon: FC<IButtonWithIconProps> = ({
  type,
  variant,
  icon,
  text,
  isDeleteButton,
  isJustIconOnMobile,
  isJustIconOnTablet,
  isFullWidthOnMobile,
  isDisabled,
  isLoading,
  isChatIconDisabled,
  isNoHorizontalPadding = false,
  onClick,
}) => {
  return (
    <button
      type={type === 'button' ? 'button' : 'submit'}
      className={clsx(
        'flex h-[42px] min-h-[42px] w-fit select-none items-center justify-center gap-2 rounded-xs md:flex-grow-0',
        isNoHorizontalPadding ? 'md:px-2.5' : 'md:px-5',
        variant === 'primary' && !isDisabled && 'border-2 border-white',
        variant === 'secondary' && 'border-2 border-brand-700',
        variant === 'tertiary' && '!w-full border-2 border-white bg-white',
        variant === 'quaternary' && 'border-2 border-brand-700',
        variant === 'quinary' && 'funzyGradient w-full',
        variant === 'senary' && 'funzyGradient w-full',
        isDeleteButton && 'transition-colors duration-100 hover:border-red-500',
        isJustIconOnMobile
          ? 'flex-0 w-[42px] min-w-[42px] px-0 md:w-fit md:min-w-fit'
          : 'flex-1 px-2.5',
        isJustIconOnTablet &&
          'flex-0 !border-0 px-0 xl:flex-1 xl:!border-2 xl:px-3.5 xl:text-white',
        isFullWidthOnMobile && 'w-full md:w-fit',
        isDisabled &&
          'pointer-events-none cursor-default border-none bg-brand-300',
        isChatIconDisabled &&
          'pointer-events-none cursor-default border-transparent xl:bg-brand-300',
      )}
      onClick={onClick}
    >
      {createElement(icon, {
        className: clsx(
          isDisabled && 'fill-brand-1200',
          isChatIconDisabled && 'text-brand-1200',
        ),
      })}
      {text && (
        <span
          className={clsx(
            'whitespace-nowrap font-medium',
            variant === 'primary' && !isDisabled && 'text-white',
            variant === 'secondary' && 'text-brand-1700',
            variant === 'tertiary' && '!text-black',
            variant === 'quaternary' && 'text-white',
            variant === 'quinary' && 'text-[15px] font-medium md:text-base',
            variant === 'senary' && 'text-xs',
            isJustIconOnMobile ? 'hidden md:block' : 'block',
            isJustIconOnTablet ? 'hidden text-white xl:block' : 'block',
            isDisabled && 'text-brand-1200',
            isChatIconDisabled && '!text-brand-1200',
          )}
        >
          {isLoading ? <Spinner /> : text}
        </span>
      )}
    </button>
  );
};

export default ButtonWithIcon;
