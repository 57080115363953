const ReservationsTabIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.13781 11.8549V10.1306H10.8621V11.8549H9.13781ZM5.80448 11.8549V10.1306H7.52881V11.8549H5.80448ZM12.4711 11.8549V10.1306H14.1955V11.8549H12.4711ZM9.13781 15.1241V13.3998H10.8621V15.1241H9.13781ZM5.80448 15.1241V13.3998H7.52881V15.1241H5.80448ZM12.4711 15.1241V13.3998H14.1955V15.1241H12.4711ZM2.25 18.5408V3.04081H4.78527V1.36133H6.60896V3.04081H13.423V1.36133H15.2147V3.04081H17.75V18.5408H2.25V18.5408ZM4.04165 16.7491H15.9583V8.17225H4.04165V16.7491Z"
        fill="white"
      />
    </svg>
  );
};

export default ReservationsTabIcon;
