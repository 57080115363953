import { SVGProps } from 'react';

const AvailabilityIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.95 18.485L7.246 14.78l1.51-1.51 2.194 2.194 4.344-4.344 1.51 1.51-5.854 5.854zM5.158 22.25a2.37 2.37 0 01-1.742-.716 2.37 2.37 0 01-.716-1.742V6.108c0-.684.239-1.265.716-1.742a2.37 2.37 0 011.742-.716h.584V1.635h2.189V3.65h8.177V1.635h2.15V3.65h.584a2.37 2.37 0 011.742.716 2.37 2.37 0 01.716 1.742v13.684a2.37 2.37 0 01-.716 1.742 2.37 2.37 0 01-1.742.716H5.158zm0-2.15h13.684a.294.294 0 00.212-.096.294.294 0 00.096-.212V9.808H4.85v9.984c0 .077.032.148.096.212a.294.294 0 00.212.096z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default AvailabilityIcon;
