import CalendarDayColumn from '../CalendarDayColumn/CalendarDayColumn';
import CalendarDayHours from '../../atoms/CalendarDayHours/CalendarDayHours';
import { getDaysToDisplayInSchedule } from '../../../../functions/functions';

const CalendarContainer = ({
  daysToShow,
  isForSpecialDay,
}: {
  daysToShow: number | number[];
  isForSpecialDay?: boolean;
}) => {
  const daysToDisplay = getDaysToDisplayInSchedule(daysToShow);

  const getTypeColumn = (index: number) => {
    if (daysToDisplay.length === 1) {
      return 'single';
    }

    if (index % 2 === 0) {
      return 'left';
    }

    return 'right';
  };

  return (
    <div className="min-w-[1200px]">
      <div className="flex w-full">
        <CalendarDayHours />
        <div className="relative flex flex-1 flex-col">
          <div className="flex [&>*:last-child]:border-r-0">
            {daysToDisplay.map((day, index) => {
              return (
                <CalendarDayColumn
                  key={day.value}
                  dayOfWeek={day.value}
                  dayOfWeekName={day.name}
                  isForSpecialDay={isForSpecialDay}
                  typeColumn={getTypeColumn(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarContainer;
