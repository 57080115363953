import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks/useRedux';
import { routes } from '../constants/constants';

const FirstStepsRoute = () => {
  const user = useAppSelector((state) => state.user.user);

  const isPlaceFirstSteps = useAppSelector(
    (state) => state.settings.isPlaceFirstSteps,
  );

  return user && user.firebaseId && user.placeId && isPlaceFirstSteps ? (
    <Outlet />
  ) : (
    <Navigate to={routes.summary} />
  );
};

export default FirstStepsRoute;
