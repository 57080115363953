import { SVGProps } from 'react';

const ChartIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.675 21.075l-.087-1.767 2.487-2.477v4.244h-2.4zm4.25 0v-6.094l2.15-2.15v8.244h-2.15zm4 0v-8.27l2.15 2.2v6.07h-2.15zm4 0v-6.07l2.15-2.15v8.22h-2.15zm4 0V10.981l2.15-2.15v12.244h-2.15zm-16-5.08V12.98L10 5.906l4 4 7.075-7.075v3.013L14 12.92l-4-4-7.075 7.075z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default ChartIcon;
