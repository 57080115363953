import z from 'zod';

export const FeaturePropertiesSchema = z.object({
  address_line1: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  county: z.string().optional(),
  formatted: z.string().optional(),
  housenumber: z.string().optional(),
  lat: z.number(),
  lon: z.number(),
  municipality: z.string().optional(),
  place_id: z.string().optional(),
  region: z.string().optional(),
  result_type: z.string().optional(),
  state: z.string().optional(),
  street: z.string().optional(),
});

const FeatureSchema = z.object({
  type: z.string(),
  properties: FeaturePropertiesSchema,
});

const QuerySchema = z.object({
  text: z.string(),
  parsed: z
    .object({
      expected_type: z.string(),
      postcode: z.string().optional(),
    })
    .optional(),
});

export const LocationSchema = z.object({
  features: z.array(FeatureSchema),
  query: QuerySchema,
  type: z.string(),
});

export const PlaceLocationSchema = z.object({
  coordinates: z.array(z.number()),
  type: z.string(),
});

export const UpdateLocationSchema = z.object({
  city: z.string(),
  formattedAddress: z.string(),
  coords: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
});
