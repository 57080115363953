import { Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks/useRedux';
import { reduxStatus } from '../constants/constants';
import SectionLoader from '../components/UI/atoms/SectionLoader/SectionLoader';

const PermissionRoute = () => {
  const user = useAppSelector((state) => state.user.user);

  const fetchUserStatus = useAppSelector((state) => state.user.fetchUserStatus);

  const isUserLoading = fetchUserStatus === reduxStatus.loading;

  return user === undefined || isUserLoading ? (
    <div className="flex h-screen w-screen items-center justify-center bg-brand-50">
      <SectionLoader />
    </div>
  ) : (
    <Outlet />
  );
};

export default PermissionRoute;
