import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ISideMenuProps } from './SideMenu.types';
import { routes, sideMenuOptions } from '../../../../constants/constants';
import SideMenuLink from '../../molecules/SideMenuLink/SideMenuLink';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { useAppSelector } from '../../../../hooks/useRedux';
import UserInfoBox from '../../atoms/UserInfoBox/UserInfoBox';

const SideMenu: FC<ISideMenuProps> = ({ isOpen, showMenu, hideMenu }) => {
  const { t } = useTranslation();

  const isChatAvailable = useAppSelector(
    (state) => state.settings.isChatAvailable,
  );

  const isAnyMessageUnseen = useAppSelector(
    (state) => state.reservation.isAnyMessageUnseen,
  );

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const isEmployeeOrManager =
    user?.role === 'EMPLOYEE' || user?.role === 'MANAGER';

  const isRedDotVisible = (route: string) => {
    if (route === routes.reservations && isChatAvailable) {
      return isAnyMessageUnseen;
    }
    return false;
  };

  return (
    <nav
      className={clsx(
        'relative hidden w-[60px] max-w-[100vh] flex-col border-r bg-brand-50 duration-300 marker:h-full xl:flex',
        isOpen
          ? 'absolute left-0 top-0 z-10 flex xl:relative xl:w-64 xl:min-w-[256px]'
          : 'xl:min-w-[60px]',
      )}
    >
      <div className="flex h-full flex-col justify-between overflow-y-auto overflow-x-hidden pb-3 pt-4">
        <div>
          <button type="button" onClick={hideMenu} className="pb-4 xl:hidden">
            <CloseIcon />
          </button>
          {sideMenuOptions.map((section, index) => (
            <div
              key={section[0].route}
              className={clsx('py-3', index > 0 && 'border-t border-brand-700')}
            >
              <div className="px-2">
                {section.map((option) => {
                  const availabilityRouteForEmployee =
                    option.route === routes.availabilitySpacePlan && isEmployee
                      ? routes.availabilitySchedule
                      : option.route;

                  const isSettings = option.route === routes.settings;

                  if (
                    !isEmployeeOrManager ||
                    (isEmployeeOrManager && !isSettings)
                  ) {
                    return (
                      <SideMenuLink
                        key={option.route}
                        route={availabilityRouteForEmployee}
                        text={t(`messages.${option.label}`)}
                        icon={option.icon}
                        isMenuOpen={isOpen}
                        showRedDot={isRedDotVisible(option.route)}
                      />
                    );
                  }
                })}
              </div>
            </div>
          ))}
        </div>
        {isOpen && (
          <div className="flex flex-col gap-6 overflow-x-hidden px-3">
            {/* <FunzyProSidebarBox /> */}
            <UserInfoBox />
          </div>
        )}
      </div>
      <button
        type="button"
        className={clsx(
          'absolute right-[-17px] top-[42px] z-20  rounded-full border bg-brand-50 p-1 duration-300',
          isOpen ? '-rotate-90' : 'rotate-90',
        )}
        onClick={isOpen ? hideMenu : showMenu}
      >
        <ArrowIcon />
      </button>
    </nav>
  );
};

export default SideMenu;
