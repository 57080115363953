import { SVGProps } from 'react';

const HolidaysIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.7 22.35v-7.22h18.6v7.22H2.7zm2.65-9.37V7.989h5.55V6.47a2.51 2.51 0 01-.745-.769c-.17-.286-.255-.634-.255-1.045 0-.275.048-.526.143-.753.096-.226.256-.451.48-.675L12 1.75l1.476 1.476c.225.225.385.45.48.677.096.226.144.477.144.752 0 .41-.085.76-.255 1.045a2.51 2.51 0 01-.745.77v1.518h5.55v4.993H5.35z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default HolidaysIcon;
