import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../constants/constants';

const useTablet = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);

  const handleWindowSizeChange = () => {
    setIsTablet(window.innerWidth < BREAKPOINTS.tablet);
  };

  useEffect(() => {
    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isTablet;
};

export default useTablet;
