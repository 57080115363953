const EnglishIcon = () => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={20} height={20} rx={2} fill="#fff" />
      <mask
        id="prefix__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <rect width={20} height={20} rx={2} fill="#fff" />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#0A17A7" d="M0 0h20v20H0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-.916-1.918l8.535 8.06v-7.477h4.762v7.477l8.535-8.06 1.065 2.21-6.748 6.373H20v6.667h-4.767l6.748 6.372-1.065 2.21-8.535-8.059v7.477H7.62v-7.477l-8.535 8.06-1.065-2.21 6.748-6.373H0V6.665h4.767L-1.98.293l1.065-2.211z"
          fill="#fff"
        />
        <path
          d="M13.335 6.332L22.38-2M14.295 13.699l8.11 7.653M5.718 6.31l-8.46-7.982M6.636 13.603l-9.377 8.706"
          stroke="#DB1F35"
          strokeWidth={0.667}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12h8.571v8h2.858v-8H20V8h-8.571V0H8.57v8H0v4z"
          fill="#E6273E"
        />
      </g>
    </svg>
  );
};

export default EnglishIcon;
