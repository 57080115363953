import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import { IListMessagesThunk, ISendMessageThunk } from '../../types/Chat';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import {
  ListMessagesSchema,
  SendMessageSchema,
} from '../../schema/ChatSchemas';

export const listMessagesThunk = createAsyncThunk(
  'message/listMessages',
  async ({ reservationId, limit }: IListMessagesThunk, { rejectWithValue }) => {
    try {
      const params = { reservationId, limit };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.listMessage}`,
        method: 'GET',
        params,
      });

      const validatedResponse = ListMessagesSchema.parse(response);

      const sortedMessages = validatedResponse.data.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt),
      );

      return sortedMessages;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingMessages'));
      return rejectWithValue(error);
    }
  },
);

export const sendMessageThunk = createAsyncThunk(
  'message/sendMessage',
  async (
    { reservationId, message }: ISendMessageThunk,
    { rejectWithValue },
  ) => {
    try {
      const data = { reservationId, message };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.sendMessage}`,
        method: 'POST',
        data,
      });

      const validatedResponse = SendMessageSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringSendingMessage'));
      return rejectWithValue(error);
    }
  },
);
