const FilterColumnIcon = () => {
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99992 18.1952L5.98071 14.1759L7.25953 12.9388L9.99992 15.6792L12.7403 12.9388L14.0191 14.1759L9.99992 18.1952ZM7.25953 7.98684L5.98071 6.74968L9.99992 2.73047L14.0191 6.74968L12.7403 7.98684L9.99992 5.24645L7.25953 7.98684Z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default FilterColumnIcon;
