import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  addDays,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getWeekOfMonth,
  getYear,
  isAfter,
  isBefore,
} from 'date-fns';
import clsx from 'clsx';

import {
  IRecurrenceFormValues,
  IRecurrenceInput,
  IRecurrenceModalProps,
} from './RecurrenceModal.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import InputDate from '../InputDate/InputDate';
import Input from '../Input/Input';
import InputDropdown from '../../atoms/InputDropdown/InputDropdown';
import {
  DAYS_OF_WEEK,
  MONTHLY_RECCURENCE_OPTIONS,
  MONTHS,
  RECURRENCE_REPEAT_OPTIONS,
} from '../../../../constants/constants';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import {
  handleDateFromYearAndMonth,
  handleDayOfMonthChange,
  periodMapping,
} from '../../../../functions/functions';
import InputRadio from '../InputRadio/InputRadio';
import {
  clearRecurrenceData,
  setEventStartDate,
  setRecurrenceData,
} from '../../../../redux/features/eventSlice';

const RecurrenceModal: FC<IRecurrenceModalProps> = ({
  isEdit,
  visible,
  onClose,
  selectedPeriod,
}) => {
  const { t } = useTranslation();

  const { setValue: setContextValue, getValues: getContextValues } =
    useFormContext();

  const dispatch = useAppDispatch();

  const contractStartDate = useAppSelector(
    (state) => state.settings.contractStartDate,
  );
  const contractEndDate = useAppSelector(
    (state) => state.settings.contractEndDate,
  );
  const recurrenceData = useAppSelector((state) => state.event.recurrenceData);
  const singleEvent = useAppSelector((state) => state.event.event);

  const eventStartDate = useAppSelector((state) => state.event.eventStartDate);

  const mapPeriod = (value: string): string => periodMapping[value] || 'day';

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IRecurrenceFormValues>();

  const watchedRecurrenceType = watch('recurrenceType', selectedPeriod);
  const watchedMonthOfYearDay = watch('monthOfYearDay');
  const watchedStartDate = watch('recurrenceStartDate');
  const isDailyInputRadioChecked = watch('dailyInputRadio', 'true') === 'true';
  const recurrenceStartDateFromContext = getContextValues('startDate');

  const today = new Date();

  const minDate: Date =
    contractStartDate && isBefore(today, new Date(contractStartDate))
      ? new Date(contractStartDate)
      : today;

  const maxDate: Date = useMemo(
    () =>
      (contractEndDate && new Date(contractEndDate)) ||
      recurrenceStartDateFromContext,
    [contractEndDate, recurrenceStartDateFromContext],
  );

  const datePickerWatchedStartDate = watchedStartDate;

  const defaultDayOfRecurrenceStartDate = useMemo(() => {
    return recurrenceStartDateFromContext
      ? getDate(new Date(recurrenceStartDateFromContext))
      : null;
  }, [recurrenceStartDateFromContext]);

  const defaultDayOfWeek = useMemo(() => {
    return recurrenceStartDateFromContext
      ? getDay(new Date(recurrenceStartDateFromContext))
      : null;
  }, [recurrenceStartDateFromContext]);

  const defaultMonthOfYear = useMemo(() => {
    return recurrenceStartDateFromContext
      ? getMonth(new Date(recurrenceStartDateFromContext))
      : null;
  }, [recurrenceStartDateFromContext]);

  const defaultWeekOfMonth = useMemo<string | undefined>(() => {
    if (recurrenceStartDateFromContext) {
      const weekOfMonth = getWeekOfMonth(
        new Date(recurrenceStartDateFromContext),
      );
      return (
        MONTHLY_RECCURENCE_OPTIONS.find(
          (option) => option.value === String(weekOfMonth),
        )?.value || undefined
      );
    }
    return undefined;
  }, [recurrenceStartDateFromContext]);

  const buildRecurrenceInput = ({
    recurrenceStartDate,
    interval,
    currentRecurrenceType,
    recurrenceEndDate,
    daysOfWeek,
    dayOfMonth,
    weekOfMonth,
    dayOfWeek,
    monthOfYear,
    dailyInputRadio,
  }: IRecurrenceInput) => {
    const recurrenceInput: IRecurrenceInput = {
      recurrenceType: currentRecurrenceType,
      recurrenceStartDate,
      recurrenceEndDate,
      interval,
    };

    if (currentRecurrenceType === 'weekly') {
      recurrenceInput.daysOfWeek = daysOfWeek;
    }

    if (currentRecurrenceType === 'monthly') {
      if (dailyInputRadio) {
        recurrenceInput.dayOfWeek = dayOfWeek;
        recurrenceInput.weekOfMonth = weekOfMonth;
        recurrenceInput.dayOfMonth = null;
      } else {
        recurrenceInput.dayOfMonth = dayOfMonth;
        recurrenceInput.dayOfWeek = null;
        recurrenceInput.weekOfMonth = null;
      }
    }

    if (currentRecurrenceType === 'yearly') {
      recurrenceInput.monthOfYear = monthOfYear;

      if (dailyInputRadio) {
        recurrenceInput.dayOfMonth = dayOfMonth;
        recurrenceInput.dayOfWeek = null;
        recurrenceInput.weekOfMonth = null;
      } else {
        recurrenceInput.dayOfWeek = dayOfWeek;
        recurrenceInput.weekOfMonth = weekOfMonth;
        recurrenceInput.dayOfMonth = null;
      }
    }

    return recurrenceInput;
  };

  const onSubmit: SubmitHandler<IRecurrenceFormValues> = ({
    recurrenceStartDate,
    interval,
    recurrenceType,
    recurrenceEndDate,
    daysOfWeek,
    weekOfMonthMonthly,
    weekOfMonthYearly,
    dayOfMonthMonthly,
    dayOfMonthYearly,
    dayOfWeekMonthly,
    dayOfWeekYearly,
    monthOfYearDay,
    monthOfYearWeek,
  }) => {
    const currentRecurrenceType =
      recurrenceType === 'custom' ? 'daily' : mapPeriod(recurrenceType);
    const convertedInterval = Number(interval);
    const convertedDaysOfWeek = daysOfWeek?.map((day: string) =>
      DAYS_OF_WEEK.indexOf(day),
    );

    let weekOfMonth = null;
    let dayOfWeek = null;
    let dayOfMonth = null;
    let monthOfYear = null;

    if (currentRecurrenceType === 'monthly') {
      weekOfMonth = weekOfMonthMonthly ? Number(weekOfMonthMonthly) : null;
      dayOfWeek = dayOfWeekMonthly
        ? DAYS_OF_WEEK.indexOf(dayOfWeekMonthly)
        : null;
      dayOfMonth = dayOfMonthMonthly ? Number(dayOfMonthMonthly) : null;
    }

    if (currentRecurrenceType === 'yearly') {
      weekOfMonth = weekOfMonthYearly ? Number(weekOfMonthYearly) : null;
      dayOfWeek = dayOfWeekYearly
        ? DAYS_OF_WEEK.indexOf(dayOfWeekYearly)
        : null;
      dayOfMonth = dayOfMonthYearly ? Number(dayOfMonthYearly) : null;

      if (isDailyInputRadioChecked) {
        monthOfYear = monthOfYearDay
          ? MONTHS.indexOf(monthOfYearDay) + 1
          : null;
      } else {
        monthOfYear = monthOfYearWeek
          ? MONTHS.indexOf(monthOfYearWeek) + 1
          : null;
      }
    }

    const recurrenceInput = buildRecurrenceInput({
      recurrenceStartDate,
      currentRecurrenceType,
      interval: convertedInterval,
      recurrenceEndDate,
      daysOfWeek: convertedDaysOfWeek,
      dayOfMonth,
      weekOfMonth,
      dayOfWeek,
      monthOfYear,
      dailyInputRadio: isDailyInputRadioChecked,
    });

    setContextValue('recurrenceType', currentRecurrenceType);
    dispatch(setEventStartDate(recurrenceStartDate));
    dispatch(setRecurrenceData(recurrenceInput));
    onClose();
  };

  useEffect(() => {
    if (isEdit) return;

    reset();
    clearErrors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, clearErrors, reset, setValue, selectedPeriod]);

  useEffect(() => {
    if (isEdit && singleEvent) {
      reset();
      clearErrors();

      let dailyInputRadio = false;

      if (singleEvent.recurrenceType === 'monthly') {
        dailyInputRadio = !singleEvent.dayOfMonth;
      }

      if (singleEvent.recurrenceType === 'yearly') {
        dailyInputRadio = !!(singleEvent.dayOfMonth && singleEvent.monthOfYear);
      }

      if (!dailyInputRadio) {
        setValue('dailyInputRadio', 'false');
        setValue('everyInputRadio', 'true');
      } else {
        setValue('dailyInputRadio', 'true');
        setValue('everyInputRadio', 'false');
      }

      const selectedRecurrenceType = mapPeriod(selectedPeriod);

      setValue('recurrenceType', selectedRecurrenceType);

      const recurrenceInput = buildRecurrenceInput({
        currentRecurrenceType: singleEvent.recurrenceType,
        recurrenceStartDate: new Date(singleEvent.startDate),
        recurrenceEndDate: singleEvent.endRecurrenceDate
          ? new Date(singleEvent.endRecurrenceDate)
          : null,
        interval: singleEvent.interval,
        daysOfWeek: singleEvent.daysOfWeek,
        dayOfMonth: singleEvent.dayOfMonth,
        weekOfMonth: singleEvent.weekOfMonth,
        dayOfWeek: singleEvent.dayOfWeek,
        monthOfYear: singleEvent.monthOfYear,
        dailyInputRadio,
      });

      if (singleEvent.recurrenceType === selectedRecurrenceType) {
        setValue(
          'recurrenceEndDate',
          singleEvent.endRecurrenceDate
            ? new Date(singleEvent.endRecurrenceDate)
            : null,
        );
        setValue('interval', singleEvent.interval?.toLocaleString());

        if (
          singleEvent.recurrenceType === 'weekly' &&
          Array.isArray(singleEvent.daysOfWeek)
        ) {
          if (selectedPeriod !== 'everyWeekday') {
            const dayStrings = singleEvent.daysOfWeek.map(
              (day: number) => DAYS_OF_WEEK[day],
            );

            setValue('daysOfWeek', dayStrings);
          } else {
            setValue('daysOfWeek', DAYS_OF_WEEK.slice(1, 6));
          }
        }

        const dayOfWeekForm = singleEvent.dayOfWeek;
        const dayOfMonthForm = singleEvent.dayOfMonth?.toString();
        const monthOfYearForm = singleEvent.monthOfYear;

        if (singleEvent.recurrenceType === 'monthly') {
          if (
            singleEvent.dayOfWeek !== null &&
            singleEvent.weekOfMonth !== null
          ) {
            setValue('dayOfWeekMonthly', DAYS_OF_WEEK[Number(dayOfWeekForm)]);
            setValue('weekOfMonthMonthly', String(singleEvent.weekOfMonth));
          } else {
            setValue('dayOfMonthMonthly', dayOfMonthForm);
          }
        }

        if (singleEvent.recurrenceType === 'yearly') {
          if (singleEvent.dayOfMonth !== null) {
            setValue('dayOfMonthYearly', dayOfMonthForm);
            if (monthOfYearForm) {
              setValue('monthOfYearDay', MONTHS[monthOfYearForm - 1]);
            }
          } else {
            setValue('dayOfWeekYearly', DAYS_OF_WEEK[Number(dayOfWeekForm)]);
            setValue('weekOfMonthYearly', String(singleEvent.weekOfMonth));

            if (monthOfYearForm) {
              setValue('monthOfYearWeek', MONTHS[monthOfYearForm - 1]);
            }
          }
        }
      }

      dispatch(setRecurrenceData(recurrenceInput));
    }
  }, [
    dispatch,
    singleEvent,
    selectedPeriod,
    setValue,
    isEdit,
    reset,
    clearErrors,
  ]);

  useEffect(() => {
    if (
      watchedRecurrenceType === 'yearly' &&
      watchedMonthOfYearDay &&
      watchedStartDate
    ) {
      const selectedYear = getYear(new Date(watchedStartDate));

      const selectedMonth = MONTHS.indexOf(watchedMonthOfYearDay);

      const monthIndex = selectedMonth === -1 ? 0 : selectedMonth;

      const maxDaysInMonth = getDaysInMonth(new Date(selectedYear, monthIndex));

      const dayOfMonth = Number(watch('dayOfMonthYearly'));

      if (dayOfMonth && dayOfMonth > maxDaysInMonth) {
        setValue('dayOfMonthYearly', maxDaysInMonth.toString());
      }
    }
  }, [
    setValue,
    watch,
    watchedRecurrenceType,
    watchedMonthOfYearDay,
    watchedStartDate,
  ]);

  return (
    <Modal
      title={t('messages.setRecurrence')}
      visible={visible}
      onClose={onClose}
    >
      <form
        className="flex max-w-[750px] flex-col pt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalContent>
          <div className="flex flex-col gap-2">
            <Controller
              control={control}
              defaultValue={eventStartDate}
              name="recurrenceStartDate"
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <div className="min-h-[100px]">
                  <InputDate
                    labelText={t('messages.startDate')}
                    name="recurrenceStartDate"
                    placeholder={t('messages.chooseDate')}
                    value={value}
                    onChange={onChange}
                    error={errors.recurrenceStartDate?.message}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              )}
            />
            <div className="flex flex-1 gap-2">
              <Controller
                control={control}
                name="interval"
                defaultValue="1"
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.interval')}
                    placeholder={t('messages.interval')}
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, '');
                      onChange(newValue);
                    }}
                    error={errors.interval?.message}
                    maxLength={2}
                  />
                )}
              />

              <Controller
                control={control}
                name="recurrenceType"
                defaultValue={mapPeriod(selectedPeriod)}
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <div className="flex min-h-[100px] flex-1 flex-col justify-center">
                    <div className="mb-0.5 flex flex-col gap-2">
                      <InputDropdown
                        placeholder={t('messages.day')}
                        value={value}
                        options={RECURRENCE_REPEAT_OPTIONS.map((option) => ({
                          label: t(`messages.${option}`),
                          onClick: () => {
                            onChange(option);

                            if (option === 'weekly') {
                              setValue('daysOfWeek', [
                                DAYS_OF_WEEK[getDay(new Date())],
                              ]);
                            }
                          },
                          value: option,
                        }))}
                        error={errors.recurrenceType?.message}
                        variant="modal"
                        isTranslation
                        initialValueReload
                      />
                    </div>
                  </div>
                )}
              />
            </div>

            {(watchedRecurrenceType === 'everyWeekday' ||
              watchedRecurrenceType === 'weekly') && (
              <Controller
                control={control}
                name="daysOfWeek"
                defaultValue={
                  selectedPeriod === 'everyWeekday'
                    ? DAYS_OF_WEEK.slice(1, 6)
                    : [DAYS_OF_WEEK[getDay(new Date())]]
                }
                render={({ field: { value = [], onChange } }) => (
                  <div className="flex flex-wrap gap-2">
                    {[...DAYS_OF_WEEK.slice(1), DAYS_OF_WEEK[0]].map((day) => (
                      <Button
                        key={day}
                        type="button"
                        variant="nonary"
                        text={t(`messages.${day}`)}
                        onClick={() => {
                          if (value.includes(day)) {
                            if (value.length === 1) {
                              return;
                            }

                            onChange(value.filter((d: string) => d !== day));
                          } else {
                            onChange([...value, day]);
                          }
                        }}
                        isSelected={value?.includes(day)}
                      />
                    ))}
                  </div>
                )}
              />
            )}

            {(watchedRecurrenceType === 'monthly' ||
              watchedRecurrenceType === 'yearly') && (
              <div className="flex flex-col">
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dailyInputRadio"
                    defaultValue="true"
                    render={({ field: { value, onChange } }) => (
                      <div className="mr-2.5 w-[120px]">
                        <InputRadio
                          name="dailyInputRadio"
                          labelText=""
                          options={[{ value: 'true', label: 'everySingleDay' }]}
                          value={value || ''}
                          onChange={(newValue) => {
                            onChange(newValue);
                            setValue('everyInputRadio', 'false');
                          }}
                          error={errors.dailyInputRadio?.message}
                        />
                      </div>
                    )}
                  />

                  {watchedRecurrenceType === 'yearly' && (
                    <div className="-mt-2 flex gap-2">
                      <Controller
                        control={control}
                        name="dayOfMonthYearly"
                        defaultValue={defaultDayOfRecurrenceStartDate?.toString()}
                        render={({ field: { value, name, onChange } }) => (
                          <div className="-mt-2 h-0 w-[110px] md:w-[160px]">
                            <Input
                              name={name}
                              type="text"
                              variant="secondary"
                              placeholder="30"
                              labelText=""
                              value={value}
                              onChange={(e) => {
                                const currentRecurrenceStartDate = getValues(
                                  'recurrenceStartDate',
                                );

                                const currentRecurrenceMonthOfYearDay =
                                  getValues('monthOfYearDay');

                                const combinedDate = handleDateFromYearAndMonth(
                                  currentRecurrenceStartDate,
                                  currentRecurrenceMonthOfYearDay,
                                );

                                handleDayOfMonthChange(
                                  e,
                                  onChange,
                                  combinedDate,
                                );
                              }}
                              maxLength={2}
                              isDisabled={!isDailyInputRadioChecked}
                            />
                          </div>
                        )}
                      />
                      <Controller
                        control={control}
                        name="monthOfYearDay"
                        defaultValue={MONTHS[Number(defaultMonthOfYear)]}
                        rules={{
                          required: {
                            value: true,
                            message: t('errorMessages.thisFieldIsRequired'),
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <InputDropdown
                            placeholder={t('messages.month')}
                            value={value}
                            options={MONTHS.map((option) => ({
                              label: t(`messages.${option}`),
                              onClick: () => {
                                onChange(option);
                              },
                              value: option,
                            }))}
                            error={errors.monthOfYearDay?.message}
                            variant="recurrence"
                            isTranslation
                            initialValueReload
                            isDisabled={!isDailyInputRadioChecked}
                          />
                        )}
                      />
                    </div>
                  )}

                  {watchedRecurrenceType === 'monthly' && (
                    <div className="-mt-2 flex gap-2">
                      <Controller
                        control={control}
                        name="weekOfMonthMonthly"
                        defaultValue={defaultWeekOfMonth}
                        rules={{
                          required: {
                            value: true,
                            message: t('errorMessages.thisFieldIsRequired'),
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <InputDropdown
                            placeholder={t('messages.week')}
                            value={value}
                            options={MONTHLY_RECCURENCE_OPTIONS.map(
                              (option) => ({
                                label: t(`messages.${option.label}`),
                                onClick: () => {
                                  onChange(option.value);
                                },
                                value: option.value,
                              }),
                            )}
                            error={errors.weekOfMonthMonthly?.message}
                            variant="recurrence"
                            isTranslation
                            initialValueReload
                            isDisabled={!isDailyInputRadioChecked}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        defaultValue={DAYS_OF_WEEK[Number(defaultDayOfWeek)]}
                        name="dayOfWeekMonthly"
                        render={({ field: { value, onChange } }) => (
                          <div className="h-0 w-[110px] md:w-[160px]">
                            <InputDropdown
                              placeholder={t('messages.day')}
                              value={value}
                              options={DAYS_OF_WEEK.map((option) => ({
                                label: t(`messages.${option}`),
                                onClick: () => {
                                  onChange(option);
                                },
                                value: option,
                              }))}
                              error={errors.dayOfWeekMonthly?.message}
                              variant="recurrence"
                              isTranslation
                              initialValueReload
                              isDisabled={!isDailyInputRadioChecked}
                            />
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className="flex">
                  <Controller
                    control={control}
                    name="everyInputRadio"
                    defaultValue="false"
                    render={({ field: { value, onChange } }) => (
                      <div className="mr-2.5 w-[120px]">
                        <InputRadio
                          name="everyInputRadio"
                          labelText=""
                          options={[{ value: 'true', label: 'every' }]}
                          value={value || ''}
                          onChange={(newValue) => {
                            onChange(newValue);
                            setValue('dailyInputRadio', 'false');
                          }}
                          error={errors.everyInputRadio?.message}
                        />
                      </div>
                    )}
                  />

                  {watchedRecurrenceType === 'yearly' && (
                    <div className="flex gap-2">
                      <Controller
                        control={control}
                        name="weekOfMonthYearly"
                        defaultValue={defaultWeekOfMonth}
                        rules={{
                          required: {
                            value: true,
                            message: t('errorMessages.thisFieldIsRequired'),
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <div className="mt-2 h-0 w-[110px] md:w-[160px]">
                            <InputDropdown
                              placeholder={t('messages.week')}
                              value={value}
                              options={MONTHLY_RECCURENCE_OPTIONS.map(
                                (option) => ({
                                  label: t(`messages.${option.label}`),
                                  onClick: () => {
                                    onChange(option.value);
                                  },
                                  value: option.value,
                                }),
                              )}
                              error={errors.weekOfMonthYearly?.message}
                              variant="recurrence"
                              isTranslation
                              initialValueReload
                              isDisabled={isDailyInputRadioChecked}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        control={control}
                        name="dayOfWeekYearly"
                        defaultValue={DAYS_OF_WEEK[Number(defaultDayOfWeek)]}
                        render={({ field: { value, onChange } }) => (
                          <div className=" mt-2 h-0 w-[110px] md:w-[160px]">
                            <InputDropdown
                              placeholder={t('messages.day')}
                              value={value}
                              options={DAYS_OF_WEEK.map((option) => ({
                                label: t(`messages.${option}`),
                                onClick: () => {
                                  onChange(option);
                                },
                                value: option,
                              }))}
                              error={errors.dayOfWeekYearly?.message}
                              variant="recurrence"
                              isTranslation
                              initialValueReload
                              isDisabled={isDailyInputRadioChecked}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        control={control}
                        name="monthOfYearWeek"
                        defaultValue={MONTHS[Number(defaultMonthOfYear)]}
                        rules={{
                          required: {
                            value: true,
                            message: t('errorMessages.thisFieldIsRequired'),
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <div className="mt-2 h-0 w-[110px] md:w-[160px]">
                            <InputDropdown
                              placeholder={t('messages.month')}
                              value={value}
                              options={MONTHS.map((option) => ({
                                label: t(`messages.${option}`),
                                onClick: () => {
                                  onChange(option);
                                },
                                value: option,
                              }))}
                              error={errors.monthOfYearWeek?.message}
                              variant="recurrence"
                              isTranslation
                              initialValueReload
                              isDisabled={isDailyInputRadioChecked}
                            />
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {watchedRecurrenceType === 'monthly' && (
                    <Controller
                      control={control}
                      name="dayOfMonthMonthly"
                      defaultValue={defaultDayOfRecurrenceStartDate?.toString()}
                      render={({ field: { value, name, onChange } }) => (
                        <div className="h-0 w-[110px] md:w-[160px]">
                          <Input
                            name={name}
                            type="text"
                            variant="secondary"
                            placeholder="30"
                            labelText=""
                            value={value}
                            onChange={(e) => {
                              const currentRecurrenceStartDate = getValues(
                                'recurrenceStartDate',
                              );

                              handleDayOfMonthChange(
                                e,
                                onChange,
                                currentRecurrenceStartDate,
                              );
                            }}
                            maxLength={2}
                            isDisabled={isDailyInputRadioChecked}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            )}

            <div
              className={clsx(
                watchedRecurrenceType === 'everyWeekday' ||
                  watchedRecurrenceType === 'weekly'
                  ? 'pt-6'
                  : 'pt-0',
              )}
            >
              <Controller
                control={control}
                name="recurrenceEndDate"
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <div className="min-h-[100px]">
                    <InputDate
                      name="endDate"
                      labelText={t('messages.endDateNormal')}
                      value={value}
                      onChange={onChange}
                      placeholder={t('messages.typeDate')}
                      error={errors.recurrenceEndDate?.message}
                      minDate={datePickerWatchedStartDate || minDate}
                      maxDate={maxDate}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </ModalContent>

        <div className="flex flex-wrap justify-end gap-4 pt-4">
          <Button
            type="button"
            variant="tertiary"
            text={t('messages.cancel')}
            onClick={onClose}
          />
          <Button type="submit" text={t('messages.save')} variant="secondary" />
        </div>
      </form>
    </Modal>
  );
};

export default RecurrenceModal;
