import PanelIcon from '../../../../assets/icons/PanelIcon';
import { IChatModalTitleTypes } from './ChatModalTitle.types';

const ChatModalTitle = ({ tableName, userFullName }: IChatModalTitleTypes) => {
  return (
    <div className="flex w-full max-w-[calc(100%-24px)] items-center gap-3 pr-2 text-lg font-semibold text-white md:max-w-full md:gap-4 md:pr-0">
      <p className="flex-shrink truncate">{userFullName}</p>
      <div className="flex min-w-0 flex-1 items-center gap-1">
        <PanelIcon />
        <p className="min-w-0 flex-1 truncate">{tableName}</p>
      </div>
    </div>
  );
};

export default ChatModalTitle;
