import clsx from 'clsx';

import { ITableRowNameWithDescriptionProps } from './TableRowNameWithDescription.types';
import ArrowRightIcon from '../../../../assets/icons/ArrowRightIcon';

const TableRowNameWithDescription = ({
  img,
  text,
  description,
  isImageHorizontal,
  onClick,
}: ITableRowNameWithDescriptionProps) => {
  return (
    <div
      className="flex items-center gap-3 truncate"
      onClick={onClick}
      onTouchStart={(event) => event.stopPropagation()}
    >
      {img ? (
        <img
          src={img}
          alt={text}
          loading="lazy"
          className={clsx(
            'h-[42px] rounded-xs object-cover',
            isImageHorizontal
              ? 'w-[72px] min-w-[72px]'
              : 'w-[42px] min-w-[42px]',
          )}
        />
      ) : null}
      <div className="flex flex-col truncate">
        <div className="flex items-center gap-1">
          <span className={clsx('text-base font-semibold')}>{text}</span>
          <span className="scale-75">
            <ArrowRightIcon />
          </span>
        </div>
        <span className="truncate text-sm">{description}</span>
      </div>
    </div>
  );
};

export default TableRowNameWithDescription;
