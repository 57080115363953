import clsx from 'clsx';

import ArrowLeftIcon from '../../../../assets/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/ArrowRightIcon';
import { PaginationButtonsProps } from './PaginationButtons.types';

const PaginationButtons = ({
  isLoading,
  hasNextPage,
  hasPreviousPage,
  nextPage,
  prevPage,
}: PaginationButtonsProps) => {
  const handlePrevPageClick = () => {
    prevPage();
  };

  const handleNextPageClick = () => {
    nextPage();
  };

  return (
    <div className="flex gap-6">
      <button
        type="button"
        onClick={handlePrevPageClick}
        className={clsx(
          (!hasPreviousPage || isLoading) && 'pointer-events-none',
        )}
      >
        <ArrowLeftIcon isBlocked={!hasPreviousPage} />
      </button>
      <button
        type="button"
        onClick={handleNextPageClick}
        className={clsx((!hasNextPage || isLoading) && 'pointer-events-none')}
      >
        <ArrowRightIcon isBlocked={!hasNextPage} />
      </button>
    </div>
  );
};

export default PaginationButtons;
