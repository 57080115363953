import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICoords, ILocationSliceState } from '../../types/Location';
import {
  geoapifyAutocompleteThunk,
  listSearchSuggestionsThunk,
} from '../thunks/locationThunk';
import { getPlaceForSettingsThunk } from '../thunks/settingsThunk';

const initialState: ILocationSliceState = {
  suggestions: [],
  getPlacesStatus: null,
  geoapifyAutocompleteStatus: null,
  listSearchSuggestionsStatus: null,
  coords: { lat: 52.237049, lon: 21.017532 },
  currentIsCity: true,
  currentInternalLocation: null,
  currentPlaceId: null,
  currentCity: null,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    clearLocations: () => initialState,
    clearCities: (state) => {
      state.suggestions = [];
    },
    setIsCity: (state, { payload }: PayloadAction<boolean>) => {
      state.currentIsCity = payload;
    },
    setInternalLocation: (state, { payload }: PayloadAction<ICoords>) => {
      state.currentInternalLocation = payload;
    },
    setPlaceId: (state, { payload }: PayloadAction<string>) => {
      state.currentPlaceId = payload;
    },
    setCurrentCity: (state, { payload }: PayloadAction<string>) => {
      state.currentCity = payload;
    },
  },
  extraReducers: (builder) => {
    // get cities
    builder.addCase(geoapifyAutocompleteThunk.pending, (state) => {
      state.geoapifyAutocompleteStatus = 'loading';
    });
    builder.addCase(
      geoapifyAutocompleteThunk.fulfilled,
      (state, { payload }) => {
        state.geoapifyAutocompleteStatus = 'success';
        if (!payload) return;
        state.suggestions = payload;
      },
    );
    builder.addCase(geoapifyAutocompleteThunk.rejected, (state) => {
      state.geoapifyAutocompleteStatus = 'failed';
    });
    // get locations
    builder.addCase(listSearchSuggestionsThunk.pending, (state) => {
      state.listSearchSuggestionsStatus = 'loading';
    });
    builder.addCase(
      listSearchSuggestionsThunk.fulfilled,
      (state, { payload }) => {
        state.listSearchSuggestionsStatus = 'success';
        if (!payload) return;
        state.suggestions = payload;
      },
    );
    builder.addCase(listSearchSuggestionsThunk.rejected, (state) => {
      state.listSearchSuggestionsStatus = 'failed';
    });
    builder.addCase(
      getPlaceForSettingsThunk.fulfilled,
      (state, { payload }) => {
        const { formattedAddress } = payload;
        const addressParts = formattedAddress.split(',');
        const cityName = addressParts[0].trim();
        state.currentCity = cityName;
      },
    );
  },
});

export const {
  clearLocations,
  clearCities,
  setIsCity,
  setInternalLocation,
  setPlaceId,
  setCurrentCity,
} = locationSlice.actions;

export default locationSlice.reducer;
