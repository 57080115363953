import { useState, useRef, useEffect } from 'react';

import { ITooltipProps } from '../types/ITooltipProps';

const useTooltip = ({
  top,
  left,
  right,
  width,
  disableWidth,
  text = '',
}: ITooltipProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const newWidth = width ? `${width}px` : '200px';

  useEffect(() => {
    const checkTargetElement = () => {
      const targetElement = targetRef.current;
      if (!targetElement) return;

      const handleMouseEnter = () => setIsVisible(true);
      const handleMouseLeave = () => setIsVisible(false);

      targetElement.addEventListener('mouseenter', handleMouseEnter);
      targetElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        targetElement.removeEventListener('mouseenter', handleMouseEnter);
        targetElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    };

    const observer = new MutationObserver(() => {
      checkTargetElement();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    checkTargetElement();

    return () => observer.disconnect();
  }, []);

  const targetRect = targetRef?.current?.getBoundingClientRect();

  const TooltipElement = ({ dynamicText }: { dynamicText?: string }) =>
    isVisible && (dynamicText || text) ? (
      <div
        ref={tooltipRef}
        className="absolute z-50"
        style={{
          top: `${top}px`,
          left: left ? `${left}px` : undefined,
          right: right ? `${right}px` : undefined,
          width: disableWidth ? newWidth : `${targetRect?.width || 0}px`,
        }}
      >
        <div className="rounded-xs border border-brand-700 bg-brand-50 p-[10px] text-base text-white shadow-lg">
          {dynamicText || text}
        </div>
      </div>
    ) : null;

  return { targetRef, TooltipElement };
};

export default useTooltip;
