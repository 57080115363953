import { z } from 'zod';

export const errorSchema = z.object({
  errorCode: z.string(),
  error: z
    .object({
      message: z.string(),
    })
    .optional(),
});
