import { ITableTextProps } from './TableRowText.types';

const TableText = ({ text }: ITableTextProps) => {
  return (
    <div className="block items-center truncate text-xs text-brand-1700 md:text-sm">
      {text}
    </div>
  );
};

export default TableText;
