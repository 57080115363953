const UploadIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.925 20.25H6.4c-1.582 0-2.936-.543-4.062-1.628-1.125-1.085-1.688-2.419-1.688-4a5.84 5.84 0 011.212-3.614c.809-1.07 1.862-1.741 3.161-2.016a7.31 7.31 0 012.63-3.78A7 7 0 0112 3.75c1.892 0 3.528.631 4.907 1.893 1.378 1.262 2.16 2.856 2.343 4.782v.5h.308a4.442 4.442 0 012.724 1.596c.712.866 1.068 1.875 1.068 3.029 0 1.315-.455 2.427-1.364 3.337-.909.909-2.02 1.363-3.336 1.363h-5.575v-7.235l1.6 1.52 1.504-1.495L12 8.887 7.846 13.04l1.504 1.495 1.575-1.52v7.235z"
        fill="#fff"
      />
    </svg>
  );
};

export default UploadIcon;
