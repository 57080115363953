import { SVGProps } from 'react';

const ClockIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.323 16.827l1.504-1.504-3.727-3.777V6.9h-2.15v5.554l4.373 4.373zM12.002 22.3c-1.425 0-2.764-.27-4.017-.811a10.404 10.404 0 01-3.271-2.201 10.398 10.398 0 01-2.203-3.27 10.024 10.024 0 01-.811-4.016c0-1.43.27-2.772.813-4.03a10.437 10.437 0 012.205-3.28 10.224 10.224 0 013.27-2.194 10.14 10.14 0 014.01-.798c1.429 0 2.772.266 4.029.798a10.226 10.226 0 013.281 2.193 10.22 10.22 0 012.194 3.28c.532 1.257.798 2.6.798 4.03 0 1.429-.266 2.767-.798 4.015a10.247 10.247 0 01-2.192 3.265 10.433 10.433 0 01-3.28 2.206c-1.257.542-2.6.813-4.028.813z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default ClockIcon;
