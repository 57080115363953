import { Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAuth, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { isAfter } from 'date-fns';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import {
  TOAST_THREE_SECONDS_DURATION,
  reduxStatus,
  routes,
} from '../constants/constants';
import WarningModal from '../components/UI/molecules/WarningModal/WarningModal';
import { clearUser } from '../redux/features/userSlice';
import { clearSpacePlans } from '../redux/features/spacePlanSlice';
import { clearReservations } from '../redux/features/reservationSlice';
import { clearOffers } from '../redux/features/offerSlice';
import { clearCoupons } from '../redux/features/couponSlice';
import { clearSchedules } from '../redux/features/scheduleSlice';
import { clearChat } from '../redux/features/chatSlice';
import { clearSpecialDays } from '../redux/features/specialDaySlice';
import { clearStatistics } from '../redux/features/statisticsSlice';
import { clearSettings } from '../redux/features/settingsSlice';
import { clearLocations } from '../redux/features/locationSlice';
import { clearEvents } from '../redux/features/eventSlice';
import { clearFirstSteps } from '../redux/features/firstStepsSlice';
import Modal from '../components/UI/molecules/Modal/Modal';
import ContractExpirationNoticeModal from '../components/UI/organisms/ContractExpirationNoticeModal/ContractExpirationNoticeModal';

const PrivateRoute = () => {
  const { t } = useTranslation();

  const auth = getAuth();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);

  const isPlaceFirstSteps = useAppSelector(
    (state) => state.settings.isPlaceFirstSteps,
  );

  const isPlaceActive = useAppSelector((state) => state.settings.isActive);

  const getPlaceFirstStepsStatus = useAppSelector(
    (state) => state.settings.getPlaceFirstStepsStatus,
  );

  const contractEndDate = useAppSelector(
    (state) => state.settings.contractEndDate,
  );

  const today = new Date();

  const clearStore = () => {
    dispatch(clearUser());
    dispatch(clearFirstSteps());
    dispatch(clearSpacePlans());
    dispatch(clearReservations());
    dispatch(clearOffers());
    dispatch(clearCoupons());
    dispatch(clearSchedules());
    dispatch(clearChat());
    dispatch(clearSpecialDays());
    dispatch(clearStatistics());
    dispatch(clearSettings());
    dispatch(clearLocations());
    dispatch(clearEvents());
  };

  const onSignOutClick = () => {
    try {
      clearStore();
      signOut(auth);
    } catch (error) {
      toast(t('errorMessages.logoutError', TOAST_THREE_SECONDS_DURATION));
    }
  };

  const sendMail = () => {
    const recipient = 'kontakt@funzy.app';
    const mailtoLink = `mailto:${recipient}`;
    window.location.href = mailtoLink;
  };

  if (
    getPlaceFirstStepsStatus === reduxStatus.success &&
    ((contractEndDate && isAfter(today, new Date(contractEndDate))) ||
      isPlaceActive === false)
  ) {
    return (
      <ContractExpirationNoticeModal
        confirmAction={sendMail}
        onCancel={onSignOutClick}
      />
    );
  }
  if (
    user &&
    user.firebaseId &&
    user.placeId &&
    !isPlaceFirstSteps &&
    !user.tempPassword
  ) {
    return <Outlet />;
  }
  if (user?.tempPassword) {
    return <Navigate to={routes.newPassword} />;
  }
  if (isPlaceFirstSteps) {
    return <Navigate to={routes.firstSteps} />;
  }
  return <Navigate to={routes.login} />;
};

export default PrivateRoute;
