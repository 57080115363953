import { FC, useRef, useState, useEffect } from 'react';

import { IInfoIconWithTooltipProps } from './InfoIconWithTooltip.types';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import WhiteInfoIcon from '../../../../assets/icons/WhiteInfoIcon';
import InfoIcon from '../../../../assets/icons/InfoIcon';

const InfoIconWithTooltip: FC<IInfoIconWithTooltipProps> = ({
  text,
  color,
  isErrorMessage,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(tooltipRef, () => setIsVisible(false));

  useEffect(() => {
    if (isVisible) {
      const iconRect = iconRef.current?.getBoundingClientRect();
      if (iconRect) {
        const tooltipElement = tooltipRef.current;
        const scrollY = window.scrollY || window.pageYOffset;
        const scrollX = window.scrollX || window.pageXOffset;
        const top = iconRect.bottom + scrollY + 16;
        const left = isErrorMessage
          ? iconRect.left + scrollX
          : iconRect.left + scrollX + iconRect.width / 2;

        if (tooltipElement) {
          tooltipElement.style.top = `${top}px`;
          tooltipElement.style.left = `${left}px`;
          if (isErrorMessage) {
            tooltipElement.style.transform = 'translateX(0%)';
          } else tooltipElement.style.transform = 'translateX(-50%)';
        }
      }
    }
  }, [isErrorMessage, isVisible]);

  const handleIconClick = () => {
    setIsVisible(!isVisible);
  };

  const renderIconColor = () => {
    switch (color) {
      case 'white':
        return <WhiteInfoIcon />;
      default:
        return <InfoIcon />;
    }
  };

  return (
    <>
      <div onClick={handleIconClick} className="cursor-pointer" ref={iconRef}>
        {renderIconColor()}
      </div>
      {isVisible && (
        <div
          ref={tooltipRef}
          className="fixed left-0 top-0 z-50 w-[300px] pr-12 xl:w-auto xl:pr-0"
        >
          <div className="rounded-xs border border-brand-700 bg-brand-50 p-[10px] text-base text-white shadow-lg">
            {text}
          </div>
        </div>
      )}
    </>
  );
};

export default InfoIconWithTooltip;
