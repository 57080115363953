import { SVGProps } from 'react';

const PlusIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.104 10.875H3.917V9.083h5.187V3.896h1.792v5.187h5.187v1.792h-5.187v5.188H9.104v-5.188z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default PlusIcon;
