import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { t } from 'i18next';

import {
  ICreateOrUpdateEventThunk,
  IDeleteEventThunk,
  IGetEventThunk,
  IListEventsThunk,
  TListEvent,
} from '../../types/Event';
import {
  combineDateAndTime,
  handleForbiddenError,
} from '../../functions/functions';
import {
  getBunnyImage,
  getFileCdnFromStorageLink,
  removeImageFromStorage,
  uploadPhoto,
} from '../../functions/storage';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import {
  CreateEventSchema,
  listEventsCountResponseValidateSchema,
  ListEventsSchema,
  SingleEventSchema,
} from '../../schema/EventSchemas';
import { REST_API_URLS } from '../../constants/constants';
import { ImageCompressionError } from '../../types/Storage';

export const listEventsThunk = createAsyncThunk(
  'event/listEvents',
  async (
    {
      placeId,
      limit,
      offset,
      nextId,
      nextName,
      previousId,
      previousName,
      sortBy,
      order,
    }: IListEventsThunk,
    { rejectWithValue },
  ) => {
    try {
      const initialParams = {
        placeId,
        limit,
        offset,
        sortBy: sortBy || 'createdAt',
        order: order || 'desc',
      };

      let params = {};

      if (nextId) {
        params = { nextId, ...initialParams };
      }

      if (previousId) {
        params = { previousId, ...initialParams };
      }

      if (nextName) {
        params = { nextName, nextId, ...initialParams };
      }

      if (previousName) {
        params = { previousName, previousId, ...initialParams };
      }

      const response = await axiosMiddleware({
        method: 'GET',
        url: REST_API_URLS.listEvents,
        params: !isEmpty(params) ? params : initialParams,
      });

      const validatedResponse = ListEventsSchema.parse(response);

      const events = validatedResponse.data.map((event: TListEvent) => ({
        ...event,
        image: getBunnyImage(event.image, '300', '300'),
      }));

      return {
        events,
        total: validatedResponse.total,
      };
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingEvents'));
      return rejectWithValue(error);
    }
  },
);

export const getEventThunk = createAsyncThunk(
  'event/getEvent',
  async ({ placeId, eventId }: IGetEventThunk, { rejectWithValue }) => {
    try {
      const params = {
        placeId,
        eventId,
      };

      const response = await axiosMiddleware({
        method: 'GET',
        url: `${REST_API_URLS.getEvent}`,
        params,
      });

      const validatedResponse = SingleEventSchema.parse(response);
      const image = getBunnyImage(validatedResponse.data.image, '300', '300');

      return { ...validatedResponse.data, image };
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingEvent'));
      return rejectWithValue(error);
    }
  },
);

export const createEventThunk = createAsyncThunk(
  'event/createEventThunk',
  async (
    {
      placeId,
      name,
      description,
      image,
      startDate,
      startTime,
      endDate,
      endTime,
      isRecurring,
      recurrenceType,
      interval,
      endRecurrenceDate,
      daysOfWeek,
      dayOfWeek,
      weekOfMonth,
      dayOfMonth,
      monthOfYear,
      category,
      ticketUrl,
      timezone,
      path,
      callback,
    }: ICreateOrUpdateEventThunk,
    { rejectWithValue },
  ) => {
    try {
      const uploadedImage = await uploadPhoto(image, path);
      const imageObject = {
        photoUrl: uploadedImage.photoUrl,
      };

      const startTimeString = format(startTime, 'HH:mm');
      const endTimeString = endTime ? format(endTime, 'HH:mm') : null;

      const combinedStartDate = combineDateAndTime(
        startDate,
        startTimeString,
        timezone,
      );

      const combinedEndDate =
        endDate && endTimeString
          ? combineDateAndTime(endDate, endTimeString, timezone)
          : null;

      const eventData = {
        name,
        placeId,
        description,
        startDate: combinedStartDate.toISOString(),
        endDate: combinedEndDate?.toISOString(),
        image: imageObject.photoUrl,
        isRecurring,
        recurrenceType,
        interval,
        endRecurrenceDate,
        daysOfWeek,
        dayOfWeek,
        weekOfMonth,
        dayOfMonth,
        monthOfYear,
        category,
        ticketUrl,
      };

      const response = await axiosMiddleware({
        method: 'POST',
        url: REST_API_URLS.createEvent,
        data: eventData,
      });

      const validatedResponse = CreateEventSchema.parse(response);

      if (callback) {
        callback();
      }

      return {
        ...validatedResponse.data,
        image: getBunnyImage(eventData.image, '300', '300'),
      };
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringCreatingEvent'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateEventThunk = createAsyncThunk(
  'event/updateEventThunk',
  async (
    {
      id,
      placeId,
      name,
      description,
      image,
      oldImage,
      startDate,
      startTime,
      endDate,
      endTime,
      isRecurring,
      recurrenceType,
      interval,
      endRecurrenceDate,
      daysOfWeek,
      dayOfWeek,
      weekOfMonth,
      dayOfMonth,
      monthOfYear,
      category,
      ticketUrl,
      timezone,
      path,
      callback,
    }: ICreateOrUpdateEventThunk,
    { rejectWithValue },
  ) => {
    try {
      const imageObject = {
        photoUrl: oldImage,
      };

      if (image instanceof File) {
        removeImageFromStorage(oldImage, 'events');
        const uploadedImage = await uploadPhoto(image, path);
        imageObject.photoUrl = uploadedImage.photoUrl;
      } else {
        imageObject.photoUrl = getFileCdnFromStorageLink(oldImage);
      }

      const startTimeString = format(startTime, 'HH:mm');
      const endTimeString = endTime ? format(endTime, 'HH:mm') : null;

      const combinedStartDate = combineDateAndTime(
        startDate,
        startTimeString,
        timezone,
      );

      const combinedEndDate =
        endDate && endTimeString
          ? combineDateAndTime(endDate, endTimeString, timezone)
          : null;

      const eventData = {
        id,
        name,
        category,
        description,
        startDate: combinedStartDate,
        endDate: combinedEndDate,
        image: imageObject.photoUrl,
        isRecurring,
        recurrenceType,
        interval,
        endRecurrenceDate,
        daysOfWeek,
        dayOfWeek,
        weekOfMonth,
        dayOfMonth,
        monthOfYear,
        placeId,
        ticketUrl,
      };

      const response = await axiosMiddleware({
        method: 'PATCH',
        url: REST_API_URLS.updateEvent,
        data: eventData,
      });

      const validatedResponse = CreateEventSchema.parse(response);

      if (callback) {
        callback();
      }

      return {
        ...validatedResponse.data,
        image: getBunnyImage(eventData.image ?? '', '300', '300'),
      };
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingEvent'),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteEventThunk = createAsyncThunk(
  'event/deleteEventThunk',
  async (
    { eventId, placeId, oldImage }: IDeleteEventThunk,
    { rejectWithValue },
  ) => {
    try {
      removeImageFromStorage(oldImage, 'events');
      const response = await axiosMiddleware({
        method: 'DELETE',
        url: REST_API_URLS.deleteEvent,
        data: {
          eventId,
          placeId,
        },
      });

      if (response.data) {
        return eventId;
      }
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringDeletingEvent'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listEventsCountThunk = createAsyncThunk(
  'event/listEventsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listEventsCount,
        method: 'GET',
      });

      listEventsCountResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
