import clsx from 'clsx';

import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { ICollapseDateButtonProps } from './CollapseDateButton.types';

const CollapseDateButton = ({
  handleClick,
  text,
  showPicker,
  subText,
}: ICollapseDateButtonProps) => {
  return (
    <button
      type="button"
      className="flex h-[45px] w-full items-center justify-between gap-2 rounded-xs border border-brand-700 bg-transparent px-3 py-3 text-base outline-none"
      onClick={handleClick}
    >
      <div className="flex gap-2">
        <span className="scale-[0.84]">
          <CalendarIcon className=" fill-white" />
        </span>
        {subText && <span className="text-brand-1700">{subText}</span>}
        <span>{text}</span>
      </div>
      <span
        className={clsx(
          ' duration-100 ease-in-out',
          showPicker ? 'rotate-0' : 'rotate-180',
        )}
      >
        <ArrowIcon />
      </span>
    </button>
  );
};

export default CollapseDateButton;
