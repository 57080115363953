import clsx from 'clsx';

import MinusIcon from '../../../../assets/icons/MinusIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import { IWorkspaceIconBoxProps } from './WorkspaceIconBox.types';

const WorkspaceIconBox = ({
  color,
  handleClick,
  currentDays,
  dayOfWeek,
}: IWorkspaceIconBoxProps) => {
  return (
    <div
      onClick={handleClick}
      className={clsx(
        'flex h-9 w-9 cursor-pointer items-center justify-center rounded',
        color === 'purple' && ' bg-purple-200',
        color === 'indigo' && ' bg-indigo-200',
        color === 'blue' && ' bg-blue-200',
        color === 'pink' && ' bg-pink-200',
        color === 'teal' && ' bg-teal-200',
        color === 'green' && ' bg-green-200',
        color === 'yellow' && ' bg-yellow-200',
        color === 'orange' && ' bg-orange-200',
        color === 'red' && ' bg-red-200',
      )}
    >
      {currentDays.includes(dayOfWeek) ? (
        <MinusIcon
          className={clsx(
            color === 'purple' && 'fill-purple-700',
            color === 'indigo' && 'fill-indigo-600',
            color === 'blue' && 'fill-blue-700',
            color === 'pink' && 'fill-pink-600',
            color === 'teal' && 'fill-teal-500',
            color === 'green' && 'fill-green-600',
            color === 'yellow' && 'fill-yellow-500',
            color === 'orange' && 'fill-orange-600',
            color === 'red' && 'fill-red-600',
          )}
        />
      ) : (
        <PlusIcon
          className={clsx(
            color === 'purple' && 'fill-purple-700',
            color === 'indigo' && 'fill-indigo-600',
            color === 'blue' && 'fill-blue-700',
            color === 'pink' && 'fill-pink-600',
            color === 'teal' && 'fill-teal-500',
            color === 'green' && 'fill-green-600',
            color === 'yellow' && 'fill-yellow-500',
            color === 'orange' && 'fill-orange-600',
            color === 'red' && 'fill-red-600',
          )}
        />
      )}
    </div>
  );
};

export default WorkspaceIconBox;
