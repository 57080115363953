import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  IChangeOrderOfOfferCategories,
  IChangeOrderOfOffers,
  IChangeOrderOfProductsThunk,
  ICreateOfferProductThunk,
  IDeleteOfferProductThunk,
  IListOfferThunk,
  IListProductsThunk,
  IOfferProduct,
  ISetOfferStatusThunk,
  IUpdateOfferCategoriesThunk,
  IUpdateOfferProductThunk,
  IUpdateOffersThunk,
} from '../../types/Offer';
import { REST_API_URLS } from '../../constants/constants';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import {
  ChangeOrderOfProductsResponseSchema,
  CreateProductSchema,
  GetOfferBasicInformationResponseSchema,
  ListOfferCategoriesSchema,
  ListOffersResponseSchema,
  ListProductsSchema,
  UpdateOfferCategoriesResponseSchema,
  UpdateOfferResponseSchema,
  UpdateProductSchema,
} from '../../schema/MenuSchemas';
import {
  getBunnyImage,
  getFileCdnFromStorageLink,
  removeImageFromStorage,
  uploadPhoto,
} from '../../functions/storage';
import { ImageCompressionError } from '../../types/Storage';
import { handleForbiddenError } from '../../functions/functions';
import { offerCategoryImageRegex } from '../../constants/regex';

export const createOrUpdateOfferThunk = createAsyncThunk(
  'offer/createOrUpdateOfferThunk',
  async (
    { newOffer, oldOffers, oldPhoto, callback }: IUpdateOffersThunk,
    { rejectWithValue },
  ) => {
    try {
      let photo: string | undefined;
      if (
        newOffer.offerCategory === 'categoryOther' &&
        typeof newOffer.photo === 'string' &&
        !newOffer.photo.startsWith('defaultImage/')
      ) {
        photo = getFileCdnFromStorageLink(newOffer.photo);
      } else if (newOffer.offerCategory && !(newOffer.photo instanceof File)) {
        photo = `defaultImage/${newOffer.offerCategory}`;
      } else if (newOffer.photo instanceof File) {
        const uploadedImage = await uploadPhoto(
          newOffer.photo,
          'offerCategories',
        );
        photo = uploadedImage.photoUrl;
      }

      if (oldPhoto) {
        const match = oldPhoto.match(offerCategoryImageRegex);
        if (!match) {
          removeImageFromStorage(oldPhoto, 'offerCategories');
        }
      }

      let formattedData;

      if (newOffer.id) {
        formattedData = oldOffers.map((offer) => {
          if (offer.id === newOffer.id) {
            return {
              id: newOffer.id,
              name: newOffer.name,
              photo,
              published: newOffer.published,
            };
          }
          return offer;
        });
      } else {
        const newOfferWithId = {
          id: uuidv4(),
          name: newOffer.name,
          photo,
          published: newOffer.published,
        };

        formattedData = [newOfferWithId, ...oldOffers];
      }

      const data = { offers: formattedData };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOffers,
        method: 'PATCH',
        data,
      });

      UpdateOfferResponseSchema.parse(response);

      callback();

      return formattedData;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const changeOrderOfOffersThunk = createAsyncThunk(
  'offer/changeOrderOfOffersThunk',
  async ({ offers }: IChangeOrderOfOffers, { rejectWithValue }) => {
    try {
      const data = { offers };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOffers,
        method: 'PATCH',
        data,
      });

      UpdateOfferResponseSchema.parse(response);

      return offers;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteOfferThunk = createAsyncThunk(
  'offer/deleteOfferThunk',
  async ({ offers, callback }: IChangeOrderOfOffers, { rejectWithValue }) => {
    try {
      const data = { offers };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOffers,
        method: 'PATCH',
        data,
      });

      UpdateOfferResponseSchema.parse(response);

      if (callback) callback();

      return offers;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringDeletingOffer'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listOffersThunk = createAsyncThunk(
  'offer/listOffersThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listOffersAdmin,
        method: 'GET',
      });

      const validatedResponse = ListOffersResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingOfferCategories'),
      );
      return rejectWithValue(error);
    }
  },
);

export const createOrUpdateOfferCategoriesThunk = createAsyncThunk(
  'offer/createOrUpdateOfferCategoriesThunk',
  async (
    {
      offerId,
      newOfferCategory,
      oldOfferCategories,
      callback,
    }: IUpdateOfferCategoriesThunk,
    { rejectWithValue },
  ) => {
    try {
      let formattedData;

      if (newOfferCategory.id) {
        formattedData = oldOfferCategories.map((offer) => {
          if (offer.id === newOfferCategory.id) {
            return {
              id: newOfferCategory.id,
              name: newOfferCategory.name,
              totalProducts: offer.totalProducts,
            };
          }
          return offer;
        });
      } else {
        const newOfferWithId = {
          id: uuidv4(),
          name: newOfferCategory.name,
          totalProducts: 0,
        };

        formattedData = [...oldOfferCategories, newOfferWithId];
      }

      const data = { offerId, categories: formattedData };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOfferCategories,
        method: 'PATCH',
        data,
      });

      UpdateOfferCategoriesResponseSchema.parse(response);

      callback();

      return formattedData;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const changeOrderOfOfferCategoriesThunk = createAsyncThunk(
  'offer/changeOrderOfOfferCategoriesThunk',
  async (
    { offerId, offerCategories, callback }: IChangeOrderOfOfferCategories,
    { rejectWithValue },
  ) => {
    try {
      const data = { offerId, categories: offerCategories };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOfferCategories,
        method: 'PATCH',
        data,
      });

      UpdateOfferCategoriesResponseSchema.parse(response);

      if (callback) callback();

      return offerCategories;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteOfferCategoryThunk = createAsyncThunk(
  'offer/deleteOfferCategoryThunk',
  async (
    { offerId, offerCategories, callback }: IChangeOrderOfOfferCategories,
    { rejectWithValue },
  ) => {
    try {
      const data = { offerId, categories: offerCategories };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateOfferCategories,
        method: 'PATCH',
        data,
      });

      UpdateOfferCategoriesResponseSchema.parse(response);

      if (callback) callback();

      return offerCategories;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringDeletingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listOfferCategoriesThunk = createAsyncThunk(
  'offer/listOffer',
  async ({ offerId }: IListOfferThunk, { rejectWithValue }) => {
    try {
      const params = { offerId };

      const response = await axiosMiddleware({
        url: REST_API_URLS.listOfferCategory,
        method: 'GET',
        params,
      });

      const validatedResponse = ListOfferCategoriesSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingOfferCategories'),
      );
      return rejectWithValue(error);
    }
  },
);

export const getOfferBasicInformationThunk = createAsyncThunk(
  'offer/getOfferBasicInformationThunk',
  async ({ offerId }: IListOfferThunk, { rejectWithValue }) => {
    try {
      const params = { offerId };

      const response = await axiosMiddleware({
        url: REST_API_URLS.getOffer,
        method: 'GET',
        params,
      });

      const validatedResponse =
        GetOfferBasicInformationResponseSchema.parse(response);

      return validatedResponse.data.offer;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringUpdatingOfferCategory'),
      );
      return rejectWithValue(error);
    }
  },
);

export const setOfferStatusThunk = createAsyncThunk(
  'offer/setOfferStatus',
  async ({ offer }: ISetOfferStatusThunk, { rejectWithValue }) => {
    try {
      const data = offer;

      await axiosMiddleware({
        url: REST_API_URLS.updateOffer,
        method: 'PATCH',
        data,
      });

      return offer.published;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringSettingOfferStatus'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listProductsThunk = createAsyncThunk(
  'offer/listOfferProducts',
  async (
    { placeId, offerCategoryId }: IListProductsThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = { placeId, offerCategoryId };

      const response = await axiosMiddleware({
        url: REST_API_URLS.listProducts,
        method: 'GET',
        params,
      });

      const validatedResponse = ListProductsSchema.parse(response);

      const products = validatedResponse.data.map((product: IOfferProduct) => ({
        ...product,
        image: getBunnyImage(product.image, '300', '300'),
      }));

      return products;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingOfferProducts'),
      );
      return rejectWithValue(error);
    }
  },
);

export const changeOrderOfProductsThunk = createAsyncThunk(
  'offer/changeOrderOfProductsThunk',
  async (
    { categoryId, productOrder }: IChangeOrderOfProductsThunk,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        categoryId,
        productOrder: productOrder.map((product) => product.id),
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateProductOrder,
        method: 'PUT',
        data,
      });

      ChangeOrderOfProductsResponseSchema.parse(response);

      return productOrder;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingOfferProducts'),
      );
      return rejectWithValue(error);
    }
  },
);

export const createOfferProductThunk = createAsyncThunk(
  'offer/createOfferProduct',
  async (
    {
      placeId,
      offerCategoryId,
      name,
      image,
      price,
      fileExtension,
      description,
      callback,
      path,
    }: ICreateOfferProductThunk,
    { rejectWithValue },
  ) => {
    try {
      const uploadedImage = await uploadPhoto(image, path);
      const imageObject = {
        photoUrl: uploadedImage.photoUrl,
      };

      const data = {
        placeId,
        offerCategoryId,
        name,
        image: imageObject.photoUrl,
        price,
        fileExtension,
        description,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createProduct,
        method: 'POST',
        data,
      });

      const validatedResponse = CreateProductSchema.parse(response);

      callback();

      return {
        ...validatedResponse.data,
        image: getBunnyImage(data.image, '300', '300'),
      };
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringCreatingOfferProduct'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateOfferProductThunk = createAsyncThunk(
  'offer/updateOfferProduct',
  async (
    {
      id,
      placeId,
      description,
      fileExtension,
      image,
      name,
      offerCategoryId,
      price,
      callback,
      path,
      oldImage,
    }: IUpdateOfferProductThunk,
    { rejectWithValue },
  ) => {
    try {
      const imageObject = {
        photoUrl: oldImage,
      };

      if (image instanceof File) {
        removeImageFromStorage(oldImage, 'products');
        const uploadedImage = await uploadPhoto(image, path);
        imageObject.photoUrl = uploadedImage.photoUrl;
      } else {
        imageObject.photoUrl = getFileCdnFromStorageLink(oldImage);
      }

      const data = {
        id,
        placeId,
        description,
        fileExtension,
        image: imageObject.photoUrl,
        name,
        offerCategoryId,
        price,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateProduct,
        method: 'PATCH',
        data,
      });

      callback();

      const validatedResponse = UpdateProductSchema.parse(response);

      if (validatedResponse.data) {
        return {
          ...data,
          image: getBunnyImage(data.image ?? '', '300', '300'),
        };
      }
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringUpdatingOfferProduct'),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteOfferProductThunk = createAsyncThunk(
  'offer/deleteOfferProduct',
  async (
    { id, categoryId, placeId, oldImage, callback }: IDeleteOfferProductThunk,
    { rejectWithValue },
  ) => {
    try {
      const data = { id, placeId };

      removeImageFromStorage(oldImage, 'products');

      const response = await axiosMiddleware({
        method: 'DELETE',
        url: REST_API_URLS.deleteProduct,
        data,
      });

      callback();

      if (response.data) {
        return { productId: id, categoryId };
      }
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringDeletingProduct'),
      );
      return rejectWithValue(error);
    }
  },
);
