import { FC } from 'react';
import clsx from 'clsx';

import { ITertiaryHeaderProps } from './TertiaryHeader.types';

const TertiaryHeader: FC<ITertiaryHeaderProps> = ({
  text,
  isSingleLine,
  isCentered = true,
}) => {
  return (
    <h3
      className={clsx(
        'text-lg font-semibold',
        isSingleLine && 'line-clamp-1',
        isCentered && 'text-center',
      )}
    >
      {text}
    </h3>
  );
};

export default TertiaryHeader;
