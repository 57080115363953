import { SVGProps } from 'react';

const TableIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.89 19.835l2.058-5.15h2.977V10.89c-2.449-.097-4.482-.47-6.1-1.118-1.62-.648-2.429-1.41-2.429-2.284 0-.94.932-1.744 2.797-2.414 1.864-.67 4.13-1.005 6.8-1.005 2.679 0 4.95.334 6.815 1 1.864.667 2.796 1.477 2.796 2.431 0 .877-.811 1.637-2.433 2.28-1.622.643-3.654 1.013-6.096 1.11v3.795h2.977l2.058 5.15h-2.22l-1.2-3H9.31l-1.2 3H5.89z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default TableIcon;
