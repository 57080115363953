const UndoIcon = () => {
  return (
    <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.513 20.35c-2.293 0-4.25-.81-5.87-2.429-1.62-1.62-2.43-3.576-2.43-5.87 0-2.292.81-4.266 2.43-5.92s3.577-2.48 5.87-2.48c1.186 0 2.31.264 3.37.792a9.14 9.14 0 012.753 2.088v-2.88h2.15v7.465h-7.465v-2.15h4.45a6.212 6.212 0 00-2.23-2.207 5.914 5.914 0 00-3.028-.809c-1.716 0-3.17.592-4.362 1.775C6.959 8.91 6.363 10.35 6.363 12.05c0 1.717.596 3.171 1.788 4.363 1.192 1.192 2.646 1.787 4.362 1.787 1.3 0 2.48-.37 3.538-1.112 1.058-.742 1.796-1.738 2.212-2.988h2.231c-.47 1.832-1.444 3.332-2.92 4.5-1.475 1.167-3.162 1.75-5.06 1.75z"
        fill="#fff"
      />
    </svg>
  );
};

export default UndoIcon;
