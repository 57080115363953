import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import {
  ICreateAvailabilityVariables,
  IDeleteAvailabilityVariables,
  IUpdateAvailabilityVariables,
} from '../../types/ISchedule';
import { apiErrorMessages, REST_API_URLS } from '../../constants/constants';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import {
  createAvailabilityResponseValidateSchema,
  updateAvailabilityResponseValidateSchema,
} from '../../schema/ScheduleSchemas';
import { handleForbiddenError } from '../../functions/functions';

export const listAvailabilitiesThunk = createAsyncThunk(
  'schedule/listAvailabilities',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listAvailabilities,
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createAvailabilityThunk = createAsyncThunk(
  'schedule/createAvailability',
  async (
    {
      name,
      color,
      start,
      end,
      weekdays,
      errorCallback,
    }: ICreateAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const createAvailabilityInput = {
        name,
        start,
        end,
        color,
        weekdays,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createAvailability,
        method: 'POST',
        data: createAvailabilityInput,
      });

      createAvailabilityResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;
        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));
      return rejectWithValue(error);
    }
  },
);

export const updateAvailabilityThunk = createAsyncThunk(
  'schedule/updateAvailability',
  async (
    {
      id,
      name,
      color,
      start,
      end,
      weekdays,
      errorCallback,
    }: IUpdateAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const updateAvailabilityInput = {
        id,
        name,
        start,
        end,
        color,
        weekdays,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateAvailability,
        method: 'PATCH',
        data: updateAvailabilityInput,
      });

      updateAvailabilityResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;
        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));

      return rejectWithValue(error);
    }
  },
);

export const deleteAvailabilityThunk = createAsyncThunk(
  'schedule/deleteAvailability',
  async (
    { id, errorCallback }: IDeleteAvailabilityVariables,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.deleteAvailability,
        method: 'DELETE',
        data: { id },
      });

      if (response.data) {
        return { id };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        if (typeof errorData === 'object') {
          if (errorData.errorCode === apiErrorMessages.COLLIDING_RESERVATION) {
            if (errorCallback) errorCallback();
          }
        }
      }
      handleForbiddenError(error, t('errorMessages.somethingWentWrong'));
      return rejectWithValue(error);
    }
  },
);
