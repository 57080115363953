import { FC } from 'react';
import clsx from 'clsx';

import { ISecondaryHeaderProps } from './SecondaryHeader.types';

const SecondaryHeader: FC<ISecondaryHeaderProps> = ({ text, isLineBreak }) => {
  return (
    <h2
      className={clsx(
        'text-xl font-semibold md:text-2xl',
        isLineBreak ? 'line-clamp-2 text-center' : 'line-clamp-1',
      )}
    >
      {text}
    </h2>
  );
};

export default SecondaryHeader;
