import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';

import InputSearch from '../../atoms/InputSearch/InputSearch';
import { ISearchBarProps } from './SearchBar.types';
import useDebounce from '../../../../hooks/useDebounce';

const SearchBar = ({ onSearch }: ISearchBarProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 300);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    onSearch(debouncedQuery);
  }, [debouncedQuery, onSearch]);

  return (
    <InputSearch
      name="searchReservations"
      value={query}
      onChange={handleOnChange}
      variant="secondary"
      placeholder={t('messages.searchReservations')}
      isError={false}
    />
  );
};

export default SearchBar;
