import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PickerList from '../../atoms/PickerList/PickerList';
import { IInputPickerProps } from './InputPicker.types';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputArea from '../../atoms/InputArea/InputArea';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';

const InputPicker = ({
  label,
  placeholder,
  name,
  options,
  value,
  onChange,
  error,
}: IInputPickerProps) => {
  const { t } = useTranslation();

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleList = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseList = () => {
    setIsOpen(false);
  };

  const handleSelectOption = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  useDetectOutsideClick(dropdownWrapperRef, handleCloseList);

  return (
    <div
      ref={dropdownWrapperRef}
      className="relative flex flex-1 cursor-pointer flex-col"
    >
      <InputLabel text={label} htmlFor={name} variant="secondary" />
      <InputArea
        isReadOnly
        isNumberOfPeoplePicker
        name={name}
        type="text"
        variant="secondary"
        placeholder={placeholder}
        value={value}
        isError={!!error}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onClick={handleToggleList}
      />
      {isOpen && (
        <PickerList
          value={value}
          label={t('messages.numberOfPeople')}
          options={options}
          handleSelectOption={handleSelectOption}
        />
      )}
      {!!error && (
        <div className="mt-[2px]">
          <InputErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

export default InputPicker;
