import { SVGProps } from 'react';

const MoreIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.52 12.004c0 .51-.182.945-.545 1.306-.363.36-.8.54-1.31.54s-.944-.181-1.305-.544c-.36-.364-.54-.8-.54-1.31s.181-.945.544-1.305c.363-.36.8-.54 1.31-.54s.945.18 1.305.544c.36.363.54.8.54 1.31zm-6.67 0c0 .51-.181.945-.544 1.306-.363.36-.8.54-1.31.54s-.945-.181-1.305-.544c-.36-.364-.54-.8-.54-1.31s.18-.945.544-1.305c.363-.36.8-.54 1.31-.54s.944.18 1.305.544c.36.363.54.8.54 1.31zm-6.669 0c0 .51-.181.945-.544 1.306-.364.36-.8.54-1.31.54s-.945-.181-1.305-.544c-.36-.364-.54-.8-.54-1.31s.18-.945.544-1.305c.363-.36.8-.54 1.31-.54.509 0 .944.18 1.304.544.36.363.541.8.541 1.31z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default MoreIcon;
