import { Outlet } from 'react-router-dom';

import Header from '../../UI/molecules/Header/Header';
import backgroundGradient from '../../../assets/images/background-gradient.png';

const FirstStepsTemplate = () => {
  return (
    <div className="relative max-h-screen min-h-screen overflow-hidden">
      <Header isHamburgerDisabled />
      <img
        src={backgroundGradient}
        alt="background gradient"
        className="absolute left-0 top-0 -z-50 h-full w-full object-cover object-right-bottom"
      />
      <div className="flex h-[calc(100dvh-64px)] px-5 pb-4 pt-16 md:px-8 md:pb-8 md:pt-10 xl:items-center xl:p-10 xl:pt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default FirstStepsTemplate;
