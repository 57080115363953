import { SVGProps } from 'react';

const MinusIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.7 13.05V10.9h14.6v2.15H4.7z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default MinusIcon;
