import { initializeApp } from 'firebase/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Providers from './providers';
import { firebaseConfig } from './firebase/firebaseConfig';

initializeApp(firebaseConfig);

const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('messages.funzyAdminPanel');
  }, [t]);

  return <Providers />;
};

export default App;
