import { FC, createElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { ISideMenuLinkProps } from './SideMenuLink.types';
import { routes } from '../../../../constants/constants';

const SideMenuLink: FC<ISideMenuLinkProps> = ({
  route,
  icon,
  text,
  isMenuOpen,
  callback,
  showRedDot = false,
}) => {
  const { pathname } = useLocation();

  const isAvailabilityRoute =
    pathname.includes(route) ||
    (route === routes.availabilitySpacePlan &&
      (pathname.includes(routes.availabilitySchedule) ||
        pathname.includes(routes.availabilitySpecialDays)));

  const isAvailabilityRouteForEmployees =
    pathname.includes(route) ||
    (route === routes.availabilitySchedule &&
      pathname.includes(routes.availabilitySpecialDays));

  return (
    <div className="mr-0.5 py-1.5">
      <NavLink
        to={route}
        className={({ isActive }) =>
          isAvailabilityRoute || isAvailabilityRouteForEmployees || isActive
            ? 'group relative flex items-center gap-x-4 rounded-xs bg-brand-700 px-2 py-2 font-semibold transition-colors duration-100 ease-linear'
            : 'group relative flex items-center gap-x-4 rounded-xs px-2 py-2 font-medium transition-colors duration-100 ease-linear hover:bg-brand-500'
        }
        onClick={callback}
      >
        <span
          className={clsx(
            'sidebar-tooltip group-hover:scale-100 xl:ml-3',
            isMenuOpen && 'hidden',
          )}
        >
          {text}
        </span>

        <div>
          {createElement(icon, {
            className:
              pathname === route ||
              isAvailabilityRoute ||
              isAvailabilityRouteForEmployees
                ? 'fill-white'
                : 'fill-brand-1700',
          })}
        </div>
        <span
          className={clsx(
            'line-clamp-1 origin-left whitespace-nowrap duration-200',
            !isMenuOpen && 'hidden',
          )}
        >
          {text}
        </span>
        {showRedDot && (
          <span className="ml-auto mr-2 h-3 w-3 rounded-full bg-red-700" />
        )}
      </NavLink>
    </div>
  );
};

export default SideMenuLink;
