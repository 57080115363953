import LoaderIcon from '../../../../assets/icons/LoaderIcon';

const SectionLoader = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="animate-spin">
        <div className="scale-150">
          <LoaderIcon />
        </div>
      </div>
    </div>
  );
};

export default SectionLoader;
