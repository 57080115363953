import { IHiddenCheckboxProps } from './HiddenCheckbox.types';

const HiddenCheckbox = ({
  name,
  onChange,
  isChecked,
}: IHiddenCheckboxProps) => {
  return (
    <input
      type="checkbox"
      id={name}
      name={name}
      onChange={onChange}
      className="hidden"
      checked={isChecked}
    />
  );
};

export default HiddenCheckbox;
