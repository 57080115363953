import { createContext, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { IAuthProvider, IAuthContext } from './AuthContext.types';
import { useAppDispatch } from '../hooks/useRedux';
import { fetchUser } from '../redux/thunks/userThunk';

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthContextProvider = ({ children }: IAuthProvider) => {
  const dispatch = useAppDispatch();
  const value = undefined;
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) =>
      dispatch(fetchUser(user?.uid)),
    );
    return unsubscribe;
  }, [auth, dispatch]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext)?.user;
