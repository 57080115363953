import clsx from 'clsx';

import { IButtonProps } from './Button.types';
import Spinner from '../../atoms/Spinner/Spinner';

const Button = ({
  type,
  variant,
  text,
  isLoading,
  isDeleteButton,
  onClick,
  isDisabled,
  isFullWidthOnMobile,
  isFullWidth,
  isSkeleton,
  isSelected,
}: IButtonProps) => {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={clsx(
        'pointer-events-auto flex flex-1 select-none items-center justify-center gap-2 whitespace-nowrap border-2',
        variant === 'primary' &&
          'h-[60px] min-h-[60px] w-full rounded-full border-white text-lg font-semibold md:flex-grow-0',
        variant === 'secondary' &&
          'h-[42px] min-h-[42px] w-fit whitespace-nowrap rounded-xs border-white px-5 font-medium md:flex-grow-0',
        variant === 'tertiary' &&
          'h-[42px] min-h-[42px] w-fit whitespace-nowrap rounded-xs border-brand-700 bg-brand-50 px-5 font-medium md:flex-grow-0',
        variant === 'quaternary' &&
          'h-[42px] min-h-[42px] w-fit whitespace-nowrap rounded-xs border-white bg-white px-5 font-medium text-black md:flex-grow-0',
        variant === 'quinary' &&
          'h-[42px] min-h-[42px] w-full whitespace-nowrap rounded-xs border-white px-5 font-medium md:flex-grow-0',
        variant === 'senary' &&
          'h-[42px] min-h-[42px] w-full whitespace-nowrap rounded-xs border-brand-700 px-5 font-medium md:w-fit md:flex-grow-0',
        variant === 'septenary' &&
          'h-[42px] min-h-[42px] w-full rounded-xs border-white px-5 font-medium hover:bg-brand-700 md:flex-grow',
        variant === 'octonary' &&
          'h-[42px] min-h-[42px] w-full rounded-xs border-brand-700 px-5 font-medium hover:bg-brand-700 md:flex-grow',
        variant === 'nonary' &&
          'h-[42px] min-h-[42px] max-w-fit rounded-xs border-brand-700 px-3 py-2.5 text-sm font-medium text-white transition-colors',
        isDeleteButton && 'transition-colors duration-100 hover:border-red-500',
        isLoading && 'pointer-events-none',
        isDisabled &&
          (variant === 'quinary'
            ? 'border-none bg-brand-300 text-base text-brand-1200'
            : 'opacity-40'),
        isFullWidthOnMobile && 'w-full md:w-fit',
        isFullWidth && 'w-full flex-grow md:flex-grow',
        isSkeleton &&
          'border-bg-brand-700 pointer-events-none animate-pulse bg-brand-700 text-brand-700',
        isSelected && 'border-white',
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      {isLoading && <Spinner isButtonWhite={variant === 'quaternary'} />}
      <span className="truncate">{text}</span>
    </button>
  );
};

export default Button;
