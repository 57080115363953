import { FC } from 'react';

import { IDialingCodeSelectorSearchInputProps } from './DialingCodeSelectorSearchInput.types';
import SearchSmallIcon from '../../../../assets/icons/SearchSmallIcon';

const DialingCodeSelectorSearchInput: FC<
  IDialingCodeSelectorSearchInputProps
> = ({ placeholder, searchValue, onChange }) => {
  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder={placeholder}
        className="w-full border-0 bg-brand-50 py-2 pl-7 outline-none"
        value={searchValue}
        onChange={onChange}
      />
      <div className="absolute left-0 top-0 flex h-full items-center">
        <SearchSmallIcon />
      </div>
    </div>
  );
};

export default DialingCodeSelectorSearchInput;
