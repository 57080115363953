import { useTranslation } from 'react-i18next';

import RootBoundaryPageIcon from '../../../assets/icons/RootBoundaryPageIcon';
import RootBoundaryPageMobileIcon from '../../../assets/icons/RootBoundaryPageMobileIcon';
import SecondaryHeader from '../../UI/atoms/SecondaryHeader/SecondaryHeader';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../assets/icons/PlusIcon';
import useMobile from '../../../hooks/useIsMobile';

const RootBoundaryPage = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center p-8">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          {isMobile ? <RootBoundaryPageMobileIcon /> : <RootBoundaryPageIcon />}
        </div>
        <div className="flex flex-col items-center justify-center">
          <SecondaryHeader text={t('messages.pageErrorTitle')} isLineBreak />
        </div>
      </div>
      <p className="max-w-[512px] pb-6 pt-4 text-center text-brand-1700">
        {t('messages.pageErrorDescription')}
      </p>
      <div className="w-fit">
        <ButtonWithIcon
          type="button"
          text={t('messages.pageErrorButton')}
          variant="primary"
          icon={PlusIcon}
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
};

export default RootBoundaryPage;
