import { useEffect, useState } from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  DragEndEvent,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { TOffer } from '../../../../types/Offer';
import Offer from '../../molecules/Offer/Offer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { changeOrderOfOffersThunk } from '../../../../redux/thunks/offerThunk';
import { IOfferListProps } from './OfferList.types';

const OfferList = ({
  offers,
  showDeleteModal,
  showEditModal,
}: IOfferListProps) => {
  const [items, setItems] = useState<TOffer[]>(offers);

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((currentItems) => {
        const oldIndex = currentItems.findIndex(
          (item) => item.id === active.id,
        );
        const newIndex = currentItems.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(currentItems, oldIndex, newIndex);
        dispatch(changeOrderOfOffersThunk({ offers: newItems }));
        return newItems;
      });
    }
  };

  useEffect(() => {
    setItems(offers);
  }, [offers]);

  return !isEmployee ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className="mt-6 h-full w-full overflow-y-auto overflow-x-hidden">
          {items.map((offer) => (
            <Offer
              offer={offer}
              key={offer.id}
              showDeleteModal={showDeleteModal}
              showEditModal={showEditModal}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  ) : (
    <div className="mt-6 h-full w-full overflow-y-auto overflow-x-hidden">
      {items.map((offer) => (
        <Offer
          offer={offer}
          key={offer.id}
          showDeleteModal={showDeleteModal}
          showEditModal={showEditModal}
        />
      ))}
    </div>
  );
};

export default OfferList;
