import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import i18n from '../../configuration/i18n';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import { ICreateSupportTicketDataThunk } from '../../types/Support';
import { createSupportTicketValidateSchema } from '../../schema/SupportSchemas';

export const createSupportTicketThunk = createAsyncThunk(
  'support/createSupportTicket',
  async (
    { summary, description, files }: ICreateSupportTicketDataThunk,
    { rejectWithValue },
  ) => {
    try {
      const createSupportTicketInput = {
        summary,
        description,
        files,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createSupportTicket,
        method: 'POST',
        data: createSupportTicketInput,
      });

      createSupportTicketValidateSchema.parse(response);

      toast.success(i18n.t('messages.supportModule.supportTicketSuccess'));

      return response.data;
    } catch (error) {
      toast.error(
        i18n.t('errorMessages.somethingWentWrongDuringCreateSupportTicket'),
      );
      return rejectWithValue(error);
    }
  },
);
