import { SVGProps } from 'react';

const SettingsIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.117 22.3l-.43-3.221a8.064 8.064 0 01-.587-.29 3.11 3.11 0 01-.567-.381l-3.046 1.267-2.858-5.1 2.515-1.944a13.038 13.038 0 00-.024-.348 3.685 3.685 0 010-.657c.01-.114.018-.232.024-.357L1.63 9.375l2.858-5.03 2.936 1.207c.191-.141.403-.274.635-.4.232-.124.447-.235.644-.33l.415-3.172h5.766l.405 3.206c.217.109.43.22.64.332.21.112.413.242.61.389l2.976-1.233 2.857 5.031-2.604 1.973c.02.112.03.225.034.34a5.542 5.542 0 01-.005.613l-.02.37 2.535 1.904-2.857 5.1-3.04-1.277a7.87 7.87 0 01-.546.362c-.182.11-.367.205-.555.284l-.43 3.256H9.117zm2.82-6.65c1.032 0 1.906-.359 2.624-1.076.717-.717 1.075-1.592 1.075-2.624s-.358-1.907-1.075-2.624c-.718-.717-1.592-1.076-2.624-1.076-1.026 0-1.9.359-2.62 1.076-.72.717-1.08 1.592-1.08 2.624s.36 1.907 1.08 2.624c.72.717 1.594 1.076 2.62 1.076z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default SettingsIcon;
