import type { CustomFlowbiteTheme } from 'flowbite-react';

export const customFlowBiteTheme: CustomFlowbiteTheme = {
  datepicker: {
    root: {
      base: 'relative  min-w-[267px]',
    },
    popup: {
      root: {
        base: 'absolute top-10 z-50 block pt-2 ',
        inline: 'relative top-0 z-auto',
        inner: 'inline-block rounded-[8px] bg-white shadow-lg dark:bg-brand-50',
      },
      header: {
        base: '',
        title:
          'capitalize px-2 py-3 text-center font-semibold text-gray-900 dark:text-white',
        selectors: {
          base: 'flex justify-between mb-2',
          button: {
            base: 'whitespace-nowrap text-sm rounded-[8px] text-gray-900 dark:text-white bg-white dark:bg-brand-50 font-semibold py-2.5 px-5 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch',
            prev: '',
            next: '',
            view: 'flowbite-month-button px-3',
          },
        },
      },
      view: {
        base: 'p-1',
      },
      footer: {
        base: 'hidden flex mt-2 space-x-2',
        button: {
          base: 'w-full rounded-[8px] px-5 py-2 text-center text-sm font-medium focus:none',
          today:
            'bg-cyan-700 text-white hover:bg-cyan-800 dark:bg-brand-50 border-white border-[2px] dark:hover:bg-gray-600',
          clear:
            'border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-brand-50 dark:text-white dark:hover:bg-gray-600',
        },
      },
    },
    views: {
      days: {
        header: {
          base: 'grid grid-cols-7 mb-1',
          title:
            'dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400',
        },
        items: {
          base: 'grid w-64 grid-cols-7',
          item: {
            base: 'block flex-1 cursor-pointer rounded-[8px] border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 ',
            selected: 'bg-gray-500 text-brand-50 hover:bg-cyan-600',
            disabled:
              'cursor-default dark:text-brand-1200 dark:hover:bg-brand-50',
          },
        },
      },
      months: {
        items: {
          base: 'grid w-64 grid-cols-4',
          item: {
            base: 'block flex-1 cursor-pointer rounded-[8px] border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
            selected: 'bg-gray-500 text-white hover:bg-cyan-600',
            disabled: 'text-gray-500',
          },
        },
      },
      years: {
        items: {
          base: 'grid w-64 grid-cols-4',
          item: {
            base: 'block flex-1 cursor-pointer rounded-[8px] border-0 text-center text-sm font-semibold leading-9 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 text-gray-900',
            selected: 'bg-gray-500 text-white hover:bg-cyan-600',
            disabled: 'text-gray-500',
          },
        },
      },
      decades: {
        items: {
          base: 'grid w-64 grid-cols-4',
          item: {
            base: 'block flex-1 cursor-pointer rounded-[8px] border-0 text-center text-sm font-semibold leading-9  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 text-gray-900',
            selected: 'text-brand-50 bg-gray-500 hover:bg-cyan-600',
            disabled: 'text-gray-500',
          },
        },
      },
    },
  },
};
