import clsx from 'clsx';

import { ITableRowNameProps } from './TableRowName.types';

const TableRowName = ({
  img,
  text,
  isImageHorizontal,
  onClick,
}: ITableRowNameProps) => {
  return (
    <div className="flex items-center gap-3 truncate text-base font-semibold">
      {img ? (
        <img
          src={img}
          className={clsx(
            'h-[42px] rounded-xs object-cover',
            isImageHorizontal
              ? 'w-[72px] min-w-[72px]'
              : 'w-[42px] min-w-[42px]',
          )}
          alt=""
        />
      ) : null}
      <span
        className={clsx('truncate pr-2', onClick && 'cursor-pointer')}
        onClick={onClick}
      >
        {text}
      </span>
    </div>
  );
};

export default TableRowName;
