import logoImageSvg from '../../../../assets/images/gradient-logo.svg';
import logoImagePng from '../../../../assets/images/gradient-logo.png';
import { isSafari } from '../../../../functions/functions';

const LoginContentLogo = () => {
  return (
    <div className="relative h-[98px]">
      <div className="h-[86px] w-[200px]" />
      <img
        src={isSafari() ? logoImagePng : logoImageSvg}
        alt="logo"
        width={350}
        height={86}
        className="absolute left-[-78px] top-[-86px] scale-125"
      />
    </div>
  );
};

export default LoginContentLogo;
