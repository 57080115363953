import { FC } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IModalProps } from './Modal.types';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import TertiaryHeader from '../../atoms/TertiaryHeader/TertiaryHeader';
import ChatModalTitle from '../../atoms/ChatModalTitle/ChatModalTitle';
import useTooltip from '../../../../hooks/useTooltip';
import InfoIcon from '../../../../assets/icons/InfoIcon';

const Modal: FC<IModalProps> = ({
  visible,
  onClose,
  title,
  children,
  isPaddingDisabled = false,
  isChatModal,
  isNotificationModal,
  isFullScreenModal = false,
  tableName,
  userFullName,
  titleBorderDisabled,
  isGalleryModal,
}) => {
  const { t } = useTranslation();

  return createPortal(
    visible ? (
      <div
        className={clsx(
          'absolute left-0 top-0 z-[99] h-screen w-screen items-center justify-center overflow-hidden bg-black/75 md:px-12 md:py-6',
          visible ? 'flex' : 'hidden',
          isChatModal ? 'p-0' : 'p-5',
        )}
      >
        <div
          className={clsx(
            'w-full bg-brand-50',
            !isFullScreenModal &&
              (isNotificationModal ? 'max-w-[537px]' : 'max-w-[744px]'),
            !isPaddingDisabled && 'p-6',
            isChatModal
              ? 'h-full overflow-hidden rounded-none p-4 md:h-max md:overflow-x-auto md:rounded-xs md:border'
              : 'h-max max-h-[calc(100dvh-2.5rem)] overflow-x-auto overflow-y-hidden rounded-xs border',
          )}
        >
          {title && (
            <div
              className={clsx(
                'flex justify-between pb-4',
                titleBorderDisabled && 'border-none',
                !isFullScreenModal && 'border-b',
              )}
            >
              <div className="flex items-center justify-center gap-2">
                <TertiaryHeader text={title} />
                {isGalleryModal && (
                  <div className="group ml-auto cursor-pointer md:ml-0">
                    <InfoIcon isWhite />
                    <div className="relative hidden group-hover:flex">
                      <div className="absolute top-2 z-50">
                        <div className="w-[150px] rounded-xs border border-brand-700 bg-brand-50 p-[10px] text-base text-white shadow-lg">
                          {t('messages.photoShouldBeInAspect', {
                            aspect: '16:9',
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {onClose ? (
                <button type="button" onClick={onClose}>
                  <CloseIcon />
                </button>
              ) : null}
            </div>
          )}
          {isChatModal && (
            <div className="flex justify-between border-b pb-4 ">
              <ChatModalTitle
                tableName={tableName}
                userFullName={userFullName}
              />
              <button type="button" onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    ) : null,
    document.body,
  );
};
export default Modal;
