import React, {
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';

import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { IDropdownMenuProps } from '../../molecules/DropdownMenuDefaultList/DropdownMenuDefaultList.types';
import { ISimpleDropdownProps } from './SimpleDropdown.types';

const SimpleDropdown: FC<ISimpleDropdownProps> = ({
  options,
  selectedValue,
  onChange,
  formatLabel,
  placeholder,
  isDisabled = false,
  menuPortalTarget, // Przekazujesz element DOM, do którego dropdown ma być przeniesiony za pomocą createPortal. Używasz go, aby dropdown został wyrenderowany w innym miejscu niż w swoim pierwotnym miejscu w drzewie DOM (np. w <body>).
  modalContainer, // Przekazujesz element modalny, aby dropdown był poprawnie pozycjonowany względem modala. Używasz go, aby dropdown aktualizował swoją pozycję zgodnie z przewijaniem i rozmiarem modala, zamiast okna przeglądarki. Przykładowo w modalu element, który jest obszarem scrollowalnym.
  error,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    width: number;
  }>({ top: 0, left: 0, width: 0 });

  const shownValue = formatLabel
    ? formatLabel(selectedValue)
    : options.find((option) => option.value === selectedValue)?.label || '';

  const handleToggleOpen = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const updateDropdownPosition = () => {
    if (wrapperRef.current && dropdownRef.current) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;

      const scrollContainer = modalContainer || window;

      const containerHeight =
        scrollContainer instanceof Window
          ? scrollContainer.innerHeight
          : scrollContainer.clientHeight;

      const spaceBelow = containerHeight - parentRect.bottom;
      const spaceAbove = parentRect.top;

      const MARGIN_ERROR = 20;

      const openDownwards =
        spaceBelow >= dropdownHeight + MARGIN_ERROR ||
        spaceBelow >= spaceAbove + MARGIN_ERROR;

      let top;
      if (openDownwards) {
        top = parentRect.bottom + 20;
      } else {
        top = parentRect.top - dropdownHeight - 20;
      }

      const left =
        parentRect.left +
        (scrollContainer instanceof Window
          ? scrollContainer.scrollX
          : scrollContainer.scrollLeft);

      setDropdownPosition({
        top:
          top +
          (scrollContainer instanceof Window
            ? scrollContainer.scrollY
            : scrollContainer.scrollTop),
        left,
        width: parentRect.width,
      });
    }
  };

  useEffect(() => {
    const handleScrollResize = () => {
      if (isOpen) {
        updateDropdownPosition();
      }
    };

    if (isOpen) {
      updateDropdownPosition();
      window.addEventListener('resize', handleScrollResize);
      window.addEventListener('scroll', handleScrollResize, true);
    }

    return () => {
      window.removeEventListener('resize', handleScrollResize);
      window.removeEventListener('scroll', handleScrollResize, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, modalContainer]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleCloseDropdown();
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'md:min-w-[340px relative flex h-[42px] items-center justify-center rounded-xs border-[1px] border-solid',
        error && 'border-red-600',
        isDisabled && 'pointer-events: none',
      )}
    >
      <button
        type="button"
        className="flex w-full justify-between gap-2.5 px-4 py-3"
        onClick={handleToggleOpen}
        disabled={isDisabled}
      >
        <span
          className={clsx(
            'truncate',
            shownValue ? 'text-white' : 'text-brand-1700',
          )}
        >
          {shownValue || placeholder}
        </span>
        <span
          className={clsx(
            ' duration-100 ease-in-out',
            isOpen ? 'rotate-0' : 'rotate-180',
          )}
        >
          <ArrowIcon isDisabled={isDisabled} />
        </span>
      </button>

      {isOpen &&
        createPortal(
          <div
            ref={dropdownRef}
            className="absolute z-[400] flex max-h-[234px] flex-col gap-4 rounded-xs border bg-brand-50 p-5"
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
            }}
          >
            {children &&
              isValidElement(children) &&
              cloneElement(children as ReactElement<IDropdownMenuProps>, {
                options,
                onOptionClick: (val: string, onClick?: () => void) => {
                  if (onClick) onClick();
                  onChange(val);
                  handleCloseDropdown();
                },
              })}
          </div>,
          menuPortalTarget || document.body,
        )}
      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
    </div>
  );
};

export default SimpleDropdown;
