import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import AvailabilityPopup from '../../UI/organisms/AvailabilityPopup/AvailabilityPopup';
import { listSpecialDaysAvailabilitiesThunk } from '../../../redux/thunks/specialDayThunk';
import Calendar from '../../UI/organisms/Calendar/Calendar';

const SpecialDaySchedulePage = () => {
  const { specialDayId } = useParams();

  const dispatch = useAppDispatch();

  const specialDayShiftVisible = useAppSelector(
    (state) => state.specialDay.specialDayShiftVisible,
  );

  const specialDay = useAppSelector((state) => state.specialDay.specialDay);

  useEffect(() => {
    if (specialDayId && !specialDay) {
      dispatch(listSpecialDaysAvailabilitiesThunk({ specialDayId }));
    }
  }, [dispatch, specialDayId, specialDay]);

  return (
    <div className="h-full w-full">
      <Calendar isForSpecialDay />
      <AvailabilityPopup isForSpecialDay isVisible={specialDayShiftVisible} />
    </div>
  );
};

export default SpecialDaySchedulePage;
