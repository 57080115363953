import z from 'zod';

export const listAvailabilitiesResponseValidateSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string(),
    start: z.string(),
    end: z.string(),
    color: z.string(),
    weekdays: z.array(z.number()),
  }),
);

export const createAvailabilityResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  start: z.string(),
  end: z.string(),
  color: z.string(),
  weekdays: z.array(z.number()),
});

export const updateAvailabilityResponseValidateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  start: z.string(),
  end: z.string(),
  color: z.string(),
  weekdays: z.array(z.number()),
});
