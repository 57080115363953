interface IArrowIconProps {
  isDisabled?: boolean;
  color?: string;
}

const ArrowIcon = ({ isDisabled, color }: IArrowIconProps) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.99 10.904L7.376 15.52l-1.512-1.51L12 7.872l6.137 6.136-1.511 1.511-4.617-4.616-.008-.009-.01.01z"
        fill={isDisabled ? '#5E586C' : color || '#fff'}
        stroke="#fff"
        strokeWidth={0.025}
      />
    </svg>
  );
};

ArrowIcon.defaultProps = {
  isDisabled: false,
  color: false,
};

export default ArrowIcon;
