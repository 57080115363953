import clsx from 'clsx';

import { IInputLabelProps } from './InputLabel.types';

const InputLabel = ({
  text,
  htmlFor,
  variant,
  isDisabled,
  isOpen,
}: IInputLabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        'mb-2',
        variant === 'primary' && 'font-medium',
        (variant === 'secondary' ||
          variant === 'tertiary' ||
          variant === 'flexible') &&
          'text-sm font-medium',
        isDisabled && 'text-brand-1200',
      )}
    >
      {text}
    </label>
  );
};

export default InputLabel;
