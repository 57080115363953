import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import { IReservationLanguageSelectorProps } from './ReservationLanguageSelector.types';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { RESERVATION_LANGUAGES } from '../../../../constants/constants';
import { IReservationLanguageObject } from '../../../../types/Reservation';

const ReservationLanguageSelector: FC<IReservationLanguageSelectorProps> = ({
  selectedLanguage,
  handleLanguageChange,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  useDetectOutsideClick(wrapperRef, () => setIsOpen(false));

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (language: IReservationLanguageObject) => {
    handleLanguageChange(language);
    setIsOpen(false);
  };

  const getFlagCdnCode = (country: string) => {
    switch (country) {
      case 'pl':
        return 'pl';
      case 'en':
        return 'gb';
      case 'uk':
        return 'ua';
      default:
        return null;
    }
  };

  return (
    <div ref={wrapperRef} className="relative">
      <div
        className="relative flex h-[42px] items-center justify-between rounded-xs border-[1px] border-solid border-brand-700 px-4 md:min-w-[340px]"
        onClick={toggleOpen}
      >
        <div className="mr-auto flex gap-2.5">
          <span className="mt-1.5">
            <img
              src={`https://flagcdn.com/32x24/${getFlagCdnCode(
                selectedLanguage.value,
              )}.png`}
              width="16"
              height="12"
              alt={t('alts.countryFlagOfPickedDialingCodeAlt')}
            />
          </span>
          <span className="font-medium">{selectedLanguage.label}</span>
        </div>
        <div
          className={clsx('duration-100', isOpen ? 'rotate-0' : 'rotate-180')}
        >
          <ArrowIcon />
        </div>
      </div>
      {isOpen ? (
        <div className="absolute left-0 top-14 z-50 flex max-h-[330px] w-full flex-col rounded-xs border border-brand-700 bg-brand-50 p-5">
          <div className="overflow-y-auto overflow-x-hidden">
            {RESERVATION_LANGUAGES.map((language) => (
              <div
                key={`${language.label}-${language.value}`}
                className="py-2"
                onClick={() => changeLanguage(language)}
              >
                {language.label}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReservationLanguageSelector;
