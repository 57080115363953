import { ProductSkeleton } from '../../molecules/ProductSkeleton/ProductSkeleton';

/* eslint-disable react/no-array-index-key */
const OfferListSkeleton = () => {
  return (
    <div className="mt-6 flex min-h-[calc(100dvh-300px)] w-full animate-pulse flex-col">
      {[...Array(5)].map((_, i) => {
        return (
          <div key={i}>
            <div className="flex h-[72px] w-full items-center justify-between gap-4 border-b px-2 xl:px-4">
              <div className="flex items-center gap-8">
                <span className="h-6 w-6 rounded-xs bg-brand-700" />
                <span className="h-6 w-16 rounded-xs bg-brand-700 md:w-52" />
              </div>
              <div className="flex items-center gap-4">
                <span className="h-11 w-12 rounded-xs bg-brand-300 md:w-44" />
                <span className="h-6 w-6 rounded-xs bg-brand-700" />
              </div>
            </div>
            {i === 0 && <ProductSkeleton />}
          </div>
        );
      })}
    </div>
  );
};

export default OfferListSkeleton;
