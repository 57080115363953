import { z } from 'zod';

import { RECCURENCE_TYPES } from '../constants/constants';

export const ListEvent = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  likesCount: z.number(),
  createdAt: z.string(),
  isRecurring: z.boolean().optional(),
  recurrenceType: z.enum(RECCURENCE_TYPES).optional().nullable(),
  interval: z.number().optional(),
  endRecurrenceDate: z.string().optional().nullable(),
});

export const listEventsCountResponseValidateSchema = z.number().int();

export const SingleEvent = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  description: z.string().optional(),
  category: z.string().optional(),
  ticketUrl: z.string().optional(),

  isRecurring: z.boolean().optional(),
  recurrenceType: z.enum(RECCURENCE_TYPES).optional().nullable(),
  dayOfWeek: z.number().optional().nullable(),
  dayOfMonth: z.number().optional().nullable(),
  daysOfWeek: z.array(z.number()).optional().nullable(),
  weekOfMonth: z.number().optional().nullable(),
  monthOfYear: z.number().optional().nullable(),
  endRecurrenceDate: z.string().optional().nullable(),
  interval: z.number().optional(),
});

export const SingleEventSchema = z.object({
  successCode: z.string(),
  data: SingleEvent,
});

export const ListEventsSchema = z.object({
  successCode: z.string(),
  data: z.array(ListEvent),
  total: z.number(),
});

export const CreateEventSchema = z.object({
  successCode: z.string(),
  data: ListEvent,
});
