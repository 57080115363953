import { FC } from 'react';
import clsx from 'clsx';

import { IModalContentProps } from './ModalContent.types';
import { useCustomScrollbar } from '../../../../hooks/useCustomScrollbar';

const ModalContent: FC<IModalContentProps> = ({
  children,
  modalContentRef,
}) => {
  const { isScrolling, handleScroll } = useCustomScrollbar();

  return (
    <div
      ref={modalContentRef}
      className={clsx(
        'custom-scrollbar flex h-full max-h-[calc(100dvh-250px)] w-full flex-col gap-2 overflow-x-auto pb-1 pr-2',
        isScrolling && 'scrolling',
      )}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default ModalContent;
