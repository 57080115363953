import clsx from 'clsx';

import { ICheckboxLabelProps } from './CheckboxLabel.types';

const CheckboxLabel = ({
  text,
  color,
  htmlFor,
  children,
  isLargeIcon,
}: ICheckboxLabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        'flex cursor-pointer items-center text-base',
        color || 'text-gray-400',
        isLargeIcon ? 'gap-3' : 'gap-2',
      )}
    >
      {children}
      <span>{text}</span>
    </label>
  );
};

export default CheckboxLabel;
