export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/;

export const numberInputRegex = /^\d*([.,])?(\d{0,2})?$/;

export const hoursRegex = /(\d+) godz\./;

export const minutesRegex = /(\d+) min\./;

export const openingHourRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const urlRegex =
  /^(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

export const zipCodeRegex = /^\d{2}-\d{3}$/;

export const encodedNameRegex = /^[a-zA-Z0-9][-a-zA-Z0-9]*[a-zA-Z0-9]$/;

const prefix = 'https://funzy.app/';
export const encodedNameRegexWithPrefix = new RegExp(
  `^${prefix}[a-zA-Z][-a-zA-Z]*[a-zA-Z]$`,
);

export const addressRegex =
  /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ -]+, [A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ -]+ \d{1,5}[A-Za-z]?\/?\d{0,5}[A-Za-z]?$/;

export const firstNameRegex = /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]{2,}(?:\s*)$/;

export const lastNameRegex =
  /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]+(?:-[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]+)?(?:\s*)$/;

export const fullNameRegex =
  /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]{1,}(?: [a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]+){1,2}$/gm;

export const tableNameRegex = /^[a-zA-Z0-9]+$/;

export const postCodeRegex =
  /^\d{2}-\d{3}$|^\d{5}$|^\d{2}-\d{2}$|^\d{3}-\d{2}$/;

export const httpsRegex = /^https:\/\//;

export const offerCategoryImageRegex = /^defaultImage\/([^.]+)/;
