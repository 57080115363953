const CopyIcon = ({ fill = '#B3ADC1' }: { fill?: string }) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.736 15.208c-.57 0-1.054-.199-1.452-.596a1.976 1.976 0 01-.596-1.452V3.506c0-.57.198-1.053.596-1.451a1.976 1.976 0 011.452-.597h7.153c.57 0 1.054.2 1.452.597.398.398.596.882.596 1.451v9.654c0 .57-.198 1.054-.596 1.452a1.976 1.976 0 01-1.452.596H7.736zm-3.459 3.459c-.57 0-1.053-.2-1.451-.597a1.976 1.976 0 01-.597-1.451V5.173h1.792V16.62c0 .064.027.122.08.176.053.053.112.08.176.08h8.946v1.792H4.277z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyIcon;
