import clsx from 'clsx';
import { differenceInMinutes, hoursToMinutes } from 'date-fns';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setCurrentDays } from '../../../../redux/features/scheduleSlice';
import { IWorkspaceAvailabilityBoxProps } from './WorkspaceAvailabilityBox.types';
import WorkspaceIconBox from '../WorkspaceIconBox/WorkspaceIconBox';

const WorkspaceAvailabilityBox = ({
  color,
  start,
  end,
  dayOfWeek,
}: IWorkspaceAvailabilityBoxProps) => {
  const dispatch = useAppDispatch();

  const currentDays = useAppSelector((state) => state.schedule.currentDays);

  const getDateTimeToOffsetTop = (beginDate: Date | number) => {
    const [hours, minutes] = [
      new Date(beginDate).getHours(),
      new Date(beginDate).getMinutes(),
    ];

    const dateTimeInMinutes = hoursToMinutes(hours) + minutes;
    return (dateTimeInMinutes / 60) * 42.01;
  };

  const getEventDuration = (
    beginDate: Date | number,
    endDate: Date | number,
  ) => {
    const difference = Math.round(differenceInMinutes(endDate, beginDate));
    return (difference / 60) * 42.01;
  };

  const handleClick = () => {
    if (!currentDays.includes(dayOfWeek)) {
      dispatch(setCurrentDays([...currentDays, dayOfWeek]));
    } else {
      const updatedDays = currentDays.filter((day) => day !== dayOfWeek);
      dispatch(setCurrentDays(updatedDays));
    }
  };

  return (
    <div
      style={{
        top: getDateTimeToOffsetTop(Number(start)),
        height: getEventDuration(Number(start), Number(end)),
      }}
      className={clsx(
        'absolute z-20 flex w-4/5 items-center justify-center rounded border bg-opacity-50',
        color === 'purple' && 'border-purple-200 bg-purple-700',
        color === 'indigo' && 'border-indigo-200 bg-indigo-600',
        color === 'blue' && 'border-blue-200 bg-blue-700',
        color === 'pink' && 'border-pink-200 bg-pink-600',
        color === 'teal' && 'border-teal-200 bg-teal-500',
        color === 'green' && 'border-green-200 bg-green-600',
        color === 'yellow' && 'border-yellow-200 bg-yellow-500',
        color === 'orange' && 'border-orange-200 bg-orange-600',
        color === 'red' && 'border-red-200 bg-red-600',
      )}
    >
      <WorkspaceIconBox
        color={color}
        currentDays={currentDays}
        dayOfWeek={dayOfWeek}
        handleClick={handleClick}
      />
    </div>
  );
};

export default WorkspaceAvailabilityBox;
