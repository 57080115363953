const ErrorIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.05 6.85H10.9V13h2.15V6.85zm-1.046 8.262c-.299 0-.55.1-.755.303a1.012 1.012 0 00-.308.75c0 .298.101.55.303.755.202.205.452.307.75.307.3 0 .55-.1.756-.303.204-.202.307-.452.307-.75 0-.299-.101-.55-.303-.755a1.011 1.011 0 00-.75-.307zM11.998 1.7c1.424 0 2.763.27 4.017.811a10.404 10.404 0 013.27 2.201 10.398 10.398 0 012.203 3.27c.54 1.253.811 2.592.811 4.016 0 1.43-.27 2.772-.812 4.03a10.435 10.435 0 01-2.206 3.28 10.223 10.223 0 01-3.27 2.194 10.14 10.14 0 01-4.01.798 10.23 10.23 0 01-4.029-.798 10.226 10.226 0 01-3.28-2.193 10.22 10.22 0 01-2.194-3.28A10.227 10.227 0 011.699 12c0-1.428.266-2.767.798-4.015A10.246 10.246 0 014.69 4.72a10.432 10.432 0 013.28-2.206c1.256-.542 2.599-.813 4.028-.813z"
        fill="#dc2626"
      />
    </svg>
  );
};

export default ErrorIcon;
