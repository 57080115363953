import { createSlice } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';

import { IUserSliceState } from '../../types/User';
import {
  disableTempPasswordUser,
  fetchUser,
  cancelSubscriptionThunk,
  createSubscriptionThunk,
  uncancelSubscriptionThunk,
} from '../thunks/userThunk';
import i18n from '../../configuration/i18n';

const initialState: IUserSliceState = {
  user: undefined,
  fetchUserStatus: null,
  disableTempPasswordUserStatus: null,
  createSubscriptionStatus: null,
  cancelSubscriptionStatus: null,
  uncancelSubscriptionStatus: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.fetchUserStatus = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.fetchUserStatus = 'success';
        if (payload) {
          state.user = payload;
        } else {
          state.user = null;
        }
      })
      .addCase(fetchUser.rejected, (state) => {
        state.fetchUserStatus = 'failed';
        const auth = getAuth();
        signOut(auth);
      });

    builder
      .addCase(disableTempPasswordUser.pending, (state) => {
        state.disableTempPasswordUserStatus = 'loading';
      })
      .addCase(disableTempPasswordUser.fulfilled, (state, { payload }) => {
        if (!payload) return;

        if (state.user) {
          state.user = {
            ...state.user,
            tempPassword: payload.tempPassword,
          };
        }

        state.disableTempPasswordUserStatus = 'success';
      })
      .addCase(disableTempPasswordUser.rejected, (state) => {
        state.disableTempPasswordUserStatus = 'failed';
      });

    // createSubscription
    builder.addCase(createSubscriptionThunk.pending, (state) => {
      state.createSubscriptionStatus = 'loading';
    });
    builder.addCase(createSubscriptionThunk.fulfilled, (state, { payload }) => {
      state.createSubscriptionStatus = 'success';

      window.location.href = payload;
    });
    builder.addCase(createSubscriptionThunk.rejected, (state) => {
      state.createSubscriptionStatus = 'failed';
    });

    // cancelSubscription
    builder.addCase(cancelSubscriptionThunk.pending, (state) => {
      state.cancelSubscriptionStatus = 'loading';
    });
    builder.addCase(cancelSubscriptionThunk.fulfilled, (state) => {
      state.cancelSubscriptionStatus = 'success';

      if (state.user) {
        state.user.cancelAtPeriodEndSubscription = true;
      }
    });
    builder.addCase(cancelSubscriptionThunk.rejected, (state) => {
      state.cancelSubscriptionStatus = 'failed';
    });

    // uncancelSubscription
    builder.addCase(uncancelSubscriptionThunk.pending, (state) => {
      state.uncancelSubscriptionStatus = 'loading';
    });
    builder.addCase(uncancelSubscriptionThunk.fulfilled, (state) => {
      state.uncancelSubscriptionStatus = 'success';

      if (state.user) {
        state.user.cancelAtPeriodEndSubscription = false;
      }
    });
    builder.addCase(uncancelSubscriptionThunk.rejected, (state) => {
      state.uncancelSubscriptionStatus = 'failed';
    });
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
