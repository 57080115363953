import { FC } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { IHeaderProps } from './Header.types';
import { TOAST_THREE_SECONDS_DURATION } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import HeaderLogo from '../../atoms/HeaderLogo/HeaderLogo';
import ExitIcon from '../../../../assets/icons/ExitIcon';
import MenuIcon from '../../../../assets/icons/MenuIcon';
import { clearFirstSteps } from '../../../../redux/features/firstStepsSlice';
import { clearUser } from '../../../../redux/features/userSlice';
import { clearSpacePlans } from '../../../../redux/features/spacePlanSlice';
import { clearReservations } from '../../../../redux/features/reservationSlice';
import LanguageDropdown from '../../atoms/LanguageDropdown/LanguageDropdown';
import { clearOffers } from '../../../../redux/features/offerSlice';
import { clearCoupons } from '../../../../redux/features/couponSlice';
import { clearSchedules } from '../../../../redux/features/scheduleSlice';
import { clearChat } from '../../../../redux/features/chatSlice';
import { clearSpecialDays } from '../../../../redux/features/specialDaySlice';
import { clearStatistics } from '../../../../redux/features/statisticsSlice';
import { clearSettings } from '../../../../redux/features/settingsSlice';
import { clearLocations } from '../../../../redux/features/locationSlice';
import { clearEvents } from '../../../../redux/features/eventSlice';

const Header: FC<IHeaderProps> = ({ showMenu, isHamburgerDisabled }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const user = useAppSelector((store) => store.user.user);

  const auth = getAuth();

  const clearStore = () => {
    dispatch(clearUser());
    dispatch(clearFirstSteps());
    dispatch(clearSpacePlans());
    dispatch(clearReservations());
    dispatch(clearOffers());
    dispatch(clearCoupons());
    dispatch(clearSchedules());
    dispatch(clearChat());
    dispatch(clearSpecialDays());
    dispatch(clearStatistics());
    dispatch(clearSettings());
    dispatch(clearLocations());
    dispatch(clearEvents());
  };

  const onSignOutClick = () => {
    try {
      clearStore();
      signOut(auth);
    } catch (error) {
      toast(t('errorMessages.logoutError', TOAST_THREE_SECONDS_DURATION));
    }
  };

  return (
    <header className="flex min-h-[64px] items-center justify-between border-b px-5">
      {isHamburgerDisabled ? null : (
        <button type="button" className="block xl:hidden" onClick={showMenu}>
          <MenuIcon />
        </button>
      )}
      <HeaderLogo />
      {user && user.firebaseId ? (
        <>
          <div className="mr-3">
            <LanguageDropdown />
          </div>
          <button type="button" onClick={onSignOutClick}>
            <ExitIcon />
          </button>
        </>
      ) : null}
    </header>
  );
};

export default Header;
