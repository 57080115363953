import { ICheckboxProps } from './Checkbox.types';
import CheckboxArea from '../../atoms/CheckboxArea/CheckboxArea';
import HiddenCheckbox from '../../atoms/HiddenCheckbox/HiddenCheckbox';
import CheckboxLabel from '../../atoms/CheckboxLabel/CheckboxLabel';

const Checkbox = ({
  name,
  labelText,
  labelTextColor,
  isChecked,
  isLargeIcon,
  onChange,
}: ICheckboxProps) => {
  return (
    <div>
      <HiddenCheckbox name={name} onChange={onChange} isChecked={isChecked} />
      <CheckboxLabel
        text={labelText}
        color={labelTextColor}
        htmlFor={name}
        isLargeIcon={isLargeIcon}
      >
        <CheckboxArea isChecked={isChecked} isLargeIcon={isLargeIcon} />
      </CheckboxLabel>
    </div>
  );
};

export default Checkbox;
