import { useRef, useState } from 'react';
import clsx from 'clsx';

import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import EnglishIcon from '../../../../assets/icons/EnglishIcon';
import PolishIcon from '../../../../assets/icons/PolishIcon';
import i18n from '../../../../configuration/i18n';
import { LANGUAGES } from '../../../../constants/constants';
import CheckIcon from '../../../../assets/icons/CheckIcon';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import { ILanguageDropdownProps } from './LanguageDropdown.types';

const LanguageDropdown = ({ isMobile }: ILanguageDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = (value: string) => {
    setIsOpen(false);
    i18n.changeLanguage(value);
  };

  useDetectOutsideClick(wrapperRef, handleClose);

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'relative  items-center justify-center xl:flex',
        isMobile ? 'flex' : 'hidden',
      )}
    >
      <span>
        {i18n.language.substring(0, 2) === 'pl' ? (
          <PolishIcon />
        ) : (
          <EnglishIcon />
        )}
      </span>
      <span
        onClick={handleToggle}
        className={clsx(
          'cursor-pointer p-2 duration-100 ease-in-out',
          !isOpen && 'rotate-180',
        )}
      >
        <ArrowIcon color="#B3ADC1" />
      </span>
      {isOpen ? (
        <div className="absolute right-2 top-10 z-[99] flex w-[237px] flex-col gap-4 border border-brand-700 bg-brand-50 p-[20px]">
          {LANGUAGES.map((item) => (
            <span
              onClick={() => handleClick(item.value)}
              className="flex h-6 w-full cursor-pointer flex-row items-center justify-between py-1 hover:bg-brand-500"
            >
              <span className="flex flex-row items-center">
                <span>
                  {item.value === 'pl' ? <PolishIcon /> : <EnglishIcon />}
                </span>
                <span className="ml-2">{item.label}</span>
              </span>
              <span className="flex scale-[1.43] items-center justify-center">
                {item.value === i18n.language.substring(0, 2) && (
                  <CheckIcon className=" stroke-white" />
                )}
              </span>
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default LanguageDropdown;
