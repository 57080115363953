import { useMemo } from 'react';

const generateHourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => `${i}:00`);
};

const CalendarDayHours = () => {
  const hourLabels = useMemo(generateHourLabels, []);

  return (
    <div className="sticky left-0 z-[21] flex w-[78px] flex-col border-r">
      {hourLabels.map((item: string) => (
        <div
          className="flex max-h-[42px] min-h-[42px] w-full items-center justify-center border-b bg-brand-50 text-xs font-medium uppercase"
          key={item}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default CalendarDayHours;
