export type TCollectionName =
  | 'coupons'
  | 'events'
  | 'products'
  | 'settings'
  | 'gallery'
  | 'places/logo'
  | 'places/photos'
  | 'offerCategories';

export class ImageCompressionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ImageCompressionError';
  }
}
