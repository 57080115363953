import { SVGProps } from 'react';

const TrashIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.4 14.27h1.79V6.605H7.4v7.667zm3.451 0h1.792V6.605H10.85v7.667zm-6.893 3.48V4.917h-.833V3.125h4.333v-.904h5.125v.904h4.334v1.792h-.834V17.75H3.958z"
        fill="#FFF"
        className={className}
      />
    </svg>
  );
};

export default TrashIcon;
