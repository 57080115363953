import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { stringHourToMinutes } from '../../../../functions/functions';
import { ExcludableAvailabilityBoxProps } from './ExcludableAvailabilityBox.types';
import { useAppSelector } from '../../../../hooks/useRedux';
import MinusIcon from '../../../../assets/icons/MinusIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import { setExcludedAvailabilitySeries } from '../../../../redux/features/spacePlanSlice';

const ExcludableAvailabilityBox = ({
  id,
  color,
  title,
  start,
  end,
}: ExcludableAvailabilityBoxProps) => {
  const HOUR_BOX_HEIGHT = 42.01;

  const dispatch = useDispatch();

  const excludedAvailabilitySeriesTemporary = useAppSelector(
    (state) => state.spacePlan.excludedAvailabilitySeriesTemporary,
  );
  const excludedAvailabilitySeries = useAppSelector(
    (state) => state.spacePlan.excludedAvailabilitySeries,
  );

  const seriesToUse =
    excludedAvailabilitySeriesTemporary ?? excludedAvailabilitySeries ?? [];

  const isExcluded = seriesToUse.some((availability) => availability.id === id);

  const getDateTimeToOffsetTop = (minutes: number) => {
    return (minutes / 60) * HOUR_BOX_HEIGHT;
  };

  const getEventDuration = (beginDate: number, endDate: number) => {
    const difference = endDate - beginDate;
    return (difference / 60) * HOUR_BOX_HEIGHT;
  };

  const handleClick = () => {
    if (isExcluded) {
      const updatedExcludedSeries = seriesToUse.filter(
        (availability) => availability.id !== id,
      );
      dispatch(setExcludedAvailabilitySeries(updatedExcludedSeries));
    } else {
      const updatedExcludedSeries = [
        ...(seriesToUse ?? []),
        { id, name: title },
      ];
      dispatch(setExcludedAvailabilitySeries(updatedExcludedSeries));
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{
        top: getDateTimeToOffsetTop(stringHourToMinutes(start)),
        height: getEventDuration(
          stringHourToMinutes(start),
          stringHourToMinutes(end),
        ),
      }}
      className={clsx(
        'absolute z-20 flex w-4/5 cursor-pointer flex-col items-center justify-center overflow-hidden rounded',
        color === 'purple' &&
          `bg-purple-800 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'indigo' &&
          `bg-indigo-600 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'blue' &&
          `bg-blue-600 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'pink' &&
          `bg-pink-500 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'teal' &&
          `bg-teal-500 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'green' &&
          `bg-green-500 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'yellow' &&
          `bg-yellow-400 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'orange' &&
          `bg-orange-400 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
        color === 'red' &&
          `bg-red-600 ${isExcluded ? 'bg-opacity-50' : 'bg-opacity-100'}`,
      )}
    >
      {getEventDuration(stringHourToMinutes(start), stringHourToMinutes(end)) >
      60 ? (
        <div
          className={clsx(
            ' flex flex-col items-center',
            isExcluded && 'text-brand-1800',
          )}
        >
          <div
            className={clsx(
              'flex h-4 w-4 items-center justify-center',
              getEventDuration(
                stringHourToMinutes(start),
                stringHourToMinutes(end),
              ) >= 84 && 'mb-2',
            )}
          >
            {isExcluded ? (
              <PlusIcon className="pointer-events-none mr-2 h-5 w-5" />
            ) : (
              <MinusIcon className="pointer-events-none mr-2 h-5 w-5" />
            )}
          </div>
          <span className="truncate text-sm font-semibold">{title}</span>
          <span className="text-sm">
            {start} - {end}
          </span>
        </div>
      ) : (
        <div
          className={clsx(
            'flex w-[90%] flex-row items-center',
            isExcluded && 'text-brand-1800',
          )}
        >
          <div className="mr-2 flex h-4 w-4 items-center justify-center">
            {isExcluded ? (
              <PlusIcon className="pointer-events-none mr-2 h-5 w-5" />
            ) : (
              <MinusIcon className="pointer-events-none mr-2 h-5 w-5" />
            )}
          </div>
          <span className="truncate text-sm font-semibold">
            {title} {start} - {end}
          </span>
          {/* <span className="flex h-5 w-[60%] flex-nowrap justify-end text-sm">
            {start} - {end}
          </span> */}
        </div>
      )}
    </div>
  );
};

export default ExcludableAvailabilityBox;
