import { createSlice } from '@reduxjs/toolkit';

import { IEventSliceState } from '../../types/Event';
import {
  createEventThunk,
  deleteEventThunk,
  getEventThunk,
  listEventsCountThunk,
  listEventsThunk,
  updateEventThunk,
} from '../thunks/eventThunk';

const initialState: IEventSliceState = {
  listEvents: [],
  listEventsCountThunkStatus: null,
  total: 0,
  event: null,
  currentEventId: null,
  eventStatus: null,
  getEventStatus: null,
  listEventsStatus: null,
  createEventStatus: null,
  updateEventStatus: null,
  deleteEventStatus: null,
  recurrenceData: null,
  eventStartDate: new Date(),
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clearEvents: () => initialState,
    setEventStartDate: (state, action) => {
      state.eventStartDate = action.payload;
    },
    clearEventStartDate: (state) => {
      state.eventStartDate = new Date();
      state.recurrenceData = null;
    },
    setCurrentEvent: (state, { payload }) => {
      state.event = payload;
    },
    setCurrentEventId: (state, { payload }) => {
      state.currentEventId = payload;
    },
    resetDeleteEventStatus: (state) => {
      state.deleteEventStatus = null;
    },
    setRecurrenceData: (state, { payload }) => {
      state.recurrenceData = payload;
    },
    clearRecurrenceData: (state) => {
      state.recurrenceData = null;
    },
  },
  extraReducers: (builder) => {
    // listEvents
    builder.addCase(listEventsThunk.pending, (state) => {
      state.listEventsStatus = 'loading';
    });
    builder.addCase(listEventsThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.listEvents = payload.events;
      state.listEventsStatus = 'success';
    });
    builder.addCase(listEventsThunk.rejected, (state) => {
      state.listEventsStatus = 'failed';
    });

    // getEvent
    builder.addCase(getEventThunk.pending, (state) => {
      state.getEventStatus = 'loading';
    });
    builder.addCase(getEventThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.event = payload;
      state.getEventStatus = 'success';
    });
    builder.addCase(getEventThunk.rejected, (state) => {
      state.getEventStatus = 'failed';
    });

    // createEvent
    builder.addCase(createEventThunk.pending, (state) => {
      state.createEventStatus = 'loading';
    });
    builder.addCase(createEventThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.listEvents) return;
      state.listEvents.unshift(payload);
      state.total += 1;
      state.createEventStatus = 'success';
    });
    builder.addCase(createEventThunk.rejected, (state) => {
      state.createEventStatus = 'failed';
    });

    // updateEvent
    builder.addCase(updateEventThunk.pending, (state) => {
      state.updateEventStatus = 'loading';
    });
    builder.addCase(updateEventThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.listEvents) return;
      state.listEvents = state.listEvents.map((event) => {
        if (event.id === payload.id) {
          return payload;
        }
        return event;
      });
      state.updateEventStatus = 'success';
    });
    builder.addCase(updateEventThunk.rejected, (state) => {
      state.updateEventStatus = 'failed';
    });

    // deleteEvent
    builder.addCase(deleteEventThunk.pending, (state) => {
      state.deleteEventStatus = 'loading';
    });
    builder.addCase(deleteEventThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.listEvents) return;
      state.listEvents = state.listEvents.filter(
        (event) => event.id !== payload,
      );
      state.total -= 1;
      state.deleteEventStatus = 'success';
    });
    builder.addCase(deleteEventThunk.rejected, (state) => {
      state.deleteEventStatus = 'failed';
    });

    // listEventsCountThunk
    builder.addCase(listEventsCountThunk.pending, (state) => {
      state.listEventsCountThunkStatus = 'loading';
    });
    builder.addCase(listEventsCountThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.total = payload;
      state.listEventsCountThunkStatus = 'success';
    });
    builder.addCase(listEventsCountThunk.rejected, (state) => {
      state.listEventsCountThunkStatus = 'failed';
    });
  },
});

export const {
  clearEvents,
  setCurrentEvent,
  setCurrentEventId,
  resetDeleteEventStatus,
  setRecurrenceData,
  clearRecurrenceData,
  setEventStartDate,
  clearEventStartDate,
} = eventSlice.actions;

export default eventSlice.reducer;
