import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { t } from 'i18next';
import { AxiosError } from 'axios';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { subDays } from 'date-fns';

import {
  ListAvailableHoursSchema,
  ListReservationsSchema,
  CreatedNewReservationSchema,
  ListFreeReservationTablesSchema,
  ListSummaryPageReservationsSchema,
} from '../../schema/ReservationSchemas';
import {
  IAddDelayToReservationThunk,
  ICancelReservationThunk,
  IChangeTableForReservationThunk,
  ICreateReservationThunk,
  IFinishReservationThunk,
  IListReservationsWithUnseenMessagesThunk,
  IListAvailableHoursThunk,
  IListFreeTablesThunk,
  IListReservationThunk,
  IListSummaryPageReservationsThunk,
  ISendSmsThunk,
  IStartReservationThunk,
  IUpdateReservationThunk,
  TReservation,
  IGetTotalReservationsCountThunk,
} from '../../types/Reservation';
import {
  addMinutesToISODateString,
  calculateDurationInMinutes,
  combineDateAndTime,
  extractHourFromIsoString,
  timeStringToMinutes,
  getDayOfWeek,
  parseTime,
  addPreviousStatus,
  isReservationPossible,
} from '../../functions/functions';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import { IMarkMessagesAsSeenThunk } from '../../types/Chat';
import { MarkMessagesAsSeenSchema } from '../../schema/ChatSchemas';

export const hasPlaceAnyReervationThunk = createAsyncThunk(
  'reservation/hasPlaceAnyReervation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.checkReservation,
        method: 'GET',
      });

      const { data } = response;

      const validatedData = z.boolean().parse(data);

      return validatedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const listSummaryPageReservationsThunk = createAsyncThunk(
  'reservation/listSummaryPageReservations',
  async (
    {
      placeId,
      startDate,
      endDate,
      timezone,
    }: IListSummaryPageReservationsThunk,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listReservations,
        method: 'GET',
        params: {
          placeId,
          limit: 100,
          dateStart: startDate,
          dateEnd: endDate,
          filters: ['NEW', 'STARTED', 'SMS_CONFIRMED', 'DELAYED'],
        },
      });

      const validatedData = ListSummaryPageReservationsSchema.parse(response);

      const { data: reservationsData } = validatedData;

      const preparedData = reservationsData.map((reservation) => {
        const calculatedReservationTime = calculateDurationInMinutes(
          reservation.dateStart,
          reservation.dateEnd,
        );

        const isWalkIn = !!(!reservation.firstName || !reservation.lastName);

        const hour = extractHourFromIsoString(reservation.dateStart, timezone);
        const formattedTableNames = reservation.ReservationTable.map(
          (table) => table.name,
        )
          .sort()
          .join(', ');

        return {
          ...reservation,
          fullName: isWalkIn
            ? 'Walk-in'
            : `${reservation.firstName} ${reservation.lastName}`,
          walkIn: isWalkIn,
          time: calculatedReservationTime,
          formattedTableNames,
          hour,
        } satisfies TReservation;
      });

      return preparedData;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingReservations'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listReservationsThunk = createAsyncThunk(
  'reservation/listReservations',
  async (
    {
      placeId,
      limit,
      offset,
      searchInput,
      startDate,
      endDate,
      timezone,
      filters,
      sortBy,
      order,
    }: IListReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      const isSearchInputUsed = searchInput.length >= 1;

      const response = await axiosMiddleware({
        url: REST_API_URLS.listReservations,
        method: 'GET',
        params: {
          placeId,
          dateStart: startDate,
          dateEnd: endDate,
          limit,
          offset,
          ...(isSearchInputUsed && { searchInput }),
          ...(filters && { filters }),
          ...(sortBy && { sortBy }),
          ...(order && { order }),
        },
      });

      const validatedData = ListReservationsSchema.parse(response);

      const { data: reservationsData } = validatedData;

      const preparedData = reservationsData.map((reservation) => {
        const calculatedReservationTime = calculateDurationInMinutes(
          reservation.dateStart,
          reservation.dateEnd,
        );

        const isWalkIn = !!(!reservation.firstName || !reservation.lastName);

        const hour = extractHourFromIsoString(reservation.dateStart, timezone);
        const formattedTableNames = reservation.ReservationTable.map(
          (table) => table.name,
        )
          .sort()
          .join(', ');

        return {
          ...reservation,
          fullName: isWalkIn
            ? 'Walk-in'
            : `${reservation.firstName} ${reservation.lastName}`,
          walkIn: isWalkIn,
          time: calculatedReservationTime,
          formattedTableNames,
          hour,
        } satisfies TReservation;
      });

      return {
        items: preparedData,
        total: preparedData.length,
      };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingReservations'),
      );
      return rejectWithValue(error);
    }
  },
);

export const getTotalReservationsCountThunk = createAsyncThunk(
  'reservation/getTotalReservationsCount',
  async (
    {
      startDate,
      endDate,
      filters,
      searchInput,
      unseenMessagesFilter,
    }: IGetTotalReservationsCountThunk,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getTotalReservationsCount,
        method: 'GET',
        params: {
          ...(startDate && { dateStart: startDate }),
          ...(endDate && { dateEnd: endDate }),
          ...(filters && { filters }),
          ...(searchInput && { searchInput }),
          ...(unseenMessagesFilter && { unseenMessagesFilter }),
        },
      });

      const validatedData = z.number().parse(response.data);

      return validatedData;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingReservations'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listReservationsWithUnseenMessagesThunk = createAsyncThunk(
  'reservation/listReservationsWithUnseenMessages',
  async (
    {
      placeId,
      limit,
      offset,
      timezone,
      searchInput,
      sortBy,
      order,
    }: IListReservationsWithUnseenMessagesThunk,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listReservations,
        method: 'GET',
        params: {
          placeId,
          limit,
          offset,
          unseenMessagesFilter: true,
          ...(searchInput && { searchInput }),
          ...(sortBy && { sortBy }),
          ...(order && { order }),
        },
      });

      const validatedData = ListReservationsSchema.parse(response);

      const { data: reservationsData } = validatedData;

      const preparedData = reservationsData.map((reservation) => {
        const calculatedReservationTime = calculateDurationInMinutes(
          reservation.dateStart,
          reservation.dateEnd,
        );

        const isWalkIn = !!(!reservation.firstName || !reservation.lastName);

        const hour = extractHourFromIsoString(reservation.dateStart, timezone);
        const formattedTableNames = reservation.ReservationTable.map(
          (table) => table.name,
        )
          .sort()
          .join(', ');

        return {
          ...reservation,
          fullName: isWalkIn
            ? 'Walk-in'
            : `${reservation.firstName} ${reservation.lastName}`,
          walkIn: isWalkIn,
          time: calculatedReservationTime,
          formattedTableNames,
          hour,
        } satisfies TReservation;
      });

      return {
        items: preparedData,
        total: preparedData.length,
      };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingReservations'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listAvailableHoursThunk = createAsyncThunk(
  'reservation/listAvailableHours',
  async (
    {
      placeId,
      personCount,
      reservationTime,
      date,
      reservationId,
      walkIn,
      openingHours,
      timezone,
      beforeReservationTime,
      placeSlotInterval,
    }: IListAvailableHoursThunk,
    { rejectWithValue },
  ) => {
    try {
      const result = await axiosMiddleware({
        url: REST_API_URLS.listHourSlots,
        method: 'GET',
        params: {
          placeId,
          date,
          ...(reservationId && { reservationId }),
        },
      });

      const validatedData = ListAvailableHoursSchema.parse(result);

      const {
        data: { slots, slotInterval },
        nextDayAvailability,
      } = validatedData;

      if (!slots || !slotInterval || !nextDayAvailability) {
        return [];
      }

      if (!openingHours) return [];

      const transformedSlots = Object.entries(slots)
        .map(([slot, details]) => ({
          slot,
          avaId: details.avaId,
          free: details.free,
        }))
        .sort((a, b) => parseTime(a.slot) - parseTime(b.slot));

      const transformedNextDayAvailability = Object.entries(nextDayAvailability)
        .map(([slot, details]) => ({
          slot,
          avaId: details.avaId,
          free: details.free,
          previous: false,
        }))
        .sort((a, b) => parseTime(a.slot) - parseTime(b.slot));

      const selectedDate = new Date(date);

      // eslint-disable-next-line no-nested-ternary
      const tempTodayOpeningHours = result.specialDay
        ? result.specialDay.isOpen
          ? result.specialDay
          : { open: '23:59', close: '23:59' }
        : openingHours[
            getDayOfWeek(
              formatInTimeZone(new Date(selectedDate), timezone, 'yyyy-MM-dd'),
            )
          ];

      const slotsWithStatus = addPreviousStatus(
        transformedSlots,
        tempTodayOpeningHours.open,
      );

      // eslint-disable-next-line no-nested-ternary
      const tempYesterdayOpeningHours = result.prevSpecialDay
        ? result.prevSpecialDay.isOpen
          ? result.prevSpecialDay
          : { open: '00:00', close: '00:00' }
        : openingHours[
            getDayOfWeek(
              formatInTimeZone(
                subDays(new Date(selectedDate), 1),
                timezone,
                'yyyy-MM-dd',
              ),
            )
          ];

      const filteredSlots = isReservationPossible(
        slotsWithStatus,
        personCount,
        reservationTime,
        tempTodayOpeningHours.open,
        tempTodayOpeningHours.close,
        '00:00',
        tempYesterdayOpeningHours.close,
        utcToZonedTime(new Date(selectedDate), timezone),
        transformedNextDayAvailability,
        timezone,
        beforeReservationTime,
        walkIn,
        placeSlotInterval,
      );

      return filteredSlots;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingAvailableHours'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listFreeTablesThunk = createAsyncThunk(
  'reservation/listFreeTables',
  async (
    {
      placeId,
      dateStart,
      dateEnd,
      countPerson,
      reservationId,
      availabilityId,
    }: IListFreeTablesThunk,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listFreeTables,
        method: 'GET',
        params: {
          placeId,
          dateStart,
          dateEnd,
          ...(reservationId && { reservationId }),
          ...(availabilityId && { availabilityId }),
        },
      });

      const validatedData = ListFreeReservationTablesSchema.parse(response);

      const { data: tablesData } = validatedData;

      const formattedCountPerson = parseInt(countPerson, 10);

      const filteredTables = tablesData.filter(
        (table) => table.maxSeats >= formattedCountPerson,
      );

      return filteredTables;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingFreeTables'),
      );
      return rejectWithValue(error);
    }
  },
);

export const sendSmsThunk = createAsyncThunk(
  'reservation/sendSms',
  async (
    { id, callback, errorCallback }: ISendSmsThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.sendSms,
        method: 'PATCH',
        data: { id },
      });

      if (callback) callback();

      return { id };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'LIMIT_EXCEEDED') {
          if (errorCallback) errorCallback();
          if (callback) callback();
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringSendingSmsConfirmation'),
          );
        }
      }
      return rejectWithValue(error);
    }
  },
);

export const startReservationThunk = createAsyncThunk(
  'reservation/startReservation',
  async (
    { id, containsInProgressInFilter, callback }: IStartReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.startReservation,
        method: 'PATCH',
        data: { id },
      });

      if (callback) callback();

      return { id, containsInProgressInFilter };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringStartingReservation'),
      );
      return rejectWithValue(error);
    }
  },
);

export const finishReservationThunk = createAsyncThunk(
  'reservation/finishReservation',
  async (
    { id, containsEndedInFilter, callback }: IFinishReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.finishReservation,
        method: 'PATCH',
        data: { id },
      });

      if (callback) callback();

      return { id, containsEndedInFilter };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFinishingReservation'),
      );
      return rejectWithValue(error);
    }
  },
);

export const createReservationThunk = createAsyncThunk(
  'reservation/createReservation',
  async (
    {
      placeId,
      countPerson,
      reservationTime,
      date,
      hour,
      spacePlanName,
      tables,
      fullName,
      phoneNumber,
      email,
      additionalInfo,
      availabilityId,
      callback,
      isWithinSelectedFilters,
      walkIn,
      timezone,
      language,
    }: ICreateReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      const formatedTime = timeStringToMinutes(reservationTime);

      const combinedUpdatedDate = combineDateAndTime(date, hour, timezone);
      const dateInIsoString = combinedUpdatedDate.toISOString();

      const tableIds = tables.map((table) => table.id);

      const commonReservationData = {
        placeId,
        countPerson,
        date: dateInIsoString,
        time: formatedTime,
        additionalInfo,
        availabilityId,
        walkIn,
        tableIds,
        language,
      };

      const [firstName, ...lastNameParts] = fullName?.split(' ') || [];
      const lastName = lastNameParts.join(' ');

      const completeReservationData = {
        ...commonReservationData,
        firstName,
        lastName,
        phoneNumber,
        email: email || undefined,
      };

      const { createReservationAdmin, createReservationWalkIn } = REST_API_URLS;

      const queryUrl = walkIn
        ? createReservationWalkIn
        : createReservationAdmin;

      const inputData = walkIn
        ? commonReservationData
        : completeReservationData;

      const response = await axiosMiddleware({
        url: queryUrl,
        method: 'POST',
        data: inputData,
      });

      const { data } = response;

      const validatedData = CreatedNewReservationSchema.parse(data);

      callback();

      const formattedTableNames = tables
        .map((table) => table.name)
        .sort()
        .join(', ');

      const newReservation = {
        additionalInfo,
        availabilityId,
        countPerson,
        createdAt: validatedData.createdAt,
        dateEnd: addMinutesToISODateString(dateInIsoString, formatedTime),
        dateStart: dateInIsoString,
        email,
        firstName,
        lastName,
        fullName: walkIn ? 'Walk-in' : `${firstName} ${lastName}`,
        hour: extractHourFromIsoString(dateInIsoString, timezone),
        id: validatedData.id,
        phoneNumber,
        ReservationTable: tables,
        time: formatedTime,
        status: walkIn ? 'STARTED' : 'NEW',
        walkIn,
        formattedTableNames,
        spacePlanName,
        userId: validatedData.userId,
      } satisfies TReservation;

      return {
        newReservation,
        isWithinSelectedFilters,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'COLLIDING_RESERVATION') {
          toast.error(t('errorMessages.tableTaken'));
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringCreatingReservation'),
          );
        }
      } else {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCreatingReservation'),
        );
      }
      return rejectWithValue(error);
    }
  },
);

export const addDelayToReservationThunk = createAsyncThunk(
  'reservation/addDelayToReservation',
  async (
    { id, newStartDate, newTime, callback }: IAddDelayToReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updateReservationAdmin,
        method: 'PATCH',
        data: { id, date: newStartDate, time: newTime },
      });

      if (callback) callback();
      return { id, newStartDate, newTime };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringAddingDelayToReservation'),
      );
      return rejectWithValue(error);
    }
  },
);

export const changeTableForReservationThunk = createAsyncThunk(
  'reservation/changeTableForReservation',
  async (
    { id, table, callback }: IChangeTableForReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updateReservationAdmin,
        method: 'PATCH',
        data: { id, tableIds: [table.id] },
      });

      if (callback) callback();

      return { id, table };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringChangingTableForReservation'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateReservationThunk = createAsyncThunk(
  'reservation/updateReservation',
  async (
    {
      reservationId,
      placeId,
      countPerson,
      reservationTime,
      date,
      hour,
      spacePlanName,
      tables,
      fullName,
      phoneNumber,
      email,
      additionalInfo,
      availabilityId,
      callback,
      isWithinSelectedFilters,
      walkIn,
      timezone,
      userId,
    }: IUpdateReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      const [firstName, ...lastNameParts] = fullName.split(' ');

      const lastName = lastNameParts.join(' ');

      const formatedTime = timeStringToMinutes(reservationTime);

      const combinedUpdatedDate = combineDateAndTime(date, hour, timezone);
      const dateInIsoString = combinedUpdatedDate.toISOString();

      const tableIds = tables.map((table) => table.id);

      const inputData = {
        id: reservationId,
        placeId,
        firstName,
        lastName,
        phoneNumber,
        email,
        date: dateInIsoString,
        countPerson,
        tableIds,
        time: formatedTime,
        additionalInfo,
        availabilityId,
        walkIn,
      };

      await axiosMiddleware({
        url: REST_API_URLS.updateReservationAdmin,
        method: 'PATCH',
        data: inputData,
      });

      const formattedTableNames = tables
        .map((table) => table.name)
        .sort()
        .join(', ');

      callback();

      const updatedReservation = {
        additionalInfo,
        availabilityId,
        countPerson,
        dateEnd: addMinutesToISODateString(dateInIsoString, formatedTime),
        dateStart: dateInIsoString,
        email,
        firstName,
        lastName,
        fullName: walkIn ? 'Walk-in' : `${firstName} ${lastName}`,
        hour: extractHourFromIsoString(dateInIsoString, timezone),
        id: reservationId,
        phoneNumber,
        ReservationTable: tables,
        time: formatedTime,
        walkIn,
        status: 'NEW',
        formattedTableNames,
        spacePlanName,
        userId,
      } satisfies Omit<TReservation, 'createdAt'>;

      return {
        updatedReservation,
        isWithinSelectedFilters,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'COLLIDING_RESERVATION') {
          toast.error(t('errorMessages.tableTaken'));
        } else if (error.response?.data?.errorCode === 'ALREADY_STARTED') {
          toast.error(
            t('errorMessages.cantEditReservationWhichAlreadyStarted'),
          );
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringUpdatingReservation'),
          );
        }
      } else {
        toast.error(
          t('errorMessages.somethingWentWrongDuringUpdatingReservation'),
        );
      }
      return rejectWithValue(error);
    }
  },
);

export const cancelReservationThunk = createAsyncThunk(
  'reservation/cancelReservation',
  async (
    { id, containsIsCancelInFilter, callback }: ICancelReservationThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.cancelReservationAdmin,
        method: 'PATCH',
        data: { id },
      });

      if (callback) callback();

      return { id, containsIsCancelInFilter };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'ALREADY_STARTED') {
          toast.error(
            t('errorMessages.cantCancelReservationWhichAlreadyStarted'),
          );
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringCancellingReservation'),
          );
        }
      } else {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCancellingReservation'),
        );
      }
      return rejectWithValue(error);
    }
  },
);

export const markMessagesAsSeenThunk = createAsyncThunk(
  'reservation/markMessagesAsSeen',
  async ({ reservationId }: IMarkMessagesAsSeenThunk, { rejectWithValue }) => {
    try {
      const data = { reservationId };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.markMessagesAsSeen}`,
        method: 'PATCH',
        data,
      });

      const validatedResponse = MarkMessagesAsSeenSchema.parse(response);

      return {
        data: validatedResponse.data,
        reservationId,
      };
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringMarkingMessagesAsSeen'),
      );
      return rejectWithValue(error);
    }
  },
);

export const checkIsAnyUnseenMessageThunk = createAsyncThunk(
  'reservation/checkIsAnyUnseenMessage',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.isAnyUnseenMessage}`,
        method: 'GET',
      });

      const validatedResponse = z.boolean().parse(response.data);

      return validatedResponse;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringCheckingIsAnyUnseenMessage'),
      );
      return rejectWithValue(error);
    }
  },
);
