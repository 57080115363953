import React, { useState } from 'react';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import clsx from 'clsx';

import InputArea from '../../atoms/InputArea/InputArea';
import { IPhoneNumberInputProps } from './PhoneNumberInput.types';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import { formatPhoneNumber } from '../../../../functions/functions';
import DialingCodeSelector from '../DialingCodeSelector/DialingCodeSelector';

const PhoneNumberInput = ({
  labelText,
  name,
  variant,
  placeholder,
  value,
  onChange,
  countryCode,
  error,
  isDisabled,
}: IPhoneNumberInputProps) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    countryCode || 'PL',
  );

  const handleCountryChange = (country: CountryCode) => {
    setSelectedCountry(country);

    onChange('');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    const formattedValue = formatPhoneNumber(inputValue, selectedCountry);

    onChange(formattedValue);
  };

  return (
    <div
      className={clsx(
        'flex w-full flex-col',
        variant === 'primary' && 'h-[120px] min-h-[120px]',
        variant === 'secondary' && 'h-[100px] min-h-[100px]',
      )}
    >
      <InputLabel htmlFor={name} text={labelText} variant="secondary" />
      <div className="flex w-full gap-2">
        <DialingCodeSelector
          selectedCountry={selectedCountry}
          handleCountryChange={handleCountryChange}
          isDisabled={isDisabled}
        />
        <div className="w-full">
          <InputArea
            type="text"
            name={name}
            placeholder={placeholder}
            value={new AsYouType(selectedCountry)
              .input(value)
              .replace(/^\+\d+\s/, '')}
            onChange={handleOnChange}
            isError={!!error}
            variant="secondary"
            isDisabled={isDisabled}
          />
        </div>
      </div>
      {error ? <InputErrorMessage error={error} /> : null}
    </div>
  );
};

export default PhoneNumberInput;
