import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { addDays, subDays } from 'date-fns';
import { useIntercom } from 'react-use-intercom';

import Header from '../../UI/molecules/Header/Header';
import SideMenu from '../../UI/organisms/SideMenu/SideMenu';
import MobileSideMenu from '../../UI/organisms/MobileSideMenu/MobileSideMenu';
import { BREAKPOINTS, reduxStatus, routes } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { getPlaceBaseSettingsThunk } from '../../../redux/thunks/settingsThunk';
import {
  checkScheduleHours,
  setAndConvertOpeningHoursForSchedule,
} from '../../../redux/features/scheduleSlice';
import useModal from '../../../hooks/useModal';
import { checkIsAnyUnseenMessageThunk } from '../../../redux/thunks/reservationThunk';

const PrivateTemplate = () => {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const { boot, shutdown } = useIntercom();

  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.user);

  const getPlaceForSettingsStatus = useAppSelector(
    (state) => state.settings.getPlaceForSettingsStatus,
  );

  const listAvailabilitiesStatus = useAppSelector(
    (state) => state.schedule.listAvailabilitiesStatus,
  );

  const openingHours = useAppSelector((state) => state.settings.openingHours);

  const openingHoursSchedule = useAppSelector(
    (state) => state.schedule.openingHoursSchedule,
  );

  const isNotificationsModalChecked = JSON.parse(
    localStorage.getItem('isNotificationsModalChecked') || 'false',
  );

  const {
    visible: isNotificationModalVisible,
    showModal: showNotificationModal,
    hideModal: hideNotificationModal,
  } = useModal();

  const handleShowMenu = () => {
    setIsSideMenuOpen(true);
  };

  const handleHideMenu = () => {
    setIsSideMenuOpen(false);
  };

  useEffect(() => {
    if (pathname === routes.support) {
      boot();
    } else {
      shutdown();
    }
  }, [pathname, boot, shutdown]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= BREAKPOINTS.tablet && isSideMenuOpen) {
        setIsSideMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
  }, [isSideMenuOpen]);

  useEffect(() => {
    if (!user?.placeId) return;

    dispatch(getPlaceBaseSettingsThunk());
  }, [dispatch, user?.placeId]);

  useEffect(() => {
    if (
      getPlaceForSettingsStatus === reduxStatus.success &&
      listAvailabilitiesStatus === reduxStatus.success
    ) {
      if (openingHours) {
        dispatch(setAndConvertOpeningHoursForSchedule({ openingHours }));
      }
    }
  }, [
    getPlaceForSettingsStatus,
    listAvailabilitiesStatus,
    dispatch,
    openingHours,
  ]);

  useEffect(() => {
    if (
      getPlaceForSettingsStatus === reduxStatus.success &&
      listAvailabilitiesStatus === reduxStatus.success
    ) {
      if (openingHoursSchedule) {
        dispatch(checkScheduleHours({ openingHours: openingHoursSchedule }));
      }
    }
  }, [
    getPlaceForSettingsStatus,
    listAvailabilitiesStatus,
    dispatch,
    openingHoursSchedule,
  ]);

  useEffect(() => {
    dispatch(checkIsAnyUnseenMessageThunk());
  }, [dispatch]);

  useEffect(() => {
    if (user && !isNotificationsModalChecked) {
      showNotificationModal();
    }
  }, [isNotificationsModalChecked, showNotificationModal, user]);

  return (
    <div className="relative flex h-screen max-h-screen w-screen flex-col overflow-hidden">
      <Header showMenu={handleShowMenu} />
      <div className="flex h-[calc(100dvh-64px)]">
        <SideMenu
          isOpen={isSideMenuOpen}
          showMenu={handleShowMenu}
          hideMenu={handleHideMenu}
        />
        <MobileSideMenu isOpen={isSideMenuOpen} hideMenu={handleHideMenu} />
        <main
          className={clsx(
            'h-full w-full transition-[max-width]',
            isSideMenuOpen
              ? 'xl:max-w-[calc(100%-256px)] xl:duration-300'
              : 'xl:max-w-[calc(100%-60px)] xl:delay-300',
            pathname.split('/').includes('availability')
              ? 'p-0'
              : 'px-5 py-4 md:px-6 md:py-5 xl:p-10',
          )}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default PrivateTemplate;
