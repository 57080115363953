const ConfirmCheckIcon = () => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.155 18.333c-.326.002-.64-.151-.874-.427L.377 10.962a1.562 1.562 0 01-.276-.488c-.065-.183-.099-.38-.1-.58-.005-.402.123-.79.355-1.078.232-.288.55-.453.881-.457.332-.005.652.15.89.431L7.16 14.71 17.872 2.098c.238-.281.558-.436.89-.431.333.005.65.17.882.458.232.289.36.677.356 1.08-.004.403-.14.787-.378 1.068L8.03 17.906c-.234.276-.548.429-.875.427z"
        fill="#31C48D"
      />
    </svg>
  );
};

export default ConfirmCheckIcon;
