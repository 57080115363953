import { SVGProps } from 'react';

const CheckIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.917 7.583L5.25 9.917l5.833-5.834"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </svg>
  );
};
export default CheckIcon;
